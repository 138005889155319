import React, { useEffect, useState } from "react";

import {
  Table,
  Row,
  Col,
  Form,
  Input, 
  Button,
  message,
  DatePicker,
} from "antd";
import RcUeditor from "react-ueditor-wrap";
import api from "../../api";
import qs from "qs";
import _ from "lodash";
import moment from "moment";
import utils from "../../assets/js/public.js";
import "./tabel.less";

const digitUppercase = (n) => {
  var fraction = ["角", "分"];
  var digit = ["零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖"];
  var unit = [
    ["元", "万", "亿"],
    ["", "拾", "佰", "仟"],
  ];
  var head = n < 0 ? "欠" : "";
  n = Math.abs(n);
  var s = "";
  for (var i = 0; i < fraction.length; i++) {
    s += (
      digit[Math.floor(n * 10 * Math.pow(10, i)) % 10] + fraction[i]
    ).replace(/零./, "");
  }
  s = s || "整";
  n = Math.floor(n);
  for (var i = 0; i < unit[0].length && n > 0; i++) {
    var p = "";
    for (var j = 0; j < unit[1].length && n > 0; j++) {
      p = digit[n % 10] + unit[1][j] + p;
      n = Math.floor(n / 10);
    }
    s = p.replace(/(零.)*零$/, "").replace(/^$/, "零") + unit[0][i] + s;
  }
  return (
    head +
    s
      .replace(/(零.)*零元/, "元")
      .replace(/(零.)+/g, "零")
      .replace(/^整$/, "零元整")
  );
};

const ElectronicContract = () => {
  const [loading, setLoading] = useState(false);
  const [quotationInfo, setquotationInfo] = useState({});
  const [proDataSource, setProDataSource] = useState([]);
  const [totalPrice, setTotalPrice] = useState("0.00");
  const [ueditorContent, setUeditorContent] = useState();

  const [form] = Form.useForm();

  const proColumns = [
    {
      title: "项次",
      dataIndex: "",
      width: 80,
      align: "center",
      render: (text, data, index) => {
        return index + 1;
      },
    },
    {
      title: "产品名称",
      dataIndex: "",
      width: 200,
      render: (text, data, index) => {
        return data.productTitle || "-";
      },
    },
    {
      title: "品种规格",
      dataIndex: "",
      render: (text, data, index) => {
        return (
          <div>
          型号：{data.productModel}
            {(data.productConfig &&
              JSON.parse(data.productConfig).map((e) => {
                return (
                  <div>
                    {e.label}: 
                    {e.value}
                  </div>
                  
                );
                
              })) ||
              "-"}
          </div>
        );
      },
    },
    {
      title: "数量",
      dataIndex: "",
      width: 100,
      align: "center",
      render: (text, data, index) => {
        return (data.productNumber && data.productNumber + "PCS") || "-";
      },
    },
    {
      title: "单价（元）",
      dataIndex: "",
      width: 120,
      align: "center",
      render: (text, data, index) => {
        return (data.salesAmount && data.salesAmount.toFixed(2)) || "-";
      },
    },
    {
      title: "总金额（元）",
      dataIndex: "",
      width: 120,
      align: "center",
      render: (text, data, index) => {
        return (
          (data.salesAmount &&
            data.productNumber &&
            (data.salesAmount * data.productNumber).toFixed(2)) ||
          "-"
        );
      },
    },
  ];

  const editorConfig = {
    toolbars: [
      [
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "forecolor",
        "backcolor",
        "justifyleft",
        "justifycenter",
        "justifyright",
        "|",
        "insertunorderedlist",
        "insertorderedlist",
        "blockquote",
      ],
    ],
    autoClearinitialContent: false,
    autoFloatEnabled: false,
    autoHeightEnabled: false,
    focus: false,
    initialFrameWidth: "100%", // 初始化编辑器宽度
    initialFrameHeight: 300,
    maximumWords: 100000,
    isChange:false,
  };

  const getQuotationInfo = async () => {
    const orderCode = qs.parse(window.location.search.split("?")[1]).orderCode;
    console.log(orderCode);
    let res = await api.orderGetContract({ orderCode });
    // console.log(res);
    let contractTerms;
    if (res.data.contractTerms) {
      contractTerms = res.data.contractTerms;
      // delete res.data.contractTerms;
    } else {
      delete res.data.contractTerms;
    }
    form.setFieldsValue({
      ...res.data,
      signTime: (res.data.signTime && moment(res.data.signTime)) || "",
    });

    if (contractTerms) {
      setUeditorContent(contractTerms);
    }
    let totalPrice = 0;
    [...res.data.orderProductList].map((e) => {
      totalPrice += e.salesAmount * e.productNumber;
    });
    setTotalPrice(totalPrice.toFixed(2));
    setquotationInfo(res.data);
    setProDataSource([...res.data.orderProductList]);
  };

  useEffect(() => {
    getQuotationInfo();
  }, []);

  const onFinish = async (values) => {
    console.log(values);
    const orderCode = qs.parse(window.location.search.split("?")[1]).orderCode;
    let params = {
      ...values,
      id: quotationInfo.id,
      orderCode,
      signTime:
        (values?.signTime &&
          moment(values?.signTime).format("YYYY-MM-DD HH:mm:ss")) ||
        "",
    };
    let res = await api.orderPutContractUpdate(params);

    if (res.code == 200) {
      api
        .contractExport({
          orderCode: qs.parse(window.location.search.split("?")[1]).orderCode,
        })
        .then((res) => {
          if (res.code == 200) {
            let btn = document.createElement("a");
            btn.download = `订单编号-${
              qs.parse(window.location.search.split("?")[1]).orderCode
            }-电子合同.pdf`;
            btn.href = res.data;
            btn.target = "_blank";
            btn.click();
            btn = null;
          }
        });
      return message.success("保存成功,正在生成单据");
    }
    message.error(res.message);
    console.log(res);
    // console.log(res)
  };

  const hanldeChage = (value, type) => {
    // console.log(value, type);
    form.setFieldsValue({
      [type]: value,
    });
    console.log(form.getFieldsValue());
  };

  return (
    <div style={{ width: "60%", margin: "0 auto" }}>
      <Form
        form={form}
        name="quotation"
        initialValues={{
          content: ueditorContent,
        }}
        onFinish={onFinish}
      >
        <h2 className="text-center">产 品 购 销 合 同</h2>
        <br />
        <br />
        <Row gutter={90}>
          <Col md={12}>
            <div>
              <Form.Item label="供方" name="supplier">
                <Input />
              </Form.Item>
            </div>
            <div>
              <Form.Item label="需方编号" name = "buyerContract" >
              <Input />
              </Form.Item>
            </div>
            <div>
              <Form.Item label="需方" name="buyer">
                <Input />
              </Form.Item>
            </div>
          </Col>
          <Col md={12}>
            <div>
            {/* <Form.Item label="合同编号" name="contractNo">
                <Input  />
              </Form.Item> */}
            </div>
            <div>
              <Form.Item label="订单编号" name="orderCode">
                <Input />
              </Form.Item>

            </div>
            <div>
              <Form.Item label="签订时间" name="signTime">
                <DatePicker
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  showTime
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item label="签订地点" name="signAddr">
                <Input />
              </Form.Item>
            </div>
          </Col>
        </Row>
        <br />
        <p>1、标的物品名称、型号、数量及价款：</p>
        <Table
          columns={proColumns}
          dataSource={proDataSource}
          pagination={false}
          bordered
          summary={() => (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={6}>
                  <Form.Item name="remark" style={{ marginBottom: 0 }}>
                  </Form.Item>
                </Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={6}>
                  <div
                    className="d-flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <b>合计人民币（大写）：{digitUppercase(totalPrice)}</b>
                    <b>RMB:{totalPrice}</b>
                  </div>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
        />
        <br />
        <br />
        <Form.Item name="contractTerms" >
          <div>
          <RcUeditor
            ueditorUrl="/ueditor/ueditor.all.js"
            ueditorConfigUrl="/ueditor/ueditor.config.js"
            value={ueditorContent}
            editorConfig={editorConfig}
            onChange={(e) => hanldeChage(e, "contractTerms")}
          />
          </div>
        </Form.Item>
        <br />
        <br />
        <div className="ant-table ant-table-bordered">
          <div className="ant-table-container">
            <div className="ant-table-content">
              <table className="table">
                <thead className="ant-table-thead">
                  <tr>
                    <th className="ant-table-cell" colspan="2">
                      供方
                    </th>
                    <th className="ant-table-cell" colspan="2">
                      需方
                    </th>
                    <th className="ant-table-cell">鉴（公）证意见</th>
                  </tr>
                </thead>
                <tbody className="ant-table-tbody">
                  <tr className="ant-table-row">
                    <td className="ant-table-cell">单位名称</td>
                    <td className="ant-table-cell">
                      飞卓科技（上海）股份有限公司
                    </td>
                    <td className="ant-table-cell">单位名称</td>
                    <td className="ant-table-cell">
                      <Form.Item
                        name="buyerCompany"
                        style={{ marginBottom: 0 }}
                      >
                        <Input />
                      </Form.Item>
                    </td>
                    <td className="ant-table-cell" rowspan="4" />
                  </tr>
                  <tr className="ant-table-row">
                    <td className="ant-table-cell">单位地址</td>
                    <td className="ant-table-cell">
                      上海市金山工业区夏宁路818弄62号
                    </td>
                    <td className="ant-table-cell">单位地址</td>
                    <td className="ant-table-cell">
                      <Form.Item name="buyerAddr" style={{ marginBottom: 0 }}>
                        <Input.TextArea />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr className="ant-table-row">
                    <td className="ant-table-cell">代理人</td>
                    <td className="ant-table-cell">
                      <Form.Item
                        name="supplierAgent"
                        style={{ marginBottom: 0 }}
                      >
                        <Input />
                      </Form.Item>
                    </td>
                    <td className="ant-table-cell">代理人</td>
                    <td className="ant-table-cell">
                      <Form.Item name="buyerAgent" style={{ marginBottom: 0 }}>
                        <Input />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr className="ant-table-row">
                    <td className="ant-table-cell">电话</td>
                    <td className="ant-table-cell">021-57274400</td>
                    <td className="ant-table-cell">电话</td>
                    <td className="ant-table-cell">
                      <Form.Item name="buyerTel" style={{ marginBottom: 0 }}>
                        <Input />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr className="ant-table-row">
                    <td className="ant-table-cell">传真</td>
                    <td className="ant-table-cell">021-57272066</td>
                    <td className="ant-table-cell">传真</td>
                    <td className="ant-table-cell">
                      <Form.Item name="buyerFax" style={{ marginBottom: 0 }}>
                        <Input />
                      </Form.Item>
                    </td>
                    <td className="ant-table-cell" rowspan="2">
                      <Form.Item
                        name="handler"
                        label="经办人"
                        style={{ marginBottom: 0 }}
                      >
                        <Input />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr className="ant-table-row">
                    <td className="ant-table-cell">税号</td>
                    <td className="ant-table-cell">91310116765323346N</td>
                    <td className="ant-table-cell">税号</td>
                    <td className="ant-table-cell">
                      <Form.Item
                        name="buyerDutyParagraph"
                        style={{ marginBottom: 0 }}
                      >
                        <Input />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr className="ant-table-row">
                    <td className="ant-table-cell">开户银行</td>
                    <td className="ant-table-cell">
                      上海农商银行金山工业区支行
                    </td>
                    <td className="ant-table-cell">开户银行</td>
                    <td className="ant-table-cell">
                      <Form.Item name="buyerBank" style={{ marginBottom: 0 }}>
                        <Input.TextArea />
                      </Form.Item>
                    </td>
                    <td className="ant-table-cell" rowspan="3">
                      <Form.Item
                        name="zhang"
                        label="鉴（公）证意见（章"
                        style={{ marginBottom: 0 }}
                      />
                    </td>
                  </tr>
                  <tr className="ant-table-row">
                    <td className="ant-table-cell">帐号</td>
                    <td className="ant-table-cell">50131000645574253</td>
                    <td className="ant-table-cell">帐号</td>
                    <td className="ant-table-cell">
                      <Form.Item name="buyerNo" style={{ marginBottom: 0 }}>
                        <Input />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr className="ant-table-row">
                    <td className="ant-table-cell">审核</td>
                    <td className="ant-table-cell">
                      <Form.Item
                        name="supplierReview"
                        style={{ marginBottom: 0 }}
                      >
                        <Input />
                      </Form.Item>
                    </td>
                    <td className="ant-table-cell">审核</td>
                    <td className="ant-table-cell">
                      <Form.Item name="buyerReview" style={{ marginBottom: 0 }}>
                        <Input />
                      </Form.Item>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <div className="text-center">
          <Button
            className="mr-3"
            onClick={() => {
              // window.history.go(-1);
              window.close()
              // window.open("/index/order/contract");
            }}
          >
            返回
          </Button>
          <Button className="mr-3" type="primary" htmlType="submit">
            提交
          </Button>
          <Button
            className="mr-3"
            onClick={() => {
              api
                .contractExport({
                  orderCode: qs.parse(window.location.search.split("?")[1])
                    .orderCode,
                })
                .then((res) => {
                  if (res.code == 200) {
                    let btn = document.createElement("a");
                    btn.download = `订单编号-${
                      qs.parse(window.location.search.split("?")[1]).orderCode
                    }-电子合同.pdf`;
                    btn.href = res.data;
                    btn.target = "_blank";
                    btn.click();
                    btn = null;
                  }
                });
            }}
          >
            导出
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ElectronicContract;
