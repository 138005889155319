import codes from "./state_code.js"; //状态码，报错提示
import login from "./login.js";
import user from "./user.js";
import home from "./home";
import setting from "./setting";
import finance from "./finance.js";
import notice from "./notice.js";
import rule from "./rule.js";
import ad from "./ad.js";
import logs from "./logs.js";
import product from "./product.js";
import message from "./message.js";
import system from "./system.js";
import template from "./template.js";
import order from "./order.js";

// import upload from './upload.js';

export default {
  stateCode: codes,
  ...login,
  ...user,
  ...home,
  ...setting,
  ...finance,
  ...notice,
  ...rule,
  ...ad,
  ...logs,
  ...product,
  ...system,
  ...message,
  ...template,
  ...order,
};
