import React from 'react';
import {
    //Switch,
    Route, Redirect, BrowserRouter as Router } from 'react-router-dom';

// 用户注册
import Login from './login/login';
import Register from './login/register';
import Forget from './login/forget';
//首页
import Index from './index';

class Pages extends React.Component {

    state = {
        login_state: false,
    }
    componentDidMount(){
    }
    componentWillUnmount() {
        this.setState = () => false;
    }
    render() {
        return (
            <div >
                <Router>
                    {/* 首页 */}
                    <Route path="/" exact component={Index} />
                    <Route path="/index" component={Index}/>
                    {/* 日志 */}
                    <Route path="/logs" component={Forget} />

                    {/* 用户登录注册 */}
                    <Route path="/login" component={Login} />
                    <Route path="/register" component={Register}/>
                    <Route path="/reset" component={Forget} />
                    {/* test */}
                    {/* <Route path="/test/upload" component={PicturesWall} /> */}

                    {/* <Redirect to={`/login`} /> */}
                </Router>
            </div>
        );
    }
}
export default Pages;
