import instance from './instance.js'
import { base, mock } from './config.js'
import cookie from 'react-cookies'
import qs from 'qs'
export default {
  async getOverview(params) {
    return await instance
      .get(`${base}/shopFinance/overviewPrice`, params)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        //message.error('服务器出错')
      })
  },
  //获取用户订单列表
  async getDetailsList(params) {
    return await instance
      .post(`${base}/shopFinance/queryUserList`, params)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        //message.error('服务器出错')
      })
  },
  //获取商家支出 充值邮豆
  async getShopDetailsList(params) {
    return await instance
      .post(`${base}/shopFinance/queryBeansOrderList`, params)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        //message.error('服务器出错')
      })
  },
  //获取充值vip订单
  async getVipDetailsList(params) {
    return await instance
      .post(`${base}/shopFinance/queryVipOrderList`, params)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        //message.error('服务器出错')
      })
  },
  //获取提现申请列表
  async getWithdrawList(params) {
    return await instance
      .post(`${base}/shopWithdrawalOrder/queryList`, params)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        //message.error('服务器出错')
      })
  },
  //审核提现申请
  async updateWithdraw(params) {
    return await instance
      .post(`${base}/shopWithdrawalOrder/updateStatus`, params)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        //message.error('服务器出错')
      })
  },
  
  //广告商获取收益信息
  async getAduserMoney(params){
    return await instance
    .post(`${base}/serviceShop/queryMoneyByAdmin`, params)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      //message.error('服务器出错')
    })
  },
  //广告商获取收支明细列表
  async getAduserDetails(params){
    return await instance
    .post(`${base}/serviceShop/queryFinanceListByAdmin`, params)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      //message.error('服务器出错')
    })
  },
  //服务商获取收益信息
  async getServerMoney(params){
    return await instance
    .post(`${base}/serviceShop/queryShopByAdmin`, params)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      //message.error('服务器出错')
    })
  },
  //服务商获取商户邮豆充值列表
  async getServerShopuserBean(params){
    return await instance
    .post(`${base}/serviceShop/queryOrderBeansByAdmin`, params)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      //message.error('服务器出错')
    })
  },
  //服务商获取商户VIP充值列表
  async getServerShopuserVip(params){
    return await instance
    .post(`${base}/serviceShop/queryOrderVipByAdmin`, params)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      //message.error('服务器出错')
    })
  },
  
}
