import instance from './instance.js'
import { base, mock } from './config.js'
import cookie from 'react-cookies'
import qs from 'qs'
export default {
  //创建权限
  async createRule(params) {
    return await instance
      .post(`${base}/e/role/add`, params)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        //message.error('服务器出错')
      })
  },
  //删除权限
  async delRule(params) {
    return await instance
      .post(`${base}/e/role/delete/${params.id}`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        //message.error('服务器出错')
      })
  },
  //获取权限信息
  async getRuleInfo(params) {
    return await instance
      .get(`${base}/e/role/get/${params.id}`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        //message.error('服务器出错')
      })
  },
  //获取权限列表
  async getRuleList(params) {
    return await instance
      .post(`${base}/e/role/query`,params)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        //message.error('服务器出错')
      })
  },
  //修改权限
  async updateRuleInfo(params) {
    return await instance
      .post(`${base}/e/role/update`,params)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        //message.error('服务器出错')
      })
  },
  //创建菜单
  async createMenu(params) {
    return await instance
      .post(`${base}/e/menu/add`, params)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        //message.error('服务器出错')
      })
  },
  //删除菜单
  async delMenu(params) {
    return await instance
      .post(`${base}/e/menu/delete/${params.id}`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        //message.error('服务器出错')
      })
  },
  //获取菜单信息
  async getMenuInfo(params) {
    return await instance
      .get(`${base}/e/menu/get/${params.id}`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        //message.error('服务器出错')
      })
  },
  //获取当前管理员菜单信息
  async getMenuAdmin(params) {
    return await instance
      .get(`${base}/e/menu/getAdminMenu`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        //message.error('服务器出错')
      })
  },
  //获取所有菜单信息
  async getMenuAll(params) {
    return await instance
      .get(`${base}/e/menu/getAllMenu`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        //message.error('服务器出错')
      })
  },
  //获取菜单列表
  async getMenuList(params) {
    return await instance
      .post(`${base}/e/menu/query`,params)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        //message.error('服务器出错')
      })
  },
  //修改权限
  async updateMenuInfo(params) {
    return await instance
      .post(`${base}/e/menu/update`,params)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        //message.error('服务器出错')
      })
  },
  //获取该角色下的权限
  async queryResourceByRoleId(params) {
    return await instance
      .post(`${base}/m/sysResource/queryResourceByRoleId/${params.id}`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        //message.error('服务器出错')
      })
  },
}
