import React from 'react'
import { Form, Radio } from 'antd'
/**
 * @prop config 属性设置
 */
export default class TempNumberRadio extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        let { config } = this.props;
        return (
            <Form.Item label={config.title} name={config.field} >
                <Radio.Group options={config.options.filter(item => !!item && (item.value || item.label)).map((item, index) => {
                    // if (item.value && !item.label) {
                    //     item.label = item.value
                    // } else if (!item.value && item.label) {
                    // }
                    item.value = `${item.label}_${index}`;
                    return item;
                })} />
            </Form.Item>
        )
    }
}
