import instance from './instance.js'
import { base, mock } from './config.js'
import cookie from 'react-cookies'
import qs from 'qs'
export default {
  //创建户外广告位
  async createAdSite(params) {
    return await instance
      .post(`${base}/largeOutdoorAdvertisingSpace/create`, params)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        //message.error('服务器出错')
      })
  },
  //更新户外广告位
  async updateAdSite(params) {
    return await instance
      .post(`${base}/largeOutdoorAdvertisingSpace/update`, params)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        //message.error('服务器出错')
      })
  },
  //删除户外广告位
  async delAdSite(params) {
    return await instance
      .post(`${base}/largeOutdoorAdvertisingSpace/delete/${params.id}`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        //message.error('服务器出错')
      })
  },
  //删除飞卓广告位
  async delRedAdSite(params) {
    return await instance
      .post(`${base}/e/shopBusiness/deleteAdverties/${params.id}`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        //message.error('服务器出错')
      })
  },
  //查询所有广告位
  async getAllAdSite(params) {
    return await instance
      .post(`${base}/largeOutdoorAdvertisingSpace/queryByAdvertisingId`, params)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        //message.error('服务器出错')
      })
  },
  //查询自己相关广告位
  async getAdSite(params) {
    return await instance
      .post(`${base}/e/shopBusiness/queryAdminAdvertiesList`, params)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        //message.error('服务器出错')
      })
  },
  //审核广告位 1:同意 2:拒绝
  async auditAdSite(params) {
    return await instance
      .post(`${base}/e/shopBusiness/reviewedShopBusiness/${params.id}/${params.opt}`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        //message.error('服务器出错')
      })
  },
  //获取广告位下广告
  async getAdSiteAdList(params) {
    return await instance
      .post(`${base}/e/mapAdvertisement/queryAdvertisementByShopBusinessId`, params)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        //message.error('服务器出错')
      })
  },
  //审核广告 1:同意 2:拒绝
  async auditAdSiteAd(params) {
    return await instance
      .post(`${base}/e/mapAdvertisement/reviewedMapAdvertisement`, params)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        //message.error('服务器出错')
      })
  },
  //获取地区列表
  async getCityInfo(params) {
    return await instance
      .get(`${base}/address/getAddress/${(params && params.parentId) || 1}`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        //message.error('服务器出错')
      })
  },

  //创建飞卓广告位
  async createRedMailAdsite(params) {
    return await instance
      .post(`${base}/e/shopBusiness/createOutdoorsAdverties`, params)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        //message.error('服务器出错')
      })
  },
  //修改飞卓广告位
  async updateRedMailAdsite(params) {
    return await instance
      .post(`${base}/e/shopBusiness/updateOutdoorsAdverties`, params)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        //message.error('服务器出错')
      })
  },
  //获取飞卓广告位二维码
  async getRedmailQrcode(params) {
    return await instance
      .get(`${base}/advertisementSpace/getAdvertisementSpaceCode/${params.id}`, )
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        //message.error('服务器出错')
      })
  },
}
