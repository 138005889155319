import React, { Component, useState, useEffect } from "react";
import {
  Breadcrumb,
  Row,
  Col,
  Button,
  Input,
  Form,
  message,
  Divider,
  Empty,
  Space,
  Modal,
  Upload,
  Tooltip,
  Tag,
} from "antd";
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  indexToArray,
  getItem,
  setInfo,
  isPath,
  getCloneItem,
  itemRemove,
  itemAdd,
} from "./utils";
import Sortable from "react-sortablejs";
import uniqueId from "lodash/uniqueId";
import update from "immutability-helper";
import cookie from "react-cookies";
import _ from "lodash";
import api from "../../api";
import "./index.less";

// import { withRouter } from "react-router-dom";

import TempInput from "../../components/tempInput";
import TempTextarea from "../../components/TempTextarea";
import TempRadio from "../../components/TempRadio";
import TempCheck from "../../components/TempCheck";
import TempSignSelect from "../../components/TempSignSelect";
import TempCheckSelect from "../../components/TempCheckSelect";
import TempUpload from "../../components/TempUpload";
import TempNumber from "../../components/TempNumber";
import TempNumberRadio from "../../components/TempNumberRadio";
import TempNumberLadRadio from "../../components/TempNumberRadio";
import TempVideo from "../../components/TempVideo";
import TempPicture from "../../components/TempPicture";
import TempComplexSelect from "../../components/TempComplexSelect";
import TempCascader from "../../components/TempCascader";

const GlobalComponent = {
  TempInput,
  TempTextarea,
  TempRadio,
  TempCheck,
  TempSignSelect,
  TempCheckSelect,
  TempUpload,
  Divider,
  TempNumber,
  TempNumberRadio,
  TempNumberLadRadio,
  TempVideo,
  TempPicture,
  TempComplexSelect,
  TempCascader,
  TempCascaderLad: TempCascader,
};
// const [shouldLoadOptions, setShouldLoadOptions] = useState(true);

// useEffect(() => {
//   if (shouldLoadOptions) {
//     this.getOptionsForm(uploadFile, "options", [], max);
//     setShouldLoadOptions(false); // Set to false to prevent further calls
//   }
// }, [shouldLoadOptions]);
const soundData = [
  {
    label: "标题",
    name: "Containers",
    attr: {},
    config: {
      type: ["title"],
    },
    props: {
      title: "标题",
    },
  },
  {
    label: "单行文本",
    name: "TempInput",
    attr: {},
    config: {
      type: ["title"],
    },
    props: {
      title: "单行文本",
    },
  },
  {
    label: "多行文本",
    name: "TempTextarea",
    attr: {},
    config: {
      type: ["title"],
    },
    props: {
      title: "多行文本",
    },
  },
  {
    label: "单选",
    name: "TempRadio",
    attr: {},
    config: {
      type: ["title", "options"],
    },
    props: {
      title: "单选",
      options: [],
    },
  },
  {
    label: "多选",
    name: "TempCheck",
    attr: {},
    config: {
      type: ["title", "options"],
    },
    props: {
      title: "多选",
      options: [],
    },
  },
  {
    label: "单选下拉框",
    name: "TempSignSelect",
    attr: {},
    config: {
      type: ["title", "options"],
    },
    props: {
      title: "单选下拉框",
      options: [],
    },
  },
  {
    label: "多选下拉框",
    name: "TempCheckSelect",
    attr: {},
    config: {
      type: ["title", "options"],
    },
    props: {
      title: "多选下拉框",
      options: [],
    },
  },
  {
    label: "数字",
    name: "TempNumber",
    attr: {},
    config: {
      type: ["title", "SKU", "base", "ltBase", "geBase", "addBase"],
    },
    props: {
      title: "数字",
    },
  },
  {
    label: "数字单选",
    name: "TempNumberRadio",
    attr: {},
    config: {
      type: ["title", "options"],
    },
    props: {
      title: "数字单选",
      options: [],
    },
  },
  {
    label: "数字单选阶梯",
    name: "TempNumberLadRadio",
    attr: {},
    config: {
      type: ["title", "options"],
      isMoreOptions: true,
    },
    props: {
      title: "数字单选阶梯",
      options: [],
    },
  },
  {
    label: "子项单选",
    name: "TempComplexSelect",
    attr: {},
    config: {
      type: ["title", "options"],
      iscomplex: true,
    },
    props: {
      title: "子项单选",
      options: [],
    },
  },
  {
    label: "级联选择",
    name: "TempCascader",
    attr: {},
    config: {
      type: ["title", "options"],
    },
    props: {
      title: "级联选择",
      options: [],
    },
  },
  {
    label: "阶梯级联选择",
    name: "TempCascaderLad",
    attr: {},
    config: {
      type: ["title", "options"],
    },
    props: {
      title: "阶梯级联选择",
      options: [],
    },
  },
  // {
  //     label: '附件',
  //     name: 'TempUpload',
  //     attr: {},
  //     config: {
  //         type: ['title']
  //     },
  //     props: {
  //         title: '附件',
  //         fileList: []
  //     },
  // },
  // {
  //     label: '视频',
  //     name: 'TempVideo',
  //     attr: {},
  //     config: {
  //         type: ['title']
  //     },
  //     props: {
  //         title: '视频',
  //         fileList: []
  //     }
  // },
  // {
  //     label: '图片',
  //     name: 'TempPicture',
  //     attr: {},
  //     config: {
  //         type: ['title']
  //     },
  //     props: {
  //         title: '图片',
  //         fileList: []
  //     }
  // },
  {
    label: "分割线",
    name: "Divider",
    attr: {},
  },
];

class TemplateForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Data: [],
      currentPath: null,
      title: null,
      id: null,
      isTitleVisible: false,
      inputValue: "", // 添加一个新的状态 inputValue
    };
  }

  formRef = React.createRef();
  componentDidMount() {
    let { search } = this.props.location;
    if (search && /^[\?id=]/.test(search)) {
      let id = search.split("=")[1];
      this.setState({ id });
      api.configTemplateGetInfo({ id }).then((res) => {
        let { code, message: msg, data } = res;
        if (200 !== code) {
          message.error(msg || "获取详情失败");
          return;
        }
        this.setState({
          Data: JSON.parse(data.templateContent),
          title: data.title,
        });
      });
    }
  }
  // 拖拽的更新方法
  sortableAdd(evt) {
    // 组件名或路径
    const nameOrIndex = evt.clone.getAttribute("data-id");
    // 父节点路径
    const parentPath = evt.path[1].getAttribute("data-id");
    // 拖拽元素的目标路径
    const { newIndex } = evt;
    // 新路径 为根节点时直接使用index
    const newPath = parentPath ? `${parentPath}-${newIndex}` : newIndex;
    // 判断是否为路径 路径执行移动，非路径为新增
    if (isPath(nameOrIndex)) {
      this.sortableAddMove(nameOrIndex, newPath);
    } else {
      this.sortableAddNew(nameOrIndex, newPath);
    }
  }
  // 拖拽的更新方法
  sortableAdd(evt) {
    // 组件名或路径
    const nameOrIndex = evt.clone.getAttribute("data-id");
    // 父节点路径 TODO
    // const parentPath = evt.path[1].getAttribute("data-id");
    const parentPath = evt.path
      ? evt.path[1].getAttribute("data-id")
      : evt.composedPath && evt.composedPath()[1]
      ? evt.composedPath()[1].getAttribute("data-id")
      : null;
    // if (parentPath) {
    //   // 执行您的代码
    // } else {
    //   console.error("未能获取父元素的data-id属性值");
    // }
    // 拖拽元素的目标路径
    const { newIndex } = evt;
    // 新路径 为根节点时直接使用index
    const newPath = parentPath ? `${parentPath}-${newIndex}` : newIndex;
    // 判断是否为路径 路径执行移动，非路径为新增
    if (isPath(nameOrIndex)) {
      this.sortableAddMove(nameOrIndex, newPath);
    } else {
      this.sortableAddNew(nameOrIndex, newPath);
    }
  }
  // 拖拽的添加方法
  sortableAddNew(id, newPath, copyData) {
    let newItem = null;
    // 新增流程 创建元素 => 插入元素 => 更新视图
    if (copyData) {
      newItem = _.cloneDeep(copyData);
    } else {
      newItem = _.cloneDeep(soundData.find((item) => item.name === id));
    }

    // 为容器或者弹框时增加子元素
    if (newItem.name === "Containers") {
      newItem.children = [];
    }
    newItem.isFinish = false;
    let Data = itemAdd(newPath, this.state.Data, newItem);
    this.setState({ Data });
  }

  // 拖拽的排序方法
  sortableUpdate(evt) {
    // 交换数组
    const { newIndex, oldIndex } = evt;
    // 父节点路径
    const parentPath =
      evt.currentTarget && evt.currentTarget.getAttribute("data-id");

    // 父元素 根节点时直接调用data
    let parent = parentPath
      ? getItem(parentPath, this.state.Data)
      : this.state.Data;
    // 当前拖拽元素
    const dragItem = parent[oldIndex];
    // 更新后的父节点
    parent = update(parent, {
      $splice: [
        [oldIndex, 1],
        [newIndex, 0, dragItem],
      ],
    });

    // 最新的数据 根节点时直接调用data
    const Data = parentPath
      ? setInfo(parentPath, this.state.Data, parent)
      : parent;
    // 调用父组件更新方法
    this.setState({ Data });
  }

  sortableCopy(indexs) {
    let curItem = this.state.Data[indexs];
    this.sortableAddNew(curItem.name, indexs, curItem);
  }
  sortableDel(indexs) {
    let newTreeData = itemRemove(indexs, this.state.Data);
    this.setState({ Data: newTreeData, currentPath: null });
  }

  sortableEdit(indexs) {
    if (this.formRef.current) {
      let { Data, currentPath } = this.state;
      let curItem = Data[currentPath];
      this.formRef.current
        .validateFields()
        .then((value) => {
          curItem.isFinish = true;
          this.setState({ currentPath: indexs || currentPath, Data });
          this.formRef.current.resetFields();
        })
        .catch(() => {
          curItem.isFinish = false;
        });
    } else {
      this.setState({ currentPath: indexs });
    }
  }
  export(listArr, pathArr) {
    let { Data, currentPath } = this.state;
    let { props } = Data[currentPath];
    if (!props.options) {
      props.options = [];
    }
    let curItem = props.options;
    pathArr.map((index) => {
      if (!curItem[index]) {
        curItem[index] = {
          options: [],
        };
      }
      if (!curItem[index].options) {
        curItem[index].options = [];
      }
      curItem = curItem[index].options;
    });
    console.log(listArr);
    curItem.push(...listArr);
    this.setState({ Data });
    this.formRef.current.setFieldsValue({
      ...props,
    });
  }
  getTempEditabled(item, iscomplex) {
    let hide = null;
    let { Data, currentPath } = this.state;
    let curItem = Data[currentPath];
    let isCascader = ["TempCascader", "TempCascaderLad"].includes(curItem.name);
    const uploadFile = (fileInfo, pathArr) => {
      let { status, response } = fileInfo.file;
      switch (status) {
        case "uploading":
          !hide && (hide = message.loading("文件上传中", 0));
          break;
        case "done":
          hide();
          hide = null;
          if (200 !== response.code) {
            message.error(response.message);
          } else {
            let { data } = response;
            data.map((e, i) => {
              data[i].labelEn = e.lebelEn;
              data[i].labelEN = e.lebelEn;
            });
            this.export(data, pathArr);
          }
          break;
        case "error":
          hide();
          hide = null;
          message.error(response.message);
          break;
      }
    };

    switch (item) {
      case "title":
        return (
          <Form.Item label="标题">
            <Space>
              <Form.Item
                name="title"
                noStyle
                // rules={[{ required: true, message: '请输入标题' }]}
              >
                <Input placeholder="请输入标题" />
              </Form.Item>
              <Form.Item
                name="titleEN"
                noStyle
                // rules={[{ required: true, message: '请输入英文标题' }]}
              >
                <Input placeholder="请输入英文标题" />
              </Form.Item>
            </Space>
          </Form.Item>
        );

      case "field":
        return (
          <Form.Item
            label="字段"
            name="field"
            // rules={[{ required: true, message: '请输入字段' }]}
          >
            <Input placeholder="请输入字段" />
          </Form.Item>
        );
      case "SKU":
        return (
          <Form.Item
            label="字段"
            name="sku"
            // rules={[{ required: true, message: '请输入SKU规格值' }, { pattern: /^[0-9a-zA-Z]+$/, message: 'SKU为数字字母混合' }]}
          >
            <Input placeholder="请输入SKU规格值" />
          </Form.Item>
        );
      case "base":
        return (
          <Form.Item
            label="基准数值"
            name="base"
            // rules={[{ required: true, message: '请输入基准数值' }, { pattern: /^[0-9]+$/, message: '基准数值应为正整数' }]}
          >
            <Input placeholder="请输入基准数值" />
          </Form.Item>
        );
      case "ltBase":
        return (
          <Form.Item
            label="小于基准数值的单价"
            name="ltBase"
            // rules={[{ required: true, message: '请输入小于基准数值的单价' }, { pattern: /^[0-9]+$/, message: '单价应为正整数' }]}
          >
            <Input placeholder="请输入小于基准数值的单价" />
          </Form.Item>
        );
      case "geBase":
        return (
          <Form.Item
            label="大于或等于基准数值的单价"
            name="geBase"
            // rules={[{ required: true, message: '请输入大于或等于基准数值的单价' }, { pattern: /^[0-9]+$/, message: '单价应为正整数' }]}
          >
            <Input placeholder="请输入大于或等于基准数值的单价" />
          </Form.Item>
        );
      case "addBase":
        return (
          <Form.Item
            label="附加费"
            name="addBase"
            // rules={[{ required: true, message: '请输入大于或等于基准数值的单价' }, { pattern: /^[0-9]+$/, message: '单价应为正整数' }]}
          >
            <Input placeholder="请输入附加费" />
          </Form.Item>
        );
      case "options":
        let max = 1;
        if (isCascader) {
          max = 3;
        }
        if (iscomplex) {
          max = 2;
        }
        return (
          <Form.Item label="选项">
            {this.getOptionsForm(uploadFile, "options", [], max)}
          </Form.Item>
        );
      default:
        break;
    }
  }
 
  // getOptionsForm(uploadFile, listName, level, max) {
  //   let shouldExecute = true;
  //   let { Data, currentPath } = this.state;
  //   let curItem = Data[currentPath];
  //   let isMoreOptions = ["TempNumberLadRadio", "TempCascaderLad"].includes(
  //     curItem.name
  //   );
  //   return (
  //     <Form.List name={listName} key={`${level}_${listName}`}>
  //       {(fields, { add, remove }) => {
  //         return (
  //           <div className="template-option-content">
  //             {fields.map(({ key, name, ...restField }) => (
  //               <>
  //                 <Space
  //                   className="template-option-header"
  //                   style={{ display: "flex", marginBottom: 8 }}
  //                   align="baseline"
  //                   key={`${level}_${name}_${key}`}
  //                 >
  //                   <Tooltip title="名称">
  //                     <Form.Item
  //                       {...restField}
  //                       name={[name, "label"]}
  //                       fieldKey={[key, "label"]}
  //                       // rules={[{ required: true, message: '请输入名称' }]}
  //                     >
  //                       <Input placeholder="名称" />
  //                     </Form.Item>
  //                   </Tooltip>
  //                   <Tooltip title="英文名称">
  //                     <Form.Item
  //                       {...restField}
  //                       name={[name, "labelEN"]}
  //                       fieldKey={[key, "labelEN"]}
  //                       // rules={[{ required: true, message: '请输入英文名称' }]}
  //                     >
  //                       <Input placeholder="英文名称" />
  //                     </Form.Item>
  //                   </Tooltip>
  //                   <Tooltip title="SKU规格值">
  //                     <Form.Item
  //                       {...restField}
  //                       name={[name, "sku"]}
  //                       fieldKey={[key, "sku"]}
  //                       // rules={[{ required: true, message: '请输入SKU规格值' }, { pattern: /^[0-9a-zA-Z]+$/, message: 'SKU为数字字母混合' }]}
  //                     >
  //                       <Input placeholder="SKU规格值" />
  //                     </Form.Item>
  //                   </Tooltip>
  //                   {isMoreOptions ? (
  //                     <>
  //                       <Tooltip title="基准数值">
  //                         <Form.Item
  //                           {...restField}
  //                           name={[name, "base"]}
  //                           fieldKey={[key, "base"]}
  //                           // rules={[{ required: true, message: '请输入基准数值' }, { pattern: /^[0-9]+$/, message: '基准数值应为正整数' }]}
  //                         >
  //                           <Input placeholder="基准数值" />
  //                         </Form.Item>
  //                       </Tooltip>
  //                       <Tooltip title="小于基准数值的单价">
  //                         <Form.Item
  //                           {...restField}
  //                           name={[name, "ltBase"]}
  //                           fieldKey={[key, "ltBase"]}
  //                           // rules={[{ required: true, message: '请输入小于基准数值的单价' }, { pattern: /^[0-9]+$/, message: '单价应为正整数' }]}
  //                         >
  //                           <Input placeholder="小于基准数值的单价" />
  //                         </Form.Item>
  //                       </Tooltip>
  //                       <Tooltip title="大于或等于基准数值的单价">
  //                         <Form.Item
  //                           {...restField}
  //                           name={[name, "geBase"]}
  //                           fieldKey={[key, "geBase"]}
  //                           // rules={[{ required: true, message: '请输入大于或等于基准数值的单价' }, { pattern: /^[0-9]+$/, message: '单价应为正整数' }]}
  //                         >
  //                           <Input placeholder="大于或等于基准数值的单价" />
  //                         </Form.Item>
  //                       </Tooltip>
  //                       <Tooltip title="附加费">
  //                         <Form.Item
  //                           {...restField}
  //                           name={[name, "addBase"]}
  //                           fieldKey={[key, "addBase"]}
  //                           // rules={[{ required: true, message: '请输入大于或等于基准数值的单价' }, { pattern: /^[0-9]+$/, message: '单价应为正整数' }]}
  //                         >
  //                           <Input placeholder="附加费" />
  //                         </Form.Item>
  //                       </Tooltip>
  //                     </>
  //                   ) : (
  //                     <Tooltip title="价格">
  //                       <Form.Item
  //                         {...restField}
  //                         name={[name, "price"]}
  //                         fieldKey={[key, "price"]}
  //                         rules={[
  //                           { required: true, message: "请输入价格" },
  //                           {
  //                             pattern:
  //                               /(^([1-9]\d*(\.\d*)?)$)|(^0(\.\d{1,2})?$)/,
  //                             message: "价格不能小于0.01",
  //                           },
  //                         ]}
  //                       >
  //                         <Input type="number" placeholder="价格" />
  //                       </Form.Item>
  //                     </Tooltip>
  //                   )}
  //                   <MinusCircleOutlined onClick={() => remove(name)} />
  //                 </Space>
  //                 {shouldExecute &&
  //                   this.getOptionsForm(
  //                     uploadFile,
  //                     [name, "options"],
  //                     [...level, name],
  //                     max
  //                   )}
  //               </>
  //             ))}
  //             {level.length < max ? (
  //               <Form.Item>
  //                 <Button
  //                   type="dashed"
  //                   style={{ marginBottom: "12px" }}
  //                   onClick={() => {
  //                     let item = curItem.props;
  //                     level.map((index) => (item = item.options[index]));
  //                     if (!item.options) {
  //                       item.options = [{}];
  //                     } else {
  //                       item.options.push({});
  //                     }
  //                     this.formRef.current.setFieldsValue({
  //                       ...curItem.props,
  //                     });
  //                   }}
  //                   block
  //                   icon={<PlusOutlined />}
  //                 >
  //                   新增选项
  //                 </Button>
  //                 <Upload
  //                   name="file"
  //                   action={
  //                     isMoreOptions
  //                       ? "/v1/m/configTemplate/import7Colums"
  //                       : "/v1/m/configTemplate/import"
  //                   }
  //                   headers={{ Authorization: cookie.load("accessToken") }}
  //                   showUploadList={false}
  //                   onChange={(info) => {
  //                     uploadFile(info, level);
  //                   }}
  //                   style={{ width: "100%" }}
  //                 >
  //                   <Button block type="primary">
  //                     导入选项数据
  //                   </Button>
  //                 </Upload>
  //               </Form.Item>
  //             ) : (
  //               ""
  //             )}
  //           </div>
  //         );
  //       }}
  //     </Form.List>
  //   );
  // }
  getOptionsForm(uploadFile, listName, level, max) {
    // console.log(uploadFile)
    // console.log(listName)
    // console.log(level)
    // console.log(max)
    const generateOptionsForm = () => {
      let { Data, currentPath } = this.state;
      let curItem = Data[currentPath];
      let isMoreOptions = ["TempNumberLadRadio", "TempCascaderLad"].includes(
        curItem.name
      );

      return (
        <Form.List name={listName} key={`${level}_${listName}`}>
          {(fields, { add, remove }) => {
            return (
              <div className="template-option-content">
                {fields.map(({ key, name, ...restField }) => (
                  <>
                    <Space
                      className="template-option-header"
                      style={{ display: "flex", marginBottom: 8 }}
                      align="baseline"
                      key={`${level}_${name}_${key}`}
                    >
                      <Tooltip title="名称">
                        <Form.Item
                          {...restField}
                          name={[name, "label"]}
                          fieldKey={[key, "label"]}
                          // rules={[{ required: true, message: '请输入名称' }]}
                        >
                          <Input placeholder="名称" />
                        </Form.Item>
                      </Tooltip>
                      <Tooltip title="英文名称">
                        <Form.Item
                          {...restField}
                          name={[name, "labelEN"]}
                          fieldKey={[key, "labelEN"]}
                          // rules={[{ required: true, message: '请输入英文名称' }]}
                        >
                          <Input placeholder="英文名称" />
                        </Form.Item>
                      </Tooltip>
                      <Tooltip title="SKU规格值">
                        <Form.Item
                          {...restField}
                          name={[name, "sku"]}
                          fieldKey={[key, "sku"]}
                          // rules={[{ required: true, message: '请输入SKU规格值' }, { pattern: /^[0-9a-zA-Z]+$/, message: 'SKU为数字字母混合' }]}
                        >
                          <Input placeholder="SKU规格值" />
                        </Form.Item>
                      </Tooltip>
                      {isMoreOptions ? (
                        <>
                          <Tooltip title="基准数值">
                            <Form.Item
                              {...restField}
                              name={[name, "base"]}
                              fieldKey={[key, "base"]}
                              // rules={[{ required: true, message: '请输入基准数值' }, { pattern: /^[0-9]+$/, message: '基准数值应为正整数' }]}
                            >
                              <Input placeholder="基准数值" />
                            </Form.Item>
                          </Tooltip>
                          <Tooltip title="小于基准数值的单价">
                            <Form.Item
                              {...restField}
                              name={[name, "ltBase"]}
                              fieldKey={[key, "ltBase"]}
                              // rules={[{ required: true, message: '请输入小于基准数值的单价' }, { pattern: /^[0-9]+$/, message: '单价应为正整数' }]}
                            >
                              <Input placeholder="小于基准数值的单价" />
                            </Form.Item>
                          </Tooltip>
                          <Tooltip title="大于或等于基准数值的单价">
                            <Form.Item
                              {...restField}
                              name={[name, "geBase"]}
                              fieldKey={[key, "geBase"]}
                              // rules={[{ required: true, message: '请输入大于或等于基准数值的单价' }, { pattern: /^[0-9]+$/, message: '单价应为正整数' }]}
                            >
                              <Input placeholder="大于或等于基准数值的单价" />
                            </Form.Item>
                          </Tooltip>
                          <Tooltip title="附加费">
                            <Form.Item
                              {...restField}
                              name={[name, "addBase"]}
                              fieldKey={[key, "addBase"]}
                              // rules={[{ required: true, message: '请输入大于或等于基准数值的单价' }, { pattern: /^[0-9]+$/, message: '单价应为正整数' }]}
                            >
                              <Input placeholder="附加费" />
                            </Form.Item>
                          </Tooltip>
                        </>
                      ) : (
                        <Tooltip title="价格">
                          <Form.Item
                            {...restField}
                            name={[name, "price"]}
                            fieldKey={[key, "price"]}
                            rules={[
                              { required: true, message: "请输入价格" },
                              {
                                pattern:
                                  /(^([1-9]\d*(\.\d*)?)$)|(^0(\.\d{1,2})?$)/,
                                message: "价格不能小于0.01",
                              },
                            ]}
                          >
                            <Input type="number" placeholder="价格" />
                          </Form.Item>
                        </Tooltip>
                      )}
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                    {this.getOptionsForm(
                      uploadFile,
                      [name, "options"],
                      [...level, name],
                      max,
                      // loading(1000)
                    )}
                  </>
                ))}
                {level.length < max ? (
                  <Form.Item>
                    <Button
                      type="dashed"
                      style={{ marginBottom: "12px" }}
                      onClick={() => {
                        let item = curItem.props;
                        level.map((index) => (item = item.options[index]));
                        if (!item.options) {
                          item.options = [{}];
                        } else {
                          item.options.push({});
                        }
                        this.formRef.current.setFieldsValue({
                          ...curItem.props,
                        });
                      }}
                      block
                      icon={<PlusOutlined />}
                    >
                      新增选项
                    </Button>
                    <Upload
                      name="file"
                      action={
                        isMoreOptions
                          ? "/v1/m/configTemplate/import7Colums"
                          : "/v1/m/configTemplate/import"
                      }
                      headers={{ Authorization: cookie.load("accessToken") }}
                      showUploadList={false}
                      onChange={(info) => {
                        uploadFile(info, level);
                      }}
                      style={{ width: "100%" }}
                    >
                      <Button block type="primary">
                        导入选项数据
                      </Button>
                    </Upload>
                  </Form.Item>
                ) : (
                  ""
                )}
              </div>
            );
          }}
        </Form.List>
        
      );
    };
    return (
      <div>
        {generateOptionsForm()}{" "}
        {/* Render the generated component */}
      </div>
    );
  }

  getEditForm() {
    const changeTempValue = (changedValues, allValues) => {
      let { Data, currentPath } = this.state;
      let curItem = Data[currentPath];
      curItem.props = Object.assign(curItem.props, allValues);
      this.setState({ Data });
    };

    let { Data, currentPath } = this.state;
    let { config, props } = Data[currentPath];
    return (
      <Form
        ref={this.formRef}
        name="basic"
        layout="vertical"
        autoComplete="off"
        onValuesChange={changeTempValue}
        initialValues={props}
      >
        {config.type.map((item) =>
          this.getTempEditabled(item, config.iscomplex)
        )}
      </Form>
    );
  }

  saveTemp() {
    let hide = message.loading("模版正在校验", 0);
    this.sortableEdit();
    // 防止state未及时更新
    requestAnimationFrame(() => {
      hide();
      let { Data } = this.state;
      let isFinish = Data.every((item) => item.isFinish);
      if (!Data || !Data.length) {
        this.setState({ isTitleVisible: true });
        message.warning("模版内没有配置任何组件，请配置后提交");
      } else if (!isFinish) {
        this.setState({ isTitleVisible: true });
        message.warning("模版内有组件尚未完成配置，请确认全部配置后提交");
      } else {
        this.setState({ isTitleVisible: true });
      }
    });
  }

  async submitTemp() {
    if (!this.state.title) {
      message.warning("标题不能为空");
      return;
    }
    this.closeTitleModal();
    let { Data, id, title } = this.state;
    let result = await api.saveTemp({
      templateContent: JSON.stringify(Data),
      id,
      title,
    });
    if (result?.code === 200) {
      message.success("保存成功");
      this.props.history.push({ pathname: "/index/model/list" });
    } else {
      message.error(result.message || "保存失败");
    }
  }

  goList() {
    this.props.history.push({ pathname: "/index/model/list" });
  }

  closeTitleModal() {
    this.setState({ isTitleVisible: false });
  }
  saveFile(fileList, indexs) {
    let { Data } = this.state;
    let curItem = Data[indexs];
    curItem.props = Object.assign(curItem.props, { fileList });
    this.setState({ Data, currentPath: indexs });
  }

  render() {
    // 递归函数
    const loop = (arr, index) =>
      arr.map((item, i) => {
        const indexs = index === "" ? String(i) : `${index}-${i}`;
        let ComponentInfo = null;
        let fileList = ["TempUpload", "TempVideo", "TempPicture"];
        let event = fileList.includes(item.name)
          ? {
              saveFile: (fileList) => {
                this.saveFile(fileList, indexs);
              },
            }
          : {};
        if (item.children) {
          ComponentInfo = (
            <div className="template-title" {...item.attr} data-id={indexs}>
              {item.props.title}
            </div>
          );
        } else {
          let GlobalComponentInfo = GlobalComponent[item.name];
          ComponentInfo = (
            <GlobalComponentInfo
              data-id={indexs}
              {...item.attr}
              config={item.props || {}}
              {...event}
            />
          );
        }
        return (
          <div
            className={[
              "tempform-item",
              indexs === this.state.currentPath ? "active" : "",
            ].join(" ")}
          >
            {ComponentInfo}
            <div className="tempform-item-ctrl">
              <CopyOutlined
                onClick={() => {
                  this.sortableCopy(indexs);
                }}
              />
              {item.config ? (
                <EditOutlined
                  onClick={() => {
                    this.sortableEdit(indexs);
                  }}
                />
              ) : null}
              <DeleteOutlined
                onClick={() => {
                  this.sortableDel(indexs);
                }}
              />
            </div>
          </div>
        );
      });

    const sortableOption = {
      animation: 150,
      fallbackOnBody: true,
      swapThreshold: 0.65,
      group: {
        name: "formItem",
        pull: true,
        put: true,
      },
    };

    return (
      <div className="template-index">
        <Breadcrumb>
          <Breadcrumb.Item>配置模版</Breadcrumb.Item>
          <Breadcrumb.Item>
            {this.state.id ? "编辑模版" : "新增模版"}
          </Breadcrumb.Item>
        </Breadcrumb>
        <Row gutter={16} style={{ paddingTop: "24px" }}>
          <Col className="gutter-row" span={3}>
            <div className="template-title">基础字段</div>
            <Sortable
              options={{
                group: {
                  name: "formItem",
                  pull: "clone",
                  put: false,
                },
                sort: false,
              }}
            >
              {soundData.map((item) => {
                return (
                  <Button className="sortable-item" block data-id={item.name}>
                    {item.label}
                  </Button>
                );
              })}
            </Sortable>
          </Col>
          <Col className="gutter-row template-container" span={14}>
            {this.state.Data.length ? (
              ""
            ) : (
              <div
                style={{
                  fontSize: "12px",
                  color: "#999",
                  padding: "24px",
                  borderBottom: "1px dashed #eee",
                }}
              >
                拖拽左侧选项至下方区域即可添加组件
              </div>
            )}
            <Form
              name="basic"
              autoComplete="off"
              labelCol={{ span: 4 }}
              style={{ height: "100%" }}
            >
              <Sortable
                ref={(c) => c && (this.sortable = c.sortable)}
                options={{
                  ...sortableOption,
                  onUpdate: (evt) => this.sortableUpdate(evt),
                  onAdd: (evt) => this.sortableAdd(evt),
                }}
                key={uniqueId()}
                style={{ minHeight: "100%" }}
              >
                {loop(this.state.Data, "")}
              </Sortable>
            </Form>
          </Col>
          <Col className="gutter-row" span={7}>
            <div className="template-title">
              属性配置
              {this.state.currentPath && this.state.Data.length ? (
                <Tag color="red">
                  {this.state.Data[this.state.currentPath].label}
                </Tag>
              ) : (
                ""
              )}
            </div>
            {!this.state.currentPath || !this.state.Data.length ? (
              <Empty description="未选择配置组件" />
            ) : (
              this.getEditForm()
            )}
          </Col>
        </Row>
        <Space
          className="d-flex p-2"
          style={{
            justifyContent: "center",
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "#fff",
            boxShadow: "0 0 5px rgba(0,0,0,0.1)",
          }}
        >
          <Button onClick={() => this.goList()}>取消</Button>
          <Button type="primary" onClick={() => this.saveTemp()}>
            保存模版
          </Button>
        </Space>
        <Modal
          title="模版标题"
          visible={this.state.isTitleVisible}
          onOk={() => this.submitTemp()}
          onCancel={() => this.closeTitleModal()}
        >
          <Input
            placeholder="请输入模版标题"
            defaultValue={this.state.title}
            onChange={(e) => this.setState({ title: e.target.value })}
          />
        </Modal>
      </div>
    );
  }
}

export default TemplateForm;
