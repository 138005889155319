import { message } from "antd";
import Json from "./data.json.js";
const utils = {
  timeTrans: (time, hashour) => {
    // console.log(time)
    if (time) {
      time = parseInt(time);
      let date = new Date(time);
      let Y = date.getFullYear();
      let M = date.getMonth() + 1;
      M = M < 10 ? "0" + M : M;
      let D = date.getDate();
      D = D < 10 ? "0" + D : D;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      if (hashour) {
        return `${Y}-${M}-${D} ${h}:${m}:${s}`;
      } else {
        return `${Y}-${M}-${D}`;
      }
    }
  },
  try_catch: (callback) => {
    try {
      callback();
    } catch (error) {
      message.error("网络错误");
    }
  },
  json: (type) => {
    //模拟异步请求数据
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(Json[type]);
      }, 500);
    });
  },
  phone: (s) => {
    return /^(1[3-9][0-9])\d{8}$/.test(s);
  },
  //邮箱
  isEmail: (s) => {
    return /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/.test(
      s
    );
  },
  decimal: (num, digit) => {
    num = Number(num);
    if (typeof num == "number") {
      num = num || 0;
      let f = num.toFixed(digit);
      return f;
    } else {
      // console.error('请输入数值')
      let f = 0;
      return f.toFixed(digit);
    }
  },
  /**
   * @removeSpace 去空格
   * value 需要清除空格的字符串
   */
  removeSpace: (value) => {
    return value.replace(/\s+/g, "");
  },
  /**
   * @dedupe 数组去重
   * array 数组
   */
  dedupe: (array) => {
    return Array.from(new Set(array));
  },
  /**
   * @dedupe 对象去重
   * obj 对象
   */
  dedupeObject: (obj) => {
    let uniques = [];
    let stringify = {};
    for (let i = 0; i < obj.length; i++) {
      let keys = Object.keys(obj[i]);
      keys.sort(function (a, b) {
        return Number(a) - Number(b);
      });
      let str = "";
      for (let j = 0; j < keys.length; j++) {
        str += JSON.stringify(keys[j]);
        str += JSON.stringify(obj[i][keys[j]]);
      }
      if (!stringify.hasOwnProperty(str)) {
        uniques.push(obj[i]);
        stringify[str] = true;
      }
    }
    // uniques = uniques;
    return uniques;
  },
  /**
   * @getProperty 获取&设置元素属性
   * el html元素
   * name html元素属性名称
   * val html元素属性名称的值
   */
  getProperty: (el, name, val) => {
    const prefix = "data-";
    name = prefix + name;
    if (val) {
      return el.setAttribute(name, val);
    } else {
      return el.getAttribute(name);
    }
  },
  /**
   * @hasClass 判断是否已存在class
   * el html元素
   * className html class名称
   */
  hasClass: (el, className) => {
    let reg = new RegExp("(^|\\s)" + className + "(\\s|$)");
    return reg.test(el.className);
  },
  /**
   * @addClass 添加class
   * el html元素
   * className html class名称
   */
  addClass: (el, className) => {
    if (this.hasClass(el, className)) {
      return;
    }
    let newClass = el.className.split(" ");
    newClass.push(className);
    el.className = newClass.join(" ");
  },
  /**
   * @getHost 获取域名主机
   * url 当前页面地址
   * className html class名称
   */
  getHost: (url) => {
    let host = "null";
    if (typeof url == "undefined" || null == url) {
      url = window.location.href;
    }
    let regex = /^\w+\:\/\/([^\/]*).*/;
    let match = url.match(regex);
    if (typeof match != "undefined" && null != match) {
      host = match[1];
    }
    return host;
  },
  /**
   * @getHost 获取域名主机
   * url 当前页面地址
   * className html class名称
   */
  getHost: (url) => {
    let host = "null";
    if (typeof url == "undefined" || null == url) {
      url = window.location.href;
    }
    let regex = /^\w+\:\/\/([^\/]*).*/;
    let match = url.match(regex);
    if (typeof match != "undefined" && null != match) {
      host = match[1];
    }
    return host;
  },
  /**
   * @cutstr 截取字符串长度多余用....代替
   * str 字符串
   * len 需要长度
   */
  cutstr: (str, len) => {
    let temp;
    let icount = 0;
    let patrn = /[^\x00-\xff]/;
    let strre = "";
    for (let i = 0; i < str.length; i++) {
      if (icount < len - 1) {
        temp = str.substr(i, 1);
        if (patrn.exec(temp) == null) {
          icount = icount + 1;
        } else {
          icount = icount + 2;
        }
        strre += temp;
      } else {
        break;
      }
    }
    return strre + "...";
  },
  /**@throttle
   * 节流函数--规定在一个单位时间内，只能触发一次函数。如果这个单位时间内触发多次函数，只有一次生效。
   * fun 监控的函数
   * delay 触发时间
   */
  throttle: (fun, delay) => {
    let last, deferTimer;
    return function (args) {
      let that = this;
      let _args = arguments;
      let now = +new Date();
      if (last && now < last + delay) {
        clearTimeout(deferTimer);
        deferTimer = setTimeout(function () {
          last = now;
          fun.apply(that, _args);
        }, delay);
      } else {
        last = now;
        fun.apply(that, _args);
      }
    };
  },
  ArrayObjSort: (ArrayObj, keya, sequence) => {
    function sortkey(a, b) {
      switch (sequence) {
        case "a-b":
          return a[keya] - b[keya];
        case "b-a":
          return b[keya] - a[keya];
      }
    }
    ArrayObj.sort(sortkey);
    return ArrayObj;
  },
  // 根据value值匹配
  getParent: (data2, nodeId2) => {
    var arrRes = [];
    if (data2.length == 0) {
      if (!!nodeId2) {
        arrRes.unshift(data2);
      }
      return arrRes;
    }
    let rev = (data, nodeId) => {
      for (var i = 0, length = data.length; i < length; i++) {
        let node = data[i];
        if (node.id == nodeId) {
          arrRes.unshift(node);
          // console.log(node.parentId)
          rev(data2, node.parentId); // 注意这里是传入的tree，不要写成data了，不然遍历的时候一直都是node.children,不是从最顶层开始遍历的
          break;
        } else {
          if (!!node.children) {
            rev(node.children, nodeId);
          }
        }
      }
      return arrRes;
    };
    arrRes = rev(data2, nodeId2);
    return arrRes;
  },
};
export default utils;
