import React from 'react'
import { Form, Input } from 'antd'
/**
 * @prop config 属性设置
 */
export default class TempTextarea extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        let { config } = this.props;
        return (
            <Form.Item label={config.title} name={config.field} >
                <Input.TextArea placeholder={`请输入${config.title}`}/>
            </Form.Item>
        )
    }
}
