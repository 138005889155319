/*模板
demo：{
    code:200,
    dataMap:{},
    message:'success'
}，
*/

// 路由json
export default {
  router: {
    code: 200,
    dataMap: [
      { name: '主页', router: '/overview', compent: 'Overview', ordina: 1 },
      { name: '公告', router: '/operate', compent: 'Operate', ordina: 2 },
      { name: '广告位管理', router: '/admgt', compent: 'AdMgt', ordina: 4 },
      { name: '用户管理', router: '/user', compent: 'User', ordina: 3 },
      { name: '邮豆广告管理', router: '/adbean', compent: 'Adbean', ordina: 5 },
      { name: '商户管理', router: '/shopuser', compent: 'Shopuser', ordina: 6 },
      { name: '财务', router: '/finance', compent: 'Finance', ordina: 7 },
      { name: '角色管理', router: '/manager', compent: 'Manager', ordina: 8 },
      { name: '系统', router: '/system', compent: 'System', ordina: 9 }
    ],
    message: 'success'
  }
}

// export default {
//   router: {
//       code: 200,
//       dataMap: [
//           { name: '主页', router: '/overview', compent: 'Overview', ordina: 1, icon: '' },
//           { name: '公告', router: '/operate', compent: 'Operate', ordina: 2 },
//           { name: '广告管理', router: '/AdMgt', compent: 'AdMgt', ordina: 3 },
//           { name: '平台用户', router: '/platform', compent: 'Platform', ordina: 4 },
//           { name: '平台商户', router: '/shopuser', compent: 'Shopuser', ordina: 5 },
//           { name: '广告商', router: '/aduser', compent: 'Aduser', ordina: 6 },
//           { name: '管理员', router: '/manager', compent: 'Manager', ordina: 7 },
//           // { name: '用户列表', router: '/user', compent: 'User', ordina: 4 },
//           { name: '财务', router: '/finance', compent: 'Finance', ordina: 8 },
//           { name: '系统', router: '/system', compent: 'System', ordina: 9 }
//       ],
//       message: 'success'
//   }
// }

let video = [
  {
    name: '视频1',
    src: 'https://www.video.com/123456',
    showList: [
      {
        time: 13.3,
        content: '你好',
        action: 'barrage', //触发弹幕消息
        num: 10, //触发次数
        interval: 2 //触发间隔 单位秒
      },
      {
        time: 14.3,
        content: '',
        action: 'flower', //触发鲜花消息
        num: 10, //触发次数
        interval: 2 //触发间隔 单位秒
      }
    ]
  },
  {
    name: '视频2',
    src: 'https://www.video.com/456789',
    showList: [
      {
        time: 13.3,
        content: '你好',
        action: 'barrage', //触发弹幕消息
        num: 10, //触发次数
        interval: 2 //触发间隔 单位秒
      },
      {
        time: 14.3,
        content: '',
        action: 'flower', //触发鲜花消息
        num: 10, //触发次数
        interval: 2 //触发间隔 单位秒
      }
    ]
  }
]
