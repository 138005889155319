// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/image/feejoy-logo.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".register,\n.login {\n  width: 100%;\n  height: calc(100vh);\n  position: relative;\n  text-align: left;\n}\n.header-logo {\n  margin: 10px 10% 0 ;\n  max-width: 200px;\n  width: 80%;\n  height: 100px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: center;\n  background-size: contain;\n  background-repeat: no-repeat;\n  text-align: left;\n  top: 0;\n  z-index: 2;\n  position: absolute;\n}\n.login .bg {\n  width: 100%;\n  height: calc(100vh);\n  position: relative;\n  background-position: center;\n  background-size: cover;\n  text-align: left;\n}\n.login .ant-row,\n.login .ant-col {\n  position: relative;\n  height: 100%;\n  margin: 5px !important;\n  padding: 0 !important;\n}\n.login .middle-box {\n  position: relative;\n  height: 100%;\n  margin: 0 auto 0;\n  background-color: #f9f9f9;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.login-box {\n  margin-top: -110px;\n}\n.login .gologin {\n  display: block;\n  font-size: 0.2rem;\n  text-align: center;\n}\n.login .gologin a {\n  line-height: 3;\n}\n.login .login-form {\n  width: 300px;\n  background-color: #fff;\n  padding: 40px;\n  box-shadow: 0 0 20px #cbcbcb;\n  border-radius: 30px;\n  overflow: hidden;\n}\n.login .login-form-button {\n  width: 100%;\n}\n.login div,\n.register div {\n  -moz-user-select: none;\n  -webkit-user-select: none;\n  user-select: none;\n}\n.register .ant-form-item-control-input .ant-btn {\n  width: 100%;\n}\n.register > div {\n  position: absolute;\n  width: 100%;\n  top: 50%;\n  margin: auto;\n  transform: translateY(-50%);\n}\n.register .gologin {\n  display: block;\n  font-size: 0.2rem;\n  text-align: center;\n}\n.register .gologin a {\n  line-height: 3;\n}\n.register .register-form {\n  width: 350px;\n  margin: auto;\n  background-color: #fff;\n  padding: 20px 30px;\n}\n.register .register-form-button {\n  width: 100%;\n}\n", ""]);
// Exports
module.exports = exports;
