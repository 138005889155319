import React from "react";
import { Table, Button, Select, DatePicker, Form } from "antd";
import api from "../../api";
import moment from "moment";
import utils from "../../assets/js/public.js";
const { Option } = Select;
const { RangePicker } = DatePicker;
class LoginLogs extends React.Component {
  state = {
    data: [],
    pagination: {
      pageNo: 1,
      pageSize: 10,
    },
    loading: false,
    showDetail: false,
    showEdit: false,
    chooseUserInfo: {},
    selectedRowKeys: [],
  };
  componentWillUnmount() {
    this.setState = () => false;
  }
  componentDidMount() {
    const { pagination } = this.state;
    this.fetch(pagination);
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.setState(
      {
        pagination: {
          ...pagination,
          pageNo: pagination.current,
        },
      },
      () => {
        this.fetch({
          sortField: sorter.field,
          sortOrder: sorter.order,
          ...pagination,
          pageNo: pagination.current,
          ...filters,
        });
      }
    );
  };
  onSelectChange = () => {};
  onFinish = (values) => {
    const { pagination } = this.state;
    const _t = this;
    let [createTimeFrom, createTimeTo] = values.date || [null, null];
    this.setState(
      {
        pagination: {
          ...pagination,
          pageNo: 1,
          current: 1,
          createTimeFrom,
          createTimeTo,
        },
      },
      () => {
        _t.fetch({ ..._t.state.pagination });
      }
    );
  };
  fetch = async (params = {}) => {
    this.setState({ loading: true });
    delete params.total;
    if (!!params.createTimeFrom) {
      params.createTimeFrom = moment(params.createTimeFrom).format(
        "YYYY-MM-DD"
      );
    } else {
      delete params.createTimeFrom;
    }
    if (!!params.createTimeTo) {
      params.createTimeTo = moment(params.createTimeTo).format("YYYY-MM-DD");
    } else {
      delete params.createTimeTo;
    }
    let result = await api.getLoginLogList(params);
    if (!result || result.code !== 200) {
      return false;
    }
    this.setState({
      loading: false,
      data: (result && result.data.records) || [],
      pagination: {
        ...this.state.pagination,
        pageNo: (result && result.data.pageNo) || 1,
        pageSize: (result && result.data.pageSize) || 10,
        total: (result.data && result.data.totalRecords) || 1,
      },
    });
  };

  render() {
    const { data, pagination, loading, selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    // const hasSelected = selectedRowKeys.length > 0;
    const ruleMenu =
      (
        localStorage.getItem("ruleMenu") &&
        JSON.parse(localStorage.getItem("ruleMenu"))
      ).find((e) => e.path == "/index/logs")?.children || [];

    const columns = [
      {
        title: "用户",
        dataIndex: "nickName",
        key: "nickName",
        width: 150,
        render: (index, data) => data.nickName,
      },
      {
        title: "ip",
        dataIndex: "ip",
        key: "ip",
        width: 150,
        render: (index, data) => data.ip,
      },
      {
        title: "平台",
        dataIndex: "platform",
        key: "platform",
        width: 200,
        render: (index, data) => data.platform,
      },
      {
        title: "状态",
        dataIndex: "logStatus",
        key: "logStatus",
        width: 200,
        render: (index, data) => data.logStatus,
      },
      {
        title: "时间",
        dataIndex: "createTime",
        key: "createTime",
        width: 200,
      },
    ];
    const handleSelectChange = () => {
      console.log("选择成员");
    };
    const exportEvent = () => {
      console.log("导出");
    };
    const onEditUser = (values) => {
      console.log("Received values of form: ", values);
    };

    return (
      <div>
        <div className="d-flex align-item-center pb-2 mb-2 border-bottom ">
          <h3>登录日志</h3>
          <div className="ml-auto">
            {(ruleMenu &&
              ruleMenu.findIndex((e) => e.path == "/index/logs/login/export") >=
                0 && (
                <Button type="primary" onClick={exportEvent}>
                  导出
                </Button>
              )) ||
              ""}
          </div>
        </div>
        <Form
          className="mb-1"
          name="logs-form"
          layout="inline"
          onFinish={this.onFinish}
        >
          <Form.Item name="date" label="日期">
            <RangePicker />
          </Form.Item>
          <span className="text-tips mr-1">（单次操作最长不超过一年）</span>
          {/* <Form.Item name="sysUserId" label="成员">
            <Select 
              getPopupContainer={triggerNode => triggerNode.parentNode}
              defaultValue="all"
              onChange={handleSelectChange}
             >
              <Option value="all">所有成员</Option>
            </Select>
          </Form.Item> */}
          <Form.Item className="ml-auto">
            <Button type="primary" htmlType="submit" disabled={this.loading}>
              查询
            </Button>
          </Form.Item>
        </Form>

        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={data}
          pagination={{ ...pagination, showSizeChanger: false }}
          loading={loading}
          onChange={this.handleTableChange}
          size="small"
        />
      </div>
    );
  }
}

export default LoginLogs;
