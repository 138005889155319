import React from 'react'

import { Switch, Route, Redirect, Link } from 'react-router-dom'
import { Spin, Form, Input, Button } from 'antd'
import { AppstoreOutlined } from '@ant-design/icons'
import RcUeditor from 'react-ueditor-wrap'
import utils from '../../assets/js/public'
import api from '../../api'

class Deal extends React.Component {
  state = {
    login_state: false,
    editorConfig: {
      toolbars: [
        [
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'forecolor',
          'backcolor',
          'justifyleft',
          'justifycenter',
          'justifyright',
          '|',
          'insertunorderedlist',
          'insertorderedlist',
          'blockquote'
        ]
      ],
      autoClearinitialContent: false,
      autoFloatEnabled: false,
      autoHeightEnabled: false,
      focus: false,
      initialFrameWidth: 740, // 初始化编辑器宽度
      initialFrameHeight: 300,
      maximumWords: 100000
    },
    contentOne: ``,
    contentTwo: ``,
    contentThree: ``,
    contentFour: ``
  }
  componentDidMount = () => {
    // this.setState({ loading: true })
    // console.log(ue)
    this.getDeal()
  }

  getDeal = async () => {
    let res = await api.getDealInfo()
    // console.log(res)
    // console.log(this);

    this.setState({
      contentOne: res.dataMap.contentOne,
      contentTwo: res.dataMap.contentTwo,
      contentThree: res.dataMap.contentThree,
      contentFour: res.dataMap.contentFour
    })
  }
  hanldeChage = (value, type) => {
    this.setState({
      [type]: value
    })
    // console.log('RcUeditor', value, type)
  }

  render() {
    const layout = {
      labelCol: {
        span: 3
      },
      wrapperCol: {
        span: 8
      }
    }
    const validateMessages = {
      required: '${label} 不能为空!'
    }
    const onFinish = async (values) => {
      // console.log(values)
      let params = {
        id: 1,
        contentOne: this.state.contentOne,
        contentTwo: this.state.contentTwo,
        contentThree: this.state.contentThree,
        contentFour: this.state.contentFour
      }
      let res = await api.updateDealInfo(params)
      // console.log(res)
    }
    return (
      <div>
        <Form
          {...layout}
          name="deal"
          onFinish={onFinish}
          // validateMessages={validateMessages}
          initialValues={{
            contentOne: this.state.contentOne,
            contentTwo: this.state.contentTwo,
            contentThree: this.state.contentThree,
            contentFour: this.state.contentFour
          }}
        >
          <Form.Item
            name={'contentOne'}
            label="APP飞卓协议"
            // rules={[
            //   {
            //     required: true
            //   }
            // ]}
          >
            {/* <Input.TextArea /> */}
            <Input type="hidden" />
            <RcUeditor
              ueditorUrl="/ueditor/ueditor.all.js"
              ueditorConfigUrl="/ueditor/ueditor.config.js"
              value={this.state.contentOne}
              editorConfig={this.state.editorConfig}
              onChange={(e) => this.hanldeChage(e, 'contentOne')}
            />
          </Form.Item>
          <Form.Item
            name={'contentTwo'}
            label="飞卓信息发布规则"
            // rules={[
            //   {
            //     required: true
            //   }
            // ]}
          >
            <Input type="hidden" />
            <RcUeditor
              ueditorUrl="/ueditor/ueditor.all.js"
              ueditorConfigUrl="/ueditor/ueditor.config.js"
              value={this.state.contentTwo}
              editorConfig={this.state.editorConfig}
              onChange={(e) => this.hanldeChage(e, 'contentTwo')}
            />
          </Form.Item>
          <Form.Item
            name={'contentThree'}
            label="购买服务协议"
            // rules={[
            //   {
            //     required: true
            //   }
            // ]}
          >
            <Input type="hidden" />
            <RcUeditor
              ueditorUrl="/ueditor/ueditor.all.js"
              ueditorConfigUrl="/ueditor/ueditor.config.js"
              value={this.state.contentThree}
              editorConfig={this.state.editorConfig}
              onChange={(e) => this.hanldeChage(e, 'contentThree')}
            />
          </Form.Item>
          <Form.Item
            name={'contentFour'}
            label="用户协议"
            // rules={[
            //   {
            //     required: true
            //   }
            // ]}
          >
            <Input type="hidden" />
            <RcUeditor
              ueditorUrl="/ueditor/ueditor.all.js"
              ueditorConfigUrl="/ueditor/ueditor.config.js"
              value={this.state.contentFour}
              editorConfig={this.state.editorConfig}
              onChange={(e) => this.hanldeChage(e, 'contentFour')}
            />
          </Form.Item>
          <Form.Item wrapperCol={{ ...layout.labelCol, offset: 2 }}>
            <Button type="primary" htmlType="submit">
              提交
            </Button>
          </Form.Item>
        </Form>
      </div>
    )
  }
}
export default Deal
