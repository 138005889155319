import React, { useEffect, useState } from "react";

import { Route, Redirect, Link } from "react-router-dom";
import {
  Table,
  Row,
  Col,
  Button,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Checkbox,
  message,
  Cascader,
} from "antd";
import { SearchOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import api from "../../api";
import utils from "../../assets/js/public.js";
const { Option } = Select;
const { confirm } = Modal;

const getRandomuserParams = (params) => {
  console.log(params);
  return {
    pageSize: params.pageSize,
    pageNo: params.pageNo,
  };
};
const CheckboxGroup = Checkbox.Group;

const EditRolesForm = ({ visible, onCreate, onCancel, chooseUserInfo }) => {
  const [form] = Form.useForm();
  const [classifyArr, setClassifyArr] = React.useState([]);
  const layout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 16,
    },
  };
  React.useEffect(() => {
    form.resetFields();
    let fn;
    fn = api.sysResourceQueryAll();
    fn.then((res) => {
      if (res?.code === 200) {
        let classify = [
          {
            id: "0",
            parentId: "0",
            resourceName: "顶级类目",
            children: res.data,
          },
        ];
        if (!chooseUserInfo) {
          classify = JSON.stringify(classify)
            .replace(/id/g, "value")
            .replace(/resourceName/g, "label");
          classify = JSON.parse(classify);
          console.log(classify);
          setClassifyArr(classify);
        } else {
          classify = JSON.stringify(classify)
            .replace(/id/g, "value")
            .replace(/resourceName/g, "label");
          classify = JSON.parse(classify);
          setClassifyArr(classify);
          const classifyVal = [
            "0",
            ...utils.getParent(res.data, chooseUserInfo.id).map((e) => e.id),
          ];
          form.setFieldsValue({
            ...chooseUserInfo,
            parentId: classifyVal,
          });
          // chooseUserInfo.parentId = classifyVal
          console.log(classifyVal);
          console.log(classify)
        }
      }
    });
    if (visible) {
      form.resetFields();
    }
  }, [chooseUserInfo, visible]);

  return (
    <Modal
      getContainer={false}
      visible={visible}
      title="设置路由信息"
      width={950}
      okText={"保存"}
      cancelText={"取消"}
      onOk={() => {
        // form.submit();
        onCreate(form.getFieldsValue());
      }}
      onCancel={onCancel}
      destroyOnClose
    >
      <Form
        form={form}
        {...layout}
        name="form_in_modal"
        // initialValues={chooseUserInfo}
      >
        <div>
          <Row gutter={30}>
            <Form.Item name="id">
              <Input type="hidden" value={chooseUserInfo.id} disabled />
            </Form.Item>
            <Col span={12}>
              <Form.Item name="resourceCode" label="资源编码">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="resourceName" label="路由名称">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="path" label="路由地址">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="resourceType" label="资源类型">
                <Select>
                  <Select.Option value={1}>路由</Select.Option>
                  <Select.Option value={2}>按钮</Select.Option>
                  <Select.Option value={3}>方法</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="icon" label="图标">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="isVisible" label="是否隐藏">
                <Select>
                  <Select.Option value={false}>否</Select.Option>
                  <Select.Option value={true}>是</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="parentId" label="父级路由">
                <Cascader
                  changeOnSelect={true}
                  options={
                    (classifyArr && classifyArr.length && classifyArr) || false
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="sortNumber" label="排序">
                <InputNumber />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
    </Modal>
  );
};

class Userlist extends React.Component {
  state = {
    data: [],
    pagination: {
      pageNo: 1,
      pageSize: 10,
    },
    loading: false,
    showDetail: false,
    showEdit: false,
    showBindCondition: false,
    chooseUserInfo: {},
    categoryAll: [],
  };
  componentWillUnmount() {
    this.setState = () => false;
  }
  componentDidMount() {
    this.getAll();
  }
  modalChange(type, info) {
    const _t = this;
    this.setState({ chooseUserInfo: info ? info : {} }, () => {
      _t.setState({
        [type]: !_t.state[type],
      });
    });
  }
  getAll = async () => {
    api.sysResourceQueryAll().then((res) => {
      this.setState({
        categoryAll: res?.data || [],
        data: res?.data || [],
      });
    });
  };

  showDeleteConfirm = (info) => {
    const { pagination } = this.state;
    const _t = this;
    confirm({
      title: "您确定要执行删除操作吗?",
      icon: <ExclamationCircleOutlined />,
      content: "此操作将永久删除路由，是否继续？",
      okText: "是",
      okType: "danger",
      cancelText: "否",
      onOk() {
        api.sysResourceDelete(info).then((result) => {
          if (!result || 200 !== result.code) {
            message.error(result.message);
          } else {
            message.success("删除成功");
            _t.getAll();
          }
        });
      },
      onCancel() {},
    });
  };

  render() {
    const { data, loading } = this.state;
    const ruleMenu =
      (
        localStorage.getItem("ruleMenu") &&
        JSON.parse(localStorage.getItem("ruleMenu"))
      ).find((e) => e.path == "/index/resource")?.children || [];

    const columns = [
      {
        title: "序号",
        dataIndex: "id",
        width: 60,
        key: "id",
        fixed: "left",
        render: (index, data, k) => k + 1,
      },
      {
        title: "路由名称",
        dataIndex: "resourceName",
        width: 150,
        key: "resourceName",
      },
      {
        title: "路由地址",
        dataIndex: "path",
        width: 150,
        key: "path",
      },
      {
        title: "父级路由ID",
        dataIndex: "parentId",
        width: 200,
        key: "parentId",
        render: (text, data) => {
          const tree = utils.getParent(this.state.categoryAll, data.id);
          return (
            (tree.length > 2 && tree[tree.length - 2]?.categoryName) ||
            (tree.length == 2 && tree[0]?.categoryName) ||
            "顶级路由"
          );
        },
      },
      {
        title: "创建时间",
        dataIndex: "createTime",
        width: 200,
        key: "createTime",
      },
      {
        title: "操作",
        width: 150,
        fixed: "right",
        key: "ctrl",
        render: (index, data) => {
          return (
            <div>
              {(ruleMenu &&
                ruleMenu.findIndex((e) => e.path == "/index/resource/del") >=
                  0 && (
                  <Button
                    type="primary"
                    danger
                    size="small"
                    className="mr-1"
                    onClick={() => this.showDeleteConfirm(data)}
                  >
                    删除
                  </Button>
                )) ||
                ""}
              {(ruleMenu &&
                ruleMenu.findIndex((e) => e.path == "/index/resource/edit") >=
                  0 && (
                  <Button
                    type="primary"
                    size="small"
                    className="mr-1"
                    onClick={() => this.modalChange("showEdit", data)}
                  >
                    修改
                  </Button>
                )) ||
                ""}
            </div>
          );
        },
      },
    ];

    const onEditUser = async (values) => {
      values.parentId = values.parentId[values.parentId.length - 1];
      let result = await api.sysResourceAddOrUpdate(values);
      if (!result || result.code !== 200) {
        message.error(result.message);
        return false;
      }

      this.getAll();

      this.modalChange("showEdit", false);
    };

    return (
      <div>
        <div className="search-box d-flex mb-3">
          <div className="d-flex align-item-center">
            <div className="mr-1">
              {(ruleMenu &&
                ruleMenu.findIndex((e) => e.path == "/index/resource/add") >=
                  0 && (
                  <Button
                    type="primary"
                    onClick={() => {
                      console.log(this.state.chooseUserInfo);
                      this.setState({ chooseUserInfo: {} });
                      this.modalChange("showEdit", {
                        sex: 1,
                      });
                    }}
                  >
                    添加路由
                  </Button>
                )) ||
                ""}
            </div>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          size="small"
          rowKey="id"
        />

        {/* 修改用户信息 */}
        {this.state.showEdit && (
          <EditRolesForm
            visible={this.state.showEdit}
            onCreate={onEditUser}
            onCancel={() => {
              this.modalChange("showEdit", false);
            }}
            chooseUserInfo={this.state.chooseUserInfo}
          />
        )}
      </div>
    );
  }
}

export default Userlist;
