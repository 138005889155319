import React from "react";
import { Form, Select, Radio } from "antd";
/**
 * @prop config 属性设置
 */
export default class TempComplexSelect extends React.Component {
  constructor(props) {
    super(props);
  }

  getRaiodItem(item, index) {
    return (
      <Radio value={item.value} key={`${item.value}_${index}_radio`}>
        <span style={{ paddingRight: "12px" }}>{item.label || item.value}</span>
        <Select
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
          style={{ width: "120px" }}
          placeholder={`请选择子项`}
        >
          {item.options
            .filter((item) => !!item && (item.value || item.label))
            .map((item) => (
              <Select.Option
                key={`${item.value}_${index}_select`}
                value={item.value}
              >
                {item.label}
              </Select.Option>
            ))}
        </Select>
      </Radio>
    );
  }
  render() {
    let { config } = this.props;
    let options = config.options
      .filter((item) => !!item && (item.value || item.label))
      .map((item, index) => {
        item.value = `${item.label}_${index}`;
        !item.options && (item.options = []);
        return item;
      });
    return (
      <Form.Item label={config.title} className="complex-select">
        <Radio.Group name="radiogroup">
          {options.map((item, index) => this.getRaiodItem(item, index))}
        </Radio.Group>
      </Form.Item>
    );
  }
}
