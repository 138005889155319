import React, { useEffect, useRef, useState } from "react";
import { Route, Redirect, Link } from "react-router-dom";
import {
  Table,
  Row,
  Col,
  Form,
  DatePicker,
  Button,
  Descriptions,
  message,
  Progress,
  Card,
} from "antd";
import {
  SearchOutlined,
  DownloadOutlined,
  PieChartOutlined,
  ShoppingCartOutlined,
  TeamOutlined,
  ShopOutlined,
} from "@ant-design/icons";
import Echarts from "echarts-for-react";
import api from "../../api";
import qs from "qs";
import _, { conforms } from "lodash";
import moment from "moment";
import utils from "../../assets/js/public.js";

const Overview = () => {
  const [loading, setLoading] = useState(false);
  const [defaultDateTime, setDefaultDateTime] = useState([
    moment().startOf("month"),
    moment().endOf("month"),
  ]);
  const [defaultDateTime1, setDefaultDateTime1] = useState([
    moment().startOf("month"),
    moment().endOf("month"),
  ]);
  const [defaultDateTime2, setDefaultDateTime2] = useState([
    moment().startOf("month"),
    moment().endOf("month"),
  ]);
  const [defaultDateTime3, setDefaultDateTime3] = useState([
    moment().startOf("month"),
    moment().endOf("month"),
  ]);
  const [defaultDateTime4, setDefaultDateTime4] = useState([
    moment().startOf("month"),
    moment().endOf("month"),
  ]);
  const { RangePicker } = DatePicker;
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [rankingListVOS, setRankingListVOS] = useState([]);
  const [overviewInfo, setOverviewInfo] = useState({});
  const [percentage, setPercentage] = useState(0);
  let categoryOrderTrendStatisticRes = null;
  const columns = [
    {
      title: "业务员",
      dataIndex: "contacts1",
      key: "contacts1",
    },
    {
      title: "销售额",
      dataIndex: "salesAmount",
      key: "salesAmount",
      sorter: (a, b) => a.salesAmount - b.salesAmount,
    },
    {
      title: "订单数",
      dataIndex: "totalCount",
      key: "totalCount",
      sorter: (a, b) => a.orderCount - b.orderCount,
    },
  ];
  const handleTableChange = (pagination, filters, sorter) => {
    setPagination(pagination);
  };

  const handleDateChange = (dates) => {
    setDefaultDateTime(dates);
    setPagination({
      ...pagination,
      current: 1,
    });
  };
  const [pieOption, setPieOption] = useState({
    legend: {},
    tooltip: {
      formatter: "{a} <br/>{b} : {c.toFixed(2)}%",
    },
    series: [
      {
        name: "公司业绩完成率",
        type: "gauge",
        detail: {
          formatter: "{value}%",
        },
        data: [
          {
            value: percentage,
            name: "业绩完成率",
          },
        ],
      },
    ],
  });
  const [barOption3, setBarOption3] = useState({
    dataset: {
      source: [
        ["10000000", "销售一部"],
        ["150000000", "销售二部"],
        ["50000000", "销售三部"],
        ["80000000", "销售四部"],
        ["60000000", "销售五部"],
      ],
    },
    xAxis: { name: "销售额" },
    yAxis: { type: "category" },
    visualMap: {
      min: 0,
      max: 200000000,
      orient: "horizontal",
      left: "center",
      dimension: 0,
    },
    series: [
      {
        type: "bar",
        encode: {
          x: "销售额",
          y: "部门",
        },
        label: {
          show: true,
          position: "inside", // 或者 "top"
          formatter: "{c}", // 显示数据值
          offset: [100, 0], // 向右移动10个像素
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: "{b}: {c}", // 显示部门名称和数据值
        },
      },
    ],
  });
  const [barOption4, setBarOption4] = useState({
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b}: {c} ({d}%)",
    },
    legend: {
      orient: "vertical",
      left: 10,
      data: ["产品A", "产品B", "产品C", "产品D", "产品E"],
    },
    series: [
      {
        name: "产品销量",
        type: "pie",
        radius: ["50%", "70%"],
        avoidLabelOverlap: false,
        label: {
          show: true, // 显示标签        
          position: "outside", // 标签位置        
          formatter: "{b}: {c} ({d}%)", // 标签格式      
        },
        emphasis: {
          label: {
            show: true,
            fontSize: "30",
            fontWeight: "bold",
          },
        },
        labelLine: {
          show: true, // 显示标签线        
          length: 20, // 标签线长度      
        },
        data: [
          { value: 335, name: "产品A" },
          { value: 310, name: "产品B" },
          { value: 234, name: "产品C" },
          { value: 135, name: "产品D" },
          { value: 1548, name: "产品E" },
        ],
      },
    ],
  });
  const [form] = Form.useForm();

  const searchDatas = async () => {
    //产品分类销售额：漏斗图
    let queryAllSaleByDateRes = await api.queryAllSaleByDate({
      timeFrom: moment(defaultDateTime[0]).format("YYYY-MM-DD"),
      timeTo: moment(defaultDateTime[1]).format("YYYY-MM-DD"),
    });

    console.log(queryAllSaleByDateRes);
    if (queryAllSaleByDateRes.code == 200) {
      setOverviewInfo({
        orderAmount: queryAllSaleByDateRes.data.orderAmount,
        orderNum: queryAllSaleByDateRes.data.orderNum,
        productNumber: queryAllSaleByDateRes.data.productNumber,
      });
    }
  };
  const searchDatas1 = async () => {
    categoryOrderTrendStatisticRes = await api.categoryOrderTrendStatistic({
      timeFrom: moment(defaultDateTime1[0]).format("YYYY-MM-DD"),
      timeTo: moment(defaultDateTime1[1]).format("YYYY-MM-DD"),
    });
    const percentageValue = (
      categoryOrderTrendStatisticRes.data / 200000000
    ).toFixed(3);
    setPercentage(percentageValue);
    setPieOption((prevOption) => ({
      ...prevOption,
      series: [
        {
          ...prevOption.series[0],
          data: [
            {
              ...prevOption.series[0].data[0],
              value: parseFloat((percentageValue * 100).toFixed(3)),              
            },
          ],
        },
      ],
    }));
  };
  const searchDatas2 = async () => {
    try {
      const res = await api.OrderRankingList({
        timeFrom: moment(defaultDateTime2[0]).format("YYYY-MM-DD"),
        timeTo: moment(defaultDateTime2[1]).format("YYYY-MM-DD"),
      });
      setData(res);
    } catch (error) {
      console.log(error);
    }
  };
  const searchDatas3 = async () => {
    let departmentalSalesListVOS = await api.DepartmentalSales({
      timeFrom: moment(defaultDateTime3[0]).format("YYYY-MM-DD"),
      timeTo: moment(defaultDateTime3[1]).format("YYYY-MM-DD"),
    });
    const newData = departmentalSalesListVOS.map((item) => {
      return [item.totalSales, item.department];
    });
    setBarOption3((prevOption) => ({
      ...prevOption,
      dataset: {
        source: newData,
      },
    }));
  };
  const searchDatas4 = async () => {
    let categorySalePer = await api.categorySalePer({
      timeFrom: moment(defaultDateTime4[0]).format("YYYY-MM-DD"),
      timeTo: moment(defaultDateTime4[1]).format("YYYY-MM-DD"),
    });
    // 将查询到的数据转换为饼图的数据格式 
    let data = [];
    let legendData = [...barOption4.legend.data]; // 新增一个数组用于存储 legend 数据，初始值为之前的 legendData  
    for (let i = 0; i < categorySalePer.data.length; i++) {
      data.push({
        value: categorySalePer.data[i].totalAmount,
        name: categorySalePer.data[i].productTitle,
      });
      if (!legendData.includes(categorySalePer.data[i].categoryName)) { // 如果之前的 legendData 中没有该分类的名称，则将其添加到数组中      
        legendData.push(categorySalePer.data[i].categoryName);
      }
    }
    console.log(data);
    console.log(legendData);
    setBarOption4(prevOption => ({
      ...prevOption,
      legend: {
        ...prevOption.legend,
        data: legendData // 将更新后的 legendData 数组作为 legend 数据的值  
      },
      series: [
        {
          ...prevOption.series[0],
          data: data      
        }
      ]
    }));
  };
  useEffect(() => {
    // sendChallenge({ CHALLENGE: 'your challenge value' })
    // .then(data => {
    //   console.log(data); // 输出返回的数据
    // })
    // .catch(error => {
    //   console.error(error);
    // });  
    searchDatas();
    searchDatas1();
    searchDatas2();
    searchDatas3();
    searchDatas4();
  }, []);
  const ruleMenuParent =
    localStorage.getItem("ruleMenu") &&
    JSON.parse(localStorage.getItem("ruleMenu")).find(
      (e) => e.path == "/index/overview"
    );
  const ruleMenu =
    (localStorage.getItem("ruleMenu") &&
      JSON.parse(localStorage.getItem("ruleMenu")).find(
        (e) => e.path == "/index/overview"
      )?.children) ||
    [];

  return (
    (localStorage.getItem("ruleMenu") && ruleMenuParent && (
      <div id="overviewLayout" style={{ overflow: "hidden" }}>
        <Descriptions
          title={
            <Row gutter={30}>
              <Col>
                <DatePicker.RangePicker
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  value={defaultDateTime}
                  ranges={{
                    今天: [moment(), moment()],
                    当月: [moment().startOf("month"), moment().endOf("month")],
                    今年: [moment().startOf("years"), moment().endOf("years")],
                  }}
                  onChange={(val) => {
                    setDefaultDateTime(val);
                  }}
                />
              </Col>
              <Col>
                <Button
                  type="primary"
                  className="mr-1"
                  onClick={() => {
                    searchDatas();
                  }}
                >
                  查询
                </Button>
                {(ruleMenu &&
                  ruleMenu.findIndex(
                    (e) => e.path == "/index/overview/export1"
                  ) >= 0 && (
                    <Button
                      type="ghost"
                      onClick={async () => {
                        let result = await api.queryAllSaleByDateExport({
                          timeFrom: moment(defaultDateTime[0]).format(
                            "YYYY-MM-DD"
                          ),
                          timeTo: moment(defaultDateTime[1]).format(
                            "YYYY-MM-DD"
                          ),
                        });

                        message.success("导出成功！");
                        let url = window.URL.createObjectURL(
                          new Blob([result])
                        );
                        // 创建A标签
                        let link = document.createElement("a");
                        link.style.display = "none";
                        link.href = url;
                        // 设置的下载文件文件名
                        const fileName = `数据统计.xlsx`;
                        // 触发点击方法
                        link.setAttribute("download", fileName);
                        document.body.appendChild(link);
                        link.click();
                      }}
                    >
                      导出
                    </Button>
                  )) ||
                  ""}
              </Col>
            </Row>
          }
        />
        <div>
          <Row className="basicInfo" gutter={30}>
            <Col className="basicInfo-item" span={6}>
              <div
                className="d-flex"
                style={{
                  alignItems: "center",
                  flex: "0 1",
                  justifyContent: "space-between",
                  padding: "20px",
                  border: "1px solid #eee",
                  borderRadius: "10px",
                }}
              >
                <div>
                  <PieChartOutlined
                    style={{ fontSize: 40, color: "rgb(0,128,251)" }}
                  />
                </div>
                <div style={{ textAlign: "right" }}>
                  <div style={{ fontSize: "28px", fontWeight: "bolder" }}>
                    {overviewInfo.orderAmount || "-"}
                  </div>
                  <p
                    style={{
                      color: "#9d9d9d",
                      fontSize: "16px",
                    }}
                  >
                    销售额
                  </p>
                </div>
              </div>
            </Col>
            <Col className="basicInfo-item" span={6}>
              <div
                className="d-flex"
                style={{
                  alignItems: "center",
                  flex: "0 1",
                  justifyContent: "space-between",
                  padding: "20px",
                  border: "1px solid #eee",
                  borderRadius: "10px",
                }}
              >
                <div>
                  <ShoppingCartOutlined
                    style={{ fontSize: 40, color: "rgb(15,178,142)" }}
                  />
                </div>
                <div style={{ textAlign: "right" }}>
                  <div style={{ fontSize: "28px", fontWeight: "bolder" }}>
                    {overviewInfo.orderNum || "-"}
                  </div>
                  <p
                    style={{
                      color: "#9d9d9d",
                      fontSize: "16px",
                    }}
                  >
                    订单数
                  </p>
                </div>
              </div>
            </Col>
            <Col className="basicInfo-item" span={6}>
              <div
                className="d-flex"
                style={{
                  alignItems: "center",
                  flex: "0 1",
                  justifyContent: "space-between",
                  padding: "20px",
                  border: "1px solid #eee",
                  borderRadius: "10px",
                }}
              >
                <div>
                  <TeamOutlined
                    style={{ fontSize: 40, color: "rgb(248,95,107)" }}
                  />
                </div>
                <div style={{ textAlign: "right" }}>
                  <div style={{ fontSize: "28px", fontWeight: "bolder" }}>
                    {overviewInfo.productNumber || "-"}
                  </div>
                  <p
                    style={{
                      color: "#9d9d9d",
                      fontSize: "16px",
                    }}
                  >
                    产品销售数量
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="charts">
          <Row gutter={30}>
            <Col span={12}>
              <Card style={{ margin: "30px 0" }}>
                <Descriptions
                  title={
                    <Row gutter={30}>
                      <Col>
                        <DatePicker.RangePicker
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode
                          }
                          value={defaultDateTime1}
                          ranges={{
                            今天: [moment(), moment()],
                            当月: [
                              moment().startOf("month"),
                              moment().endOf("month"),
                            ],
                            今年: [
                              moment().startOf("years"),
                              moment().endOf("years"),
                            ],
                          }}
                          onChange={(val) => {
                            console.log(val);
                            setDefaultDateTime1(val);
                          }}
                        />
                      </Col>
                      <Col>
                        <Button
                          type="primary"
                          className="mr-1"
                          onClick={() => {
                            searchDatas1();
                          }}
                        >
                          查询{" "}
                        </Button>
                      </Col>
                    </Row>
                  }
                />
                <Echarts
                  option={pieOption}
                  style={{ height: "500px", width: "100%" }}
                  onEvents={{
                    updateAxisPointer: (params, echart) => {
                      const xAxisInfo = params.axesInfo[0];
                      if (xAxisInfo) {
                        console.log(pieOption);
                        const dimension = xAxisInfo.value + 1;
                        echart.setOption({
                          series: {
                            id: "pie",
                            label: {
                              formatter: "{b}: {@[" + dimension + "]} ({d}%)",
                            },
                            encode: {
                              value: dimension,
                              tooltip: dimension,
                            },
                          },
                        });
                      }
                    },
                  }}
                />
              </Card>
            </Col>
            <Col span={12}>
              <Card style={{ margin: "30px 0" }}>
                <Descriptions
                  title={
                    <Row gutter={30}>
                      <Col>
                        <DatePicker.RangePicker
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode
                          }
                          value={defaultDateTime2}
                          ranges={{
                            今天: [moment(), moment()],
                            当月: [
                              moment().startOf("month"),
                              moment().endOf("month"),
                            ],
                            今年: [
                              moment().startOf("years"),
                              moment().endOf("years"),
                            ],
                          }}
                          onChange={(val) => {
                            setDefaultDateTime2(val);
                          }}
                        />
                      </Col>
                      <Col>
                        <Button
                          type="primary"
                          className="mr-1"
                          onClick={() => {
                            searchDatas2();
                          }}
                        >
                          查询
                        </Button>
                      </Col>
                    </Row>
                  }
                />
                <>
                  <Table
                    columns={columns}
                    dataSource={data}
                    rowKey={(record) => record.contacts1}
                    pagination={pagination}
                    loading={loading}
                    onChange={handleTableChange}
                  />
                </>
              </Card>
            </Col>
            <Col span={12}>
              <Card style={{ margin: "30px 0" }}>
                <Descriptions
                  title={
                    <Row gutter={30}>
                      <Col>
                        <DatePicker.RangePicker
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode
                          }
                          value={defaultDateTime3}
                          ranges={{
                            今天: [moment(), moment()],
                            当月: [
                              moment().startOf("month"),
                              moment().endOf("month"),
                            ],
                            今年: [
                              moment().startOf("years"),
                              moment().endOf("years"),
                            ],
                          }}
                          onChange={(val) => {
                            setDefaultDateTime3(val);
                          }}
                        />
                      </Col>
                      <Col>
                        <Button
                          type="primary"
                          className="mr-1"
                          onClick={() => {
                            searchDatas3();
                          }}
                        >
                          查询
                        </Button>
                      </Col>
                    </Row>
                  }
                />
                <Echarts
                  option={barOption3}
                  style={{ height: "300px", width: "100%" }}
                />
              </Card>
            </Col>
            <Col span={12}>
              <Card style={{ margin: "30px 0" }}>
                <Descriptions
                  title={
                    <Row gutter={30}>
                      <Col>
                        <DatePicker.RangePicker
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode
                          }
                          value={defaultDateTime4}
                          ranges={{
                            今天: [moment(), moment()],
                            当月: [
                              moment().startOf("month"),
                              moment().endOf("month"),
                            ],
                            今年: [
                              moment().startOf("years"),
                              moment().endOf("years"),
                            ],
                          }}
                          onChange={(val) => {
                            setDefaultDateTime4(val);
                          }}
                        />
                      </Col>
                      <Col>
                        <Button
                          type="primary"
                          className="mr-1"
                          onClick={() => {
                            searchDatas4();
                          }}
                        >
                          查询
                        </Button>
                        {(ruleMenu &&
                          ruleMenu.findIndex(
                            (e) => e.path == "/index/overview/export3"
                          ) >= 0 && (
                            <Button
                              type="ghost"
                              onClick={async () => {
                                let result = await api.categorySalePerExport({
                                  timeFrom: moment(defaultDateTime4[0]).format(
                                    "YYYY-MM-DD"
                                  ),
                                  timeTo: moment(defaultDateTime4[1]).format(
                                    "YYYY-MM-DD"
                                  ),
                                });

                                message.success("导出成功！");
                                let url = window.URL.createObjectURL(
                                  new Blob([result])
                                );
                                // 创建A标签
                                let link = document.createElement("a");
                                link.style.display = "none";
                                link.href = url;
                                // 设置的下载文件文件名
                                const fileName = `产品分类销量占比.xlsx`;
                                // 触发点击方法
                                link.setAttribute("download", fileName);
                                document.body.appendChild(link);
                                link.click();
                              }}
                            >
                              导出
                            </Button>
                          )) ||
                          ""}
                      </Col>
                    </Row>
                  }
                />
                <Echarts
                  option={barOption4}
                  style={{ height: "300px", width: "100%" }}
                />
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    )) ||
    ""
  );
};

export default Overview;
