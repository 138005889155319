import React from "react";
import { base } from "../../api/config.js";
import { Route, Redirect, Link } from "react-router-dom";
import ClockCircleOutlined from '@ant-design/icons/ClockCircleOutlined';
import {
  Divider,
  Table,
  Row,
  Col,
  Button,
  Modal,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Checkbox,
  message,
  Cascader,
  Upload,
  Switch,
} from "antd";
import {
  SearchOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import api from "../../api";
import utils from "../../assets/js/public.js";
import cookie from "react-cookies";
const { Option } = Select;
const { confirm } = Modal;

const getRandomuserParams = (params) => {
  console.log(params);
  return {
    pageSize: params.pageSize,
    pageNo: params.pageNo,
  };
};
const CheckboxGroup = Checkbox.Group;

function handleChange(value) {
  console.log(`selected ${value}`);
}
const EditRolesForm = ({ visible, onCreate, onCancel, chooseUserInfo }) => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = React.useState([]);
  const [fileListVideo, setFileListVideo] = React.useState([]);
  const [fileUploadLoading, setFileUploadLoading] = React.useState(false);
  const [fileListMaterial, setFileListMaterial] = React.useState([]);
  const [fileListManual, setFileListManual] = React.useState([]);

  const [fileListCC, setFileListCC] = React.useState([]);
  const [fileListCE, setFileListCE] = React.useState([]);
  const [fileListPC, setFileListPC] = React.useState([]);
  const [fileListPFA, setFileListPFA] = React.useState([]);
  const [fileListSIL, setFileListSIL] = React.useState([]);
  const [fileListCQS, setFileListCQS] = React.useState([]);

  const [classifyArr, setClassifyArr] = React.useState([]);
  const [classifyConfigListArr, setClassifyConfigListArr] = React.useState([]);
  const [configTemplateArr, setConfigTemplateArr] = React.useState([]);
  const [configValueArr, setConfigValueArr] = React.useState([]);
  const [certificationList, setCertificationList] = React.useState([]);
  const [classifyList, setClassifyList] = React.useState([]);

  const layout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 12,
    },
  };

  const imageProps = {
    ref: "upload",
    name: "file",
    listType: "picture",
    fileList: visible ? fileList : [],
    className: "upload-list-inline",
    accept: "image/*",
    customRequest: async (file) => {
      file = file.file;
      setFileUploadLoading(true);
      let formData = new FormData();
      formData.append("file", file);
      console.log(formData);

      const res = await api.fileUpload(formData);

      if (res.code == 200) {
        setFileUploadLoading(false);
        setFileList([
          ...[...(fileList || [])],
          {
            url: res.data,
          },
        ]);
      } else {
        message.error(res.message);
      }
    },
    onRemove: (file) => {
      console.log(file);
      setFileList([...fileList.filter((e) => e.url != file.url)]);
      console.log(fileList);
    },
  };

  const videoProps = {
    ref: "upload",
    name: "file",
    listType: "text",
    fileList: fileListVideo,
    className: "upload-list-inline",
    accept: "video/*",
    customRequest: async (file) => {
      console.log(file.file);
      let formData = new FormData();

      formData.append("file", file.file);
      console.log(formData, formData.get(file));
      const res = await api.fileUpload(formData);

      if (res.code == 200) {
        setFileUploadLoading(false);
        setFileListVideo([
          {
            url: res.data,
          },
        ]);
      } else {
        message.error(res.message);
      }
    },
    onRemove: (file) => {
      setFileListVideo([]);
    },
  };

  const materialProps = {
    ref: "upload",
    name: "file",
    listType: "text",
    fileList: fileListMaterial,
    className: "upload-list-inline",
    customRequest: async (file) => {
      file = file.file;

      let formData = new FormData();

      formData.append("file", file);

      const res = await api.fileUpload(formData);

      if (res.code == 200) {
        setFileUploadLoading(false);
        setFileListMaterial([
          {
            url: res.data,
          },
        ]);
      } else {
        message.error(res.message);
      }
    },
    onRemove: (file) => {
      setFileListMaterial([]);
    },
  };

  const manualProps = {
    ref: "upload",
    name: "file",
    listType: "text",
    fileList: fileListManual,
    className: "upload-list-inline",
    customRequest: async (file) => {
      file = file.file;
      let formData = new FormData();
      formData.append("file", file);
      const res = await api.fileUpload(formData);
      if (res.code == 200) {
        setFileUploadLoading(false);
        setFileListManual([
          {
            url: res.data,
          },
        ]);
      } else {
        message.error(res.message);
      }
    },
    onRemove: (file) => {
      setFileListManual([]);
    },
  };

  const CCProps = {
    ref: "upload",
    name: "file",
    listType: "text",
    fileList: fileListCC,
    className: "upload-list-inline",
    customRequest: async (file) => {
      file = file.file;

      let formData = new FormData();

      formData.append("file", file);

      const res = await api.fileUpload(formData);

      if (res.code == 200) {
        setFileUploadLoading(false);
        setFileListCC([
          {
            url: res.data,
          },
        ]);
      } else {
        message.error(res.message);
      }
    },
    onRemove: (file) => {
      setFileListCC([]);
    },
  };

  const CEProps = {
    ref: "upload",
    name: "file",
    listType: "text",
    fileList: fileListCE,
    className: "upload-list-inline",
    customRequest: async (file) => {
      file = file.file;

      let formData = new FormData();

      formData.append("file", file);

      const res = await api.fileUpload(formData);

      if (res.code == 200) {
        setFileUploadLoading(false);
        setFileListCE([
          {
            url: res.data,
          },
        ]);
      } else {
        message.error(res.message);
      }
    },
    onRemove: (file) => {
      setFileListCE([]);
    },
  };

  const PCProps = {
    ref: "upload",
    name: "file",
    listType: "text",
    fileList: fileListPC,
    className: "upload-list-inline",
    customRequest: async (file) => {
      file = file.file;

      let formData = new FormData();

      formData.append("file", file);

      const res = await api.fileUpload(formData);

      if (res.code == 200) {
        setFileUploadLoading(false);
        setFileListPC([
          {
            url: res.data,
          },
        ]);
      } else {
        message.error(res.message);
      }
    },
    onRemove: (file) => {
      setFileListPC([]);
    },
  };

  const PFAProps = {
    ref: "upload",
    name: "file",
    listType: "text",
    fileList: fileListPFA,
    className: "upload-list-inline",
    customRequest: async (file) => {
      file = file.file;

      let formData = new FormData();

      formData.append("file", file);

      const res = await api.fileUpload(formData);

      if (res.code == 200) {
        setFileUploadLoading(false);
        setFileListPFA([
          {
            url: res.data,
          },
        ]);
      } else {
        message.error(res.message);
      }
    },
    onRemove: (file) => {
      setFileListPFA([]);
    },
  };

  const SILProps = {
    ref: "upload",
    name: "file",
    listType: "text",
    fileList: fileListSIL,
    className: "upload-list-inline",
    customRequest: async (file) => {
      file = file.file;

      let formData = new FormData();

      formData.append("file", file);

      const res = await api.fileUpload(formData);

      if (res.code == 200) {
        setFileUploadLoading(false);
        setFileListSIL([
          {
            url: res.data,
          },
        ]);
      } else {
        message.error(res.message);
      }
    },
    onRemove: (file) => {
      setFileListSIL([]);
    },
  };

  const CQSProps = {
    ref: "upload",
    name: "file",
    listType: "text",
    fileList: fileListCQS,
    className: "upload-list-inline",
    customRequest: async (file) => {
      file = file.file;

      let formData = new FormData();

      formData.append("file", file);

      const res = await api.fileUpload(formData);

      if (res.code == 200) {
        setFileUploadLoading(false);
        setFileListCQS([
          {
            url: res.data,
          },
        ]);
      } else {
        message.error(res.message);
      }
    },
    onRemove: (file) => {
      setFileListCQS([]);
    },
  };

  // async function getConfigList(params) {
  //   return await api.categoryGetConfig(params).then((res) => {
  //     if (res.code === 200) {
  //       return res.data;
  //     }
  //   });
  // }

  React.useEffect(() => {
    form.resetFields();

    api.categoryGetAll().then((categoryRes) => {
      if (categoryRes?.code === 200) {
        let classify = [...categoryRes.data];
        classify = JSON.stringify(classify)
          .replace(/id/g, "value")
          .replace(/categoryName/g, "label");
        classify = JSON.parse(classify);
        console.log(classify);
        setClassifyArr(classify);

        chooseUserInfo?.id &&
          api.productGet({ id: chooseUserInfo.id }).then((res) => {
            res.data.pictureList =
              (res.data.pictureList &&
                res.data.pictureList.length &&
                res.data.pictureList.map((e) => ({ url: e.url || e }))) ||
              [];
            chooseUserInfo = res.data;

            if (!!chooseUserInfo) {
              console.log(chooseUserInfo);
              let classifyVal = [
                ...utils
                  .getParent(categoryRes.data, chooseUserInfo.categoryIdList[0])
                  .map((values) => values.id + ""),
              ];

              console.log(classifyVal);

              form.setFieldsValue({
                ...chooseUserInfo,
                categoryIdList: classifyVal,
              });
              setFileList(
                chooseUserInfo.pictureList
                  ? [...chooseUserInfo.pictureList]
                  : []
              );
              setFileListVideo(
                chooseUserInfo.video ? [chooseUserInfo.video] : []
              );
              setFileListMaterial(
                chooseUserInfo.productInformation
                  ? [chooseUserInfo.productInformation]
                  : []
              );
              setFileListManual(
                chooseUserInfo.productSpecification
                  ? [chooseUserInfo.productSpecification]
                  : []
              );
              setFileListCC(
                chooseUserInfo.calibrationCertification
                  ? [chooseUserInfo.calibrationCertification]
                  : []
              );

              setFileListCE(
                chooseUserInfo.cecertification
                  ? [chooseUserInfo.cecertification]
                  : []
              );
              setFileListPC(
                chooseUserInfo.patentCertification
                  ? [chooseUserInfo.patentCertification]
                  : []
              );
              setFileListPFA(
                chooseUserInfo.pfacertification
                  ? [chooseUserInfo.pfacertification]
                  : []
              );
              setFileListSIL(
                chooseUserInfo.silcertification
                  ? [chooseUserInfo.silcertification]
                  : []
              );
              setFileListCQS(
                chooseUserInfo.cqstcertification
                  ? [chooseUserInfo.cqstcertification]
                  : []
              );
            }
          });
      }
    });
 
    api.configTemplateGetList({ pageNo: 1, pageSize: 1000 }).then((res) => {
      if (res?.code == 200) {
        setConfigTemplateArr(res?.data?.records);
      }
    });

    api.certificationGetAll().then((res) => {
      if (res.code == 200) {
        setCertificationList(res.data);
      }
    });
    api.classifyGetAll().then((res) => {
      if (res.code == 200) {
        setClassifyList(res.data);
      }
    });
    // api.conditionQuery({ pageNo: 1, pageSize: 99999 }).then((res) => {
    //   setConfigValueArr(res.data.records);
    // });

    if (visible || !visible) {
      form.resetFields();
    }
  }, [chooseUserInfo, visible]);

  return (
    <Modal
      getContainer={false}
      visible={visible}
      title="设置产品信息"
      width={950}
      okText={"保存"}
      cancelText={"取消"}
      maskClosable={false}
      okButtonProps={{
        disabled: fileUploadLoading,
      }}
      onOk={() => {
        // form.submit();
        form.validateFields().then((res) => {
          form.setFieldsValue({
            pictureList: fileList.map((item) => item.url),
            video: fileListVideo[0]?.url,
            productInformation: fileListMaterial[0]?.url,
            productSpecification: fileListManual[0]?.url,
            calibrationCertification: fileListCC[0]?.url,
            cecertification: fileListCE[0]?.url,
            patentCertification: fileListPC[0]?.url,
            pfacertification: fileListPFA[0]?.url,
            silcertification: fileListSIL[0]?.url,
            cqstcertification: fileListCQS[0]?.url,
          });
          onCreate(form.getFieldsValue());
        });
        console.log(form.getFieldsValue());
      }}
      onCancel={onCancel}
      destroyOnClose
    >
      <Form
        form={form}
        {...layout}
        name="form_in_modal"
        // initialValues={chooseUserInfo}
      >
        <div>
          <Form.Item name="id">
            <Input type="hidden" value={chooseUserInfo.id} disabled />
          </Form.Item>
          <Form.Item name="title" label="产品名称" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="titleEn" label="产品英文名称">
            <Input />
          </Form.Item>
          <Form.Item
            name="subtitle"
            label="产品简述"
            rules={[{ required: true }]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item name="subTitleEn" label="产品英文简述">
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            name="productModel"
            label="产品型号"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="technicalParameters" label="技术参数">
            <Input.TextArea />
          </Form.Item>
          <Form.Item name="technicalParametersEn" label="英文技术参数">
            <Input.TextArea />
          </Form.Item>

          <Form.Item
            name="categoryIdList"
            label="产品类目"
            rules={[{ required: true }]}
          >
            <Cascader
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              maxTagCount="responsive"
              options={
                (classifyArr && classifyArr.length && classifyArr) || false
              }
              changeOnSelect={true}
              onChange={(val) => {
                console.log(val);
                form.setFieldsValue({
                  categoryIdList: val,
                });

                // getConfigList({
                //   id: val && val.map((e) => e[e.length - 1]).join(","),
                // }).then((res) => {
                //   if (res && res.length) {
                //     setClassifyConfigListArr(
                //       res?.map((e) => ({
                //         value: e.id,
                //         label: e.title,
                //       })) || []
                //     );
                //   }
                // });
              }}
            />
          </Form.Item>
          {/* <Form.Item name="configTemplateId" label="类目配置">
            <Select
              mode="multiple"
              options={
                (classifyConfigListArr &&
                  classifyConfigListArr.length &&
                  classifyConfigListArr) ||
                false
              }
            />
          </Form.Item> */}
          {/* <Form.Item name="conditionValueId" label="条件值选择">
            <Select
              mode="multiple"
              options={
                (configValueArr &&
                  configValueArr.length &&
                  configValueArr.map((e) => ({
                    value: e.id,
                    label: e.conditionName,
                  }))) ||
                false
              }
            />
          </Form.Item> */}
          {/* <Form.Item name="certificationIdList" label="认证信息">
            <Select
              mode="multiple"
              showSearch
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={
                (certificationList &&
                  certificationList.length &&
                  certificationList.map((e) => ({
                    value: Number(e.id),
                    label: e.certificationName,
                  }))) ||
                false
              }
            />
          </Form.Item> */}
          <Form.Item name="classifyId" label="产品分类">
            <Select
              allowClear
              showSearch
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={
                (classifyList &&
                  classifyList.length &&
                  classifyList.map((e) => ({
                    value: e.id,
                    label: e.classifyName,
                  }))) ||
                false
              }
            />
          </Form.Item>
          <Form.Item name="configTemplateId" label="配置模板">
            <Select
            
              allowClear
              showSearch
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              
              options={
                (configTemplateArr &&
                  configTemplateArr.length &&
                  configTemplateArr.map((e) => ({
                    value: e.id,
                    label: e.title,
                  }))) ||
                false
              }
            />
          </Form.Item>
          <Form.Item name="price" label="产品价格" rules={[{ required: true }]}>
            <InputNumber />
          </Form.Item>
          <Form.Item name="productDeliveryTime" label="交付时间（天数）" rules={[{ required: false }]} >
            {/* <InputNumber /> */}
            <InputNumber status="warning" style={{ width: '100%' }} prefix={<ClockCircleOutlined />} placeholder="请输入交付时间（天数）"/>
          </Form.Item>
          <Form.Item
            name="isShelves"
            label="是否上架"
            rules={[{ required: true }]}
          >
            <Radio.Group>
              <Radio value={true}>上架</Radio>
              <Radio value={false}>下架</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="productDesc"
            label="产品描述"
            rules={[{ required: true }]}
          >
            <Input.TextArea rows={5} />
          </Form.Item>
          <Form.Item name="productDescEn" label="产品英文描述">
            <Input.TextArea rows={5} />
          </Form.Item>
          <Form.Item name="pictureList" label="图片">
            <Upload {...imageProps} listType="picture-card">
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>上传图片</div>
              </div>
            </Upload>
          </Form.Item>
          <Form.Item name="video" label="视频">
            <Upload {...videoProps} listType="picture-card">
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>上传视频</div>
              </div>
            </Upload>
          </Form.Item>
          <Form.Item name="productInformation" label="产品资料">
            <Upload {...materialProps} listType="picture-card">
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>上传资料</div>
              </div>
            </Upload>
          </Form.Item>
          <Form.Item name="productSpecification" label="产品说明书">
            <Upload {...manualProps} listType="picture-card">
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>上传说明书</div>
              </div>
            </Upload>
          </Form.Item>
          <Form.Item name="calibrationCertification" label="校准认证">
            <Upload {...CCProps} listType="picture-card">
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>上传校准认证</div>
              </div>
            </Upload>
          </Form.Item>
          <Form.Item name="cecertification" label="CE认证">
            <Upload {...CEProps} listType="picture-card">
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>上传CE认证</div>
              </div>
            </Upload>
          </Form.Item>
          <Form.Item name="patentCertification" label="专利认证">
            <Upload {...PCProps} listType="picture-card">
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>上传专利认证</div>
              </div>
            </Upload>
          </Form.Item>
          <Form.Item name="pfacertification" label="PFA认证">
            <Upload {...PFAProps} listType="picture-card">
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>上传PFA认证</div>
              </div>
            </Upload>
          </Form.Item>
          <Form.Item name="silcertification" label="SIL认证">
            <Upload {...SILProps} listType="picture-card">
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>上传SIL认证</div>
              </div>
            </Upload>
          </Form.Item>
          <Form.Item name="cqstcertification" label="防爆认证">
            <Upload {...CQSProps} listType="picture-card">
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>上传防爆认证</div>
              </div>
            </Upload>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

const EditValueForm = ({ visible, onCreate, onCancel, chooseUserInfo }) => {
  const [form] = Form.useForm();
  const [configValueArr, setConfigValueArr] = React.useState([]);
  const [configList, setConfigList] = React.useState([]);

  const layout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 18,
    },
  };
  React.useEffect(() => {
    form.resetFields();

    chooseUserInfo?.id &&
      api.productGetAllConditions({ id: chooseUserInfo.id }).then((res) => {
        api.productGetConditions(chooseUserInfo).then(async (res2) => {
          let categoryCanUsed = [],
            categoryGetConditionsRes = await api.categoryGetConditions({
              id: chooseUserInfo?.parentCategory.id,
            });

          if (categoryGetConditionsRes.code == 200) {
            categoryGetConditionsRes = categoryGetConditionsRes.data;
          } else {
            categoryGetConditionsRes = [];
          }

          let _configList = [...categoryGetConditionsRes],
            resualtList = [];

          console.log(configList);
          console.log(_configList);

          if (res2.data && res2.data.length) {
            res2.data.map((item) => {
              if (_configList && _configList.length) {
                let conditionIndex = _configList.findIndex(
                    (e) => e.id == item.conditionId
                  ),
                  conditionValueIndex = _configList[
                    conditionIndex
                  ]?.valueList.findIndex((e) => e.id == item.conditionValueId);
                if (conditionIndex >= 0 && conditionValueIndex >= 0) {
                  _configList.map((e) => {
                    if (
                      _configList[conditionIndex]?.valueList[
                        conditionValueIndex
                      ]?.valueType == 2
                    ) {
                      _configList[conditionIndex].valueList[
                        conditionValueIndex
                      ].value = item.conditionValue == "true" ? true : false;
                    } else {
                      _configList[conditionIndex].valueList[
                        conditionValueIndex
                      ].value = item.conditionValue;
                    }
                    _configList[conditionIndex].valueList[
                      conditionValueIndex
                    ].subId = item.id;
                  });
                  form.setFieldsValue({
                    [item.conditionId + `-` + item.conditionValueId]:
                      _configList[conditionIndex].valueList[conditionValueIndex]
                        .valueType == 2
                        ? item.conditionValue == "true"
                          ? true
                          : false
                        : item.conditionValue,
                  });
                  setConfigList(_configList);
                }
              }
            });
          }
          setConfigList(categoryGetConditionsRes);

          // setConfigValueArr(classify);
        });
      });

    if (visible || !visible) {
      form.resetFields();
    }
    form.setFieldsValue({
      id: chooseUserInfo.id,
    });
    console.log(chooseUserInfo);
  }, [chooseUserInfo, visible]);
  console.log(configList);
  return (
    <Modal
      getContainer={false}
      visible={visible}
      title="设置条件值信息"
      width={950}
      okText={"保存"}
      cancelText={"取消"}
      onOk={() => {
        onCreate(form.getFieldsValue());
        console.log(form.getFieldsValue());
      }}
      onCancel={onCancel}
      destroyOnClose
    >
      <Form
        form={form}
        {...layout}
        name="form_in_modal"
        // initialValues={chooseUserInfo}
      >
        <div>
          <Form.Item name="id" style={{ display: "none" }}>
            <Input type="hidden" value={chooseUserInfo.id} disabled />
          </Form.Item>
          {(configList &&
            configList.length &&
            configList.map((e) => {
              return (
                <div className="config-list">
                  <div
                    style={{ marginBottom: "15px" }}
                    className="config-title"
                  >
                    {e.conditionName}
                  </div>
                  <Row gutter={30} className="config-children-list">
                    {(e.valueList &&
                      e.valueList.length &&
                      e.valueList.map((item, ind) => {
                        return (
                          <Col span={12}>
                            <Form.Item
                              label={item.valueContent}
                              name={`${e.id}-${item.id}`}
                              valuePropName={
                                item.valueType == 2 ? `checked` : `value`
                              }
                            >
                              {(item.valueType === 1 && <Input />) || (
                                <Switch defaultChecked={item.value} />
                              )}
                            </Form.Item>
                          </Col>
                        );
                      })) ||
                      ""}
                  </Row>
                </div>
              );
            })) ||
            "暂无可用配置"}
        </div>
      </Form>
    </Modal>
  );
};

class ProductList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        // {
        //   img: "https://main.qcloudimg.com/raw/b2b7a7533e8f1dc7cfc9feb7c324d2f4/icon-practice.svg",
        //   model: "MF-4502",
        //   title: "小型浮球液位开关（304）",
        //   classify: "物联硬件",
        //   status: 0,
        //   createTime: 1632121200,
        //   updateTime: 1632121200,
        // },
      ],
      pagination: {
        pageNo: 1,
        pageSize: 10,
      },
      loading: false,
      showDetail: false,
      showEdit: false,
      showValue: false,
      chooseUserInfo: {},
      categoryAll: [],
      classifyAll: [],
      classifyArr: [],
      classifyList: [],
    };
  }

  componentWillUnmount() {
    this.setState = () => false;
  }
  componentDidMount() {
    const { pagination } = this.state;
    this.getAll();
    this.getClassifyAll();

    this.fetch(pagination);
  }
  modalChange(type, info) {
    const _t = this;
    info = { ...info };
    if (info && info.pictureList) {
      info.pictureList = info.pictureList.map((item) => ({
        url: item.url || item,
      }));
    }
    // console.log(info);
    this.setState({ chooseUserInfo: info ? info : {} }, () => {
      _t.setState({
        [type]: !_t.state[type],
      });
    });
  }
  UserChangeStatus() {
    console.log(1);
  }
  handleTableChange = (pagination, filters, sorter) => {
    let newPage = { ...this.state.pagination };
    newPage.pageNo = pagination.current;
    console.log(newPage);
    this.fetch(newPage);
  };

  getAll = async () => {
    api.categoryGetAll().then((res) => {
      let classify = [...res.data];
      classify = JSON.stringify(classify)
        .replace(/id/g, "value")
        .replace(/categoryName/g, "label");
      classify = JSON.parse(classify);
      this.setState({
        categoryAll: res?.data || [],
        classifyArr: classify,
      });
    });
  };
  getClassifyAll = async () => {
    api.classifyGetAll().then((res) => {
      this.setState({
        classifyAll: res?.data || [],
        classifyList: res?.data || [],
      });
    });
  };
  search() {
    let params = { ...this.formRef.current.getFieldsValue() };

    if (params.categoryId) {
      params.categoryId = params.categoryId[params.categoryId.length - 1];
    }
    console.log(params);
    let { pagination } = this.state;
    pagination.pageNo = 1;
    let newPage = {
      ...pagination,
      ...params,
    };
    console.log(this);
    this.setState(
      {
        pagination: newPage,
      },
      () => {
        console.log(this.state.pagination);
        this.fetch(this.state.pagination);
      }
    );
  }
  fetch = async (params = {}) => {
    this.setState({ loading: true });
    delete params.total;
    let result = await api.productGetList(params);
    if (!result || result.code !== 200) {
      return false;
    }
    this.setState({
      loading: false,
      data: (result && result.data.records) || [],
      pagination: {
        ...this.state.pagination,
        pageNo: (result && result.data.pageNo) || 1,
        current: (result && result.data.pageNo) || 1,
        pageSize: (result && result.data.pageSize) || 10,
        total: (result.data && result.data.totalRecords) || 1,
      },
    });
  };
  productExport = async (params = {}) => {
    delete params.total;
    params.pageSize = 99999999;
    let result = await api.productExport(params);
    if (!result) {
      return false;
    }
    console.log(result.headers);

    // const blob = new Blob([result]);
    // const objectURL = URL.createObjectURL(blob);
    // let btn = document.createElement("a");
    // btn.download = `产品列表.xlsx`;
    // btn.href = objectURL;
    // btn.click();
    // URL.revokeObjectURL(objectURL);
    // btn = null;
    // 创建下载的链接
    message.success("导出成功！");
    let url = window.URL.createObjectURL(
      new Blob([result], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );
    // 创建A标签
    let link = document.createElement("a");
    link.style.display = "none";
    link.href = url;
    // 设置的下载文件文件名
    const fileName = `产品列表.xlsx`;
    // 触发点击方法
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
  };
  SearchOutlined(e) {
    // if (e.which !== 13) return
   this.setState({loading:true})
   api.productGetList({
      pageNo :1,
      pageSize: 10,
      total:  1,
      titleLike :e.target.value,
    }).then(data =>{
      this.setState({
        loading: false,
        data: (data.data && data.data.records) || [],
        pagination: {
          pageNo: (data.data && data.data.pageNo) || 1,
          pageSize: (data.data && data.data.pageSize) || 10,
          total: (data.data && data.data.totalRecords) || 1,
        },
      });
    })
  }
  copy = (info) => {
    let _t = this;
    confirm({
      title: "您确定要执行复制操作吗？",
      okText: "是",
      cancelText: "否",
      async onOk() {
        let result = await api.productCopyProduct(info);
        if (result?.code == 200) {
          message.success("复制成功！", () => {
            const { pagination } = _t.state;
            _t.fetch(pagination);
          });
        }
      },
    });
  };
  showDeleteConfirm = (info) => {
    let _t = this;
    confirm({
      title: "您确定要执行删除操作吗?",
      icon: <ExclamationCircleOutlined />,
      content: "此操作将永久删除产品，是否继续？",
      okText: "是",
      okType: "danger",
      cancelText: "否",
      async onOk() {
        let result = await api.productDelete(info);
        if (!result || result.code !== 200) {
          return false;
        }
        message.success("删除成功！", () => {
          const { pagination } = _t.state;
          _t.fetch(pagination);
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  formRef = React.createRef();
  render() {
    const {
      data,
      pagination,
      loading,
      classifyAll,
      classifyArr,
      classifyList,
    } = this.state;
    const ruleMenu =
      (
        localStorage.getItem("ruleMenu") &&
        JSON.parse(localStorage.getItem("ruleMenu"))
      ).find((e) => e.path == "/index/product")?.children || [];

    const columns = [
      {
        title: "产品图片",
        dataIndex: "picture",
        width: 100,
        fixed: "left",
        align: "center",
        render: (index, data) =>
          (data.pictureList && (
            <div className="p-1">
              <img
                src={data.pictureList[0]}
                alt=""
                style={{ width: "60px", height: "60px" }}
              />
            </div>
          )) ||
          "-",
      },
      {
        title: "产品名称",
        dataIndex: "title",
        width: 150,
        align: "center",
      },
      {
        title: "产品简述",
        dataIndex: "subtitle",
        width: 150,
        align: "center",
      },
      {
        title: "产品型号",
        dataIndex: "productModel",
        width: 150,
        align: "center",
      },
      {
        title: "价格",
        dataIndex: "price",
        width: 150,
        align: "center",
      },
      {
        title: "测量任务",
        dataIndex: "categoryName",
        width: 200,
        align: "center",
        render: (text, data) => {
          function flatten(arr) {
            var res = [];
            if (arr.parentCategory) {
              res = res.concat(flatten(arr.parentCategory));
            }
            res.push(arr);
            return res;
          }
          return (
            (data?.parentCategory &&
              flatten(data?.parentCategory).map(
                (e, i) => (i == 0 && e.categoryName) || "/" + e.categoryName
              )) ||
            "-"
          );
        },
      },
      {
        title: "产品分类",
        dataIndex: "classifyId",
        width: 200,
        align: "center",
        render: (text, data) => {
          return (
            (data?.classifyId &&
              classifyAll &&
              classifyAll.length &&
              classifyAll.find((e) => e.id == data.classifyId)?.classifyName) ||
            "-"
          );
        },
      },
      {
        title: "上架",
        dataIndex: "isShelves",
        width: 100,
        align: "center",
        render: (index, data) => (
          <div>
            {(ruleMenu &&
              ruleMenu.findIndex(
                (e) => e.path == "/index/product/list/status"
              ) >= 0 && (
                <div>
                  {data.isShelves === false ? (
                    <Button
                      type="primary"
                      size="small"
                      onClick={() => {
                        onChangeStatus({
                          id: data.id,
                          isShelves: true,
                        });
                      }}
                    >
                      上架
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      size="small"
                      danger
                      onClick={() => {
                        onChangeStatus({
                          id: data.id,
                          isShelves: false,
                        });
                      }}
                    >
                      下架
                    </Button>
                  )}
                </div>
              )) ||
              ""}
          </div>
        ),
      },
      {
        title: "操作",
        width: 300,
        fixed: "right",
        align: "center",
        render: (index, data) => {
          return (
            <div>
              {(ruleMenu &&
                ruleMenu.findIndex(
                  (e) => e.path == "/index/product/list/del"
                ) >= 0 && (
                  <Button
                    type="primary"
                    danger
                    size="small"
                    className="mr-1"
                    onClick={() => this.showDeleteConfirm(data)}
                  >
                    删除
                  </Button>
                )) ||
                ""}
              {(ruleMenu &&
                ruleMenu.findIndex(
                  (e) => e.path == "/index/product/list/edit"
                ) >= 0 && (
                  <Button
                    type="primary"
                    size="small"
                    className="mr-1 warn"
                    onClick={() => this.modalChange("showEdit", data)}
                  >
                    编辑
                  </Button>
                )) ||
                ""}
              {(ruleMenu &&
                ruleMenu.findIndex(
                  (e) => e.path == "/index/product/list/test"
                ) >= 0 && (
                  <Button
                    type="primary"
                    size="small"
                    className="mr-1"
                    onClick={() => this.modalChange("showValue", data)}
                  >
                    测量原理
                  </Button>
                )) ||
                ""}
              {(ruleMenu &&
                ruleMenu.findIndex(
                  (e) => e.path == "/index/product/list/copy"
                ) >= 0 && (
                  <Button
                    type="primary"
                    size="small"
                    className="mr-1"
                    onClick={() => this.copy(data)}
                  >
                    复制
                  </Button>
                )) ||
                ""}
              {/* <Button
                type="primary"
                size="small"
                className="mr-1 info"
                onClick={() => this.modalChange("showEdit", data)}
              >
                筛选条件
              </Button> */}
              {/* <Button
                type="primary"
                size="small"
                onClick={() => this.modalChange("showEdit", data)}
              >
                产品配置
              </Button> */}
            </div>
          );
        },
      },
    ];

    const onEditUser = async (values) => {
      values.categoryIdList = [
        values.categoryIdList[values.categoryIdList.length - 1],
      ];
      let result = await api.productAddOrUpdate(values);
      if (!result || result.code !== 200) {
        message.error(result.message);
        return false;
      }
      const { pagination } = this.state;
      this.fetch(pagination);
      this.setState({ showEdit: false });
    };

    const onEditValue = async (values) => {
      let params = { ...values },
        eachParams = { ...params };
      delete eachParams.id;
      params.conditionList = [];
      Object.keys(eachParams).forEach((k) => {
        let ids = k.split(`-`);

        params.conditionList.push({
          conditionId: ids[0],
          conditionValueId: ids[1],
          conditionValue: eachParams[k],
        });
      });

      console.log(params);
      // params.conditionList = params.conditionValueId.map((e) => ({
      //   conditionId: e[0],
      //   // conditionValue: "",
      //   conditionValueId: e[1],
      //   // id: ,
      // }));
      let result = await api.productUpdateConditions(params);
      if (!result || result.code !== 200) {
        message.error(result.message);
        return false;
      }
      const { pagination } = this.state;
      this.fetch(pagination);
      this.setState({ showValue: false });
    };

    const onChangeStatus = async (values) => {
      let result = await api.productUpdateShelves(values);
      if (!result || result.code !== 200) {
        message.error(result.message);
        return false;
      }
      const { pagination } = this.state;
      this.fetch(pagination);
      this.setState({ showEdit: false });
    };

    return (
      <div>
        <Form
          className="search-box d-flex mb-3"
          name="product-form"
          layout="inline"
          ref={this.formRef}
        >
          <Form.Item name="titleLike" label="搜索标题">
            <Input onPressEnter={this.SearchOutlined.bind(this)} />
          </Form.Item>
          <Form.Item name="categoryId" label="测量任务">
            <Cascader
              style={{ minWidth: "160px" }}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              maxTagCount="responsive"
              allowClear
              options={
                (this.state.classifyArr &&
                  this.state.classifyArr.length &&
                  this.state.classifyArr) ||
                false
              }
              changeOnSelect={true}
            />
          </Form.Item>
          <Form.Item name="classifyId" label="产品分类">
            <Select
              style={{ minWidth: "160px" }}
              allowClear
              showSearch
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={
                (this.state.classifyList &&
                  this.state.classifyList.length &&
                  this.state.classifyList.map((e) => ({
                    value: e.id,
                    label: e.classifyName,
                  }))) ||
                false
              }
            />
          </Form.Item>
          <Form.Item className="ml-auto d-flex">
            <Button
              type="primary"
              className="mr-1"
              onClick={() => {
                this.search();
              }}
            >
              查询
            </Button>
            {(ruleMenu &&
              ruleMenu.findIndex(
                (e) => e.path == "/index/product/list/export"
              ) >= 0 && (
                <Button
                  type="primary"
                  className="warn mr-1"
                  onClick={() => {
                    let params = this.formRef.current.getFieldsValue(true);
                    this.productExport({ ...params });
                  }}
                >
                  导出
                </Button>
              )) ||
              ""}
            {(ruleMenu &&
              ruleMenu.findIndex((e) => e.path == "/index/product/list/add") >=
                0 && (
                <Button
                  type="primary"
                  className="mr-1"
                  onClick={() => {
                    this.setState({ chooseUserInfo: {} });
                    this.modalChange("showEdit", {});
                  }}
                >
                  新增产品
                </Button>
              )) ||
              ""}
          </Form.Item>
        </Form>
        <Table
          columns={columns}
          dataSource={data}
          pagination={{ ...pagination, showSizeChanger: false }}
          loading={loading}
          onChange={this.handleTableChange}
          size="small"
          sticky
          scroll={{ x: 1500 }}
        />
        {/* 修改用户信息 */}
        {this.state.showEdit && (
          <EditRolesForm
            visible={this.state.showEdit}
            onCreate={onEditUser}
            onCancel={() => {
              this.modalChange("showEdit", false);
            }}
            chooseUserInfo={this.state.chooseUserInfo}
          />
        )}
        {this.state.showValue && (
          <EditValueForm
            visible={this.state.showValue}
            onCreate={onEditValue}
            onCancel={() => {
              this.modalChange("showValue", false);
            }}
            chooseUserInfo={this.state.chooseUserInfo}
          />
        )}
      </div>
    );
  }
}

export default ProductList;
