import instance from './instance.js'
import { base, mock } from './config.js'
import cookie from 'react-cookies'
import qs from 'qs'
export default {
  // 保存模版
  async saveTemp(params) {
    return await instance.put(`${base}/m/configTemplate/addOrUpdate`, params).then((res) => {
      return res.data;
    }).catch((error) => {
      // message.error('服务器出错');
    });
  },
  //创建/更新配置模板
  async configTemplateAddOrUpdate(params) {
    return await instance
      .put(`${base}/m/configTemplate/addOrUpdate`, params)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        //message.error('服务器出错')
      })
  },
  //删除配置模板
  async configTemplateDel(params) {
    return await instance
      .delete(`${base}/m/configTemplate/delete/${params.id}`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        //message.error('服务器出错')
      })
  },
  //获取配置模板信息
  async configTemplateGetInfo(params) {
    return await instance
      .get(`${base}/m/configTemplate/get/${params.id}`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        //message.error('服务器出错')
      })
  },
  //复制配置模板信息
  async configTemplateCopyTemplate(params) {
    return await instance
      .get(`${base}/m/configTemplate/copyTemplate/${params.id}`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        //message.error('服务器出错')
      })
  },
  
  //分页获取配置模板列表信息
  async configTemplateGetList(params) {
    return await instance
      .post(`${base}/m/configTemplate/queryList`, params)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        //message.error('服务器出错')
      })
  },
  
  // 文件上传
  async uploadFile(params) {
    return await instance
      .post(`${base}/m/file/upload`, params, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        //message.error('服务器出错')
      })
  },
    //根据title
    async configTemplateGetInfoTitle(params) {
      return await instance
        .get(`${base}/m/configTemplate/get/${params.title}`)
        .then((res) => {
          return res.data
        })
        .catch((error) => {
          //message.error('服务器出错')
        })
    },
        //获取客户信息
        async queryAllClientSales(params) {
          return await instance
            .post(`${base}/m/statistic/queryAllClientSales`, params)
            .then((res) => {
              return res.data;
            })
            .catch((error) => {
              //message.error('服务器出错')
            });
        },
}
