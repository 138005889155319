import React from "react";

import { Route, Redirect, Link } from "react-router-dom";
import {
  Spin,
  Table,
  Row,
  Col,
  Typography,
  Button,
  Modal,
  Form,
  Input,
  Select,
  Switch,
  Popconfirm,
  message,
} from "antd";
import { AppstoreOutlined } from "@ant-design/icons";
import api from "../../api";
import utils from "../../assets/js/public.js";
const { Option } = Select;

function handleChange(value) {
  // console.log(`selected ${value}`)
}
const EditForm = ({ visible, onCreate, onCancel, chooseUserInfo }) => {
  const [form] = Form.useForm();
  const layout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 16,
    },
  };
  if (chooseUserInfo) {
    form.setFieldsValue({ ...chooseUserInfo });
  } else {
    form.setFieldsValue({
      menuName: "",
      url: "",
      parentId: "",
      sortNumber: "",
      menuType: 1,
      isEnable: true,
    });
  }
  return (
    <Modal
      visible={visible}
      title="设置菜单信息"
      okText="提交"
      cancelText="取消"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            // console.log('Validate Failed:', info)
          });
      }}
    >
      <Form
        form={form}
        {...layout}
        name="form_in_modal"
        initialValues={
          { ...chooseUserInfo } || {
            menuType: 1,
            isEnable: chooseUserInfo.isEnable,
          }
        }
      >
        {(chooseUserInfo.id && (
          <Form.Item name="id" label="菜单Id">
            <Input disabled />
          </Form.Item>
        )) ||
          ""}
        <Form.Item
          name="menuName"
          label="菜单名称"
          // rules={[
          //   {
          //     required: true,
          //     message: '请选择用户类型'
          //   }
          // ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="menuType"
          label="菜单类型"
          // rules={[
          //   {
          //     required: true,
          //     message: '请选择用户类型'
          //   }
          // ]}
        >
          <Select
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            initialvalues={1}
            style={{ width: 120 }}
            onChange={handleChange}
          >
            <Option value={1}>菜单</Option>
            <Option value={2}>接口</Option>
            <Option value={3}>按钮</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="url"
          label="菜单地址"
          // rules={[
          //   {
          //     required: true,
          //     message: '请选择用户类型'
          //   }
          // ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="parentId"
          label="父级菜单ID"
          // rules={[
          //   {
          //     required: true,
          //     message: '请选择用户类型'
          //   }
          // ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="sortNumber"
          label="排序"
          // rules={[
          //   {
          //     required: true,
          //     message: '请选择用户类型'
          //   }
          // ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="isEnable"
          label="是否启用"
          // rules={[
          //   {
          //     required: true,
          //     message: '请选择用户类型'
          //   }
          // ]}
        >
          <Switch defaultChecked={chooseUserInfo.isEnable} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

class Menulist extends React.Component {
  state = {
    data: [],
    pagination: {
      pageNo: 1,
      current: 1,
      pageSize: 10,
    },
    loading: false,
    showEdit: false,
    chooseUserInfo: {},
  };
  componentWillUnmount() {
    this.setState = () => false;
  }
  componentDidMount() {
    const { pagination } = this.state;
    this.fetch({ pagination });
  }
  modalChange(type, info) {
    // console.log(info)
    if (info) {
      this.setState({ chooseUserInfo: info });
    } else {
      this.setState({ chooseUserInfo: {} });
    }
    this.setState({
      [type]: !this.state[type],
    });
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.fetch({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  };

  fetch = async (params = {}) => {
    this.setState({ loading: true });
    // console.log(params)
    params.pagination.pageNo = params.pagination.current;
    let data = await api.getMenuList(params.pagination);
    // console.log(data)
    let resData = [];
    data &&
      data.dataMap.records &&
      data.dataMap.records.map((e) => {
        resData.push({
          ...e,
          children: e.childCategoryList,
        });
      });
    this.setState({
      loading: false,
      data: resData,
      pagination: {
        pageNo: data.dataMap.pageNo,
        current: data.dataMap.pageNo,
        total: data.dataMap.totalRecords,
        pageSize: data.dataMap.pageSize,
      },
    });
  };
  //提交
  onEditSub = async (vals) => {
    // console.log(vals)
    let res;
    if (vals.id) {
      res = await api.updateMenuInfo(vals);
    } else {
      res = await api.createMenu(vals);
    }
    // console.log(res)
    if (res.code === 200) {
      this.modalChange("showEdit");
      let pagination = this.state.pagination;
      this.fetch({ pagination });
    }
  };
  //删除
  del = async (data) => {
    // console.log(data)
    let res = await api.delMenu(data);
    // console.log(res)
    if (res.code === 200) {
      let pagination = this.state.pagination;
      this.fetch({ pagination });
    } else {
      message.warn(res.message);
    }
  };
  //禁用
  disabled = async (data) => {
    let res = await api.delMenu(data);
    // console.log(res)
    if (res.code === 200) {
      let pagination = this.state.pagination;
      this.fetch({ pagination });
    } else {
      message.warn(res.message);
    }
  };

  render() {
    const { data, pagination, loading } = this.state;
    const columns = [
      {
        title: "菜单ID",
        dataIndex: "id",
        width: 90,
      },
      {
        title: "菜单名称",
        dataIndex: "menuName",
        width: 150,
        render: (index, data) => data.menuName,
      },
      {
        title: "菜单类型",
        dataIndex: "menuType",
        width: 150,
        render: (index, data) =>
          (data.menuType === 1 && "菜单") ||
          (data.menuType === 2 && "接口") ||
          (data.menuType === 3 && "按钮"),
      },
      {
        title: "菜单地址",
        dataIndex: "url",
        width: 150,
        render: (index, data) => data.url,
      },
      {
        title: "父级菜单",
        dataIndex: "parentId",
        width: 150,
        render: (index, data) => data.parentId,
      },
      // {
      //   title: '子菜单',
      //   dataIndex: 'childCategoryList',
      //   width: 150,
      //   render: (index, data) => {
      //     // data.childCategoryList
      //     return `1`
      //   }
      // },
      {
        title: "状态",
        // dataIndex: 'userStatus',
        width: 150,
        render: (index, data) => {
          return (data.isEnable && "启用") || "禁用";
        },
      },
      {
        title: "操作",
        width: 250,
        fixed: "right",
        render: (index, data) => {
          return (
            <div>
              <Button
                type="primary"
                size="small"
                className="mr-1"
                onClick={() => this.modalChange("showEdit", data)}
              >
                修改
              </Button>
              <Popconfirm
                title="是确定要删除?删除后无法恢复."
                onConfirm={this.del.bind(this, data)}
                onCancel={this.cancel}
                okText="确定"
                cancelText="取消"
              >
                <Button size="small" type="danger" className="mr-1">
                  删除
                </Button>
              </Popconfirm>
            </div>
          );
        },
      },
    ];
    const validateMessages = {
      required: "${label} 不能为空!",
    };

    return (
      <div>
        <div className="mb-2">
          <Button
            size="small"
            type="primary"
            className="mr-1"
            onClick={() => this.modalChange("showEdit")}
          >
            新增
          </Button>
        </div>
        <Table
          columns={columns}
          rowKey={(record) => record.id}
          dataSource={data}
          pagination={{...pagination, showSizeChanger: false}}
          loading={loading}
          onChange={this.handleTableChange}
          size="small"
          sticky
          scroll={{ x: 1500 }}
        />

        {/* 修改用户信息 */}
        {(this.state.showEdit && (
          <EditForm
            visible={this.state.showEdit}
            onCreate={this.onEditSub}
            onCancel={() => this.modalChange("showEdit")}
            chooseUserInfo={this.state.chooseUserInfo}
          />
        )) ||
          ""}
      </div>
    );
  }
}

export default Menulist;
