import React from 'react'

import { Route, Redirect, Link } from 'react-router-dom'
import {
  Spin,
  Table,
  Row,
  Col,
  Typography,
  Button,
  Modal,
  Form,
  Input,
  Select,
  Switch,
  Popconfirm,
  message,
  Checkbox
} from 'antd'
import { AppstoreOutlined } from '@ant-design/icons'
import api from '../../api'
import utils from '../../assets/js/public.js'
const { Option } = Select

function handleChange(value) {
  // console.log(`selected ${value}`)
}
const EditForm = ({ visible, onCreate, onCancel, chooseInfo, menuList }) => {
  const [ form ] = Form.useForm()
  const layout = {
    labelCol: {
      span: 6
    },
    wrapperCol: {
      span: 16
    }
  }

  //权限设置点击操作
  const changeMenuIds = (ev) => {
    let menuIds = [ ...form.getFieldValue('menuIds') ]
    if (!!ev.target.checked) {
      menuIds.push(ev.target.value)
    } else {
      menuIds = [ ...menuIds.filter((e) => e !== ev.target.value) ]
    }
    console.log(menuIds)
    form.setFieldsValue({ menuIds: menuIds })
  }

  console.log(chooseInfo)
  let menuIds = []
  !!Object.keys(chooseInfo).length && chooseInfo.menuList && chooseInfo.menuList.map((e) => menuIds.push(e.id))
  console.log(menuIds)
  form.setFieldsValue({
    ...chooseInfo,
    menuIds: []
  })
  let showMenu = []
  console.log(menuList)
  !!menuList &&
    !!menuList.length &&
    menuList.map((e) => {
      showMenu.push({ label: e.menuName, value: e.id, child: e.childCategoryList })
    })
  return (
    <Modal
      visible={visible}
      title="设置权限信息"
      okText="提交"
      cancelText="取消"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields()
            onCreate(values)
          })
          .catch((info) => {
            // console.log('Validate Failed:', info)
          })
      }}
      getContainer={false}
      width={800}
    >
      <Form
        form={form}
        {...layout}
        name="form_in_modal"
        initialValues={
          { ...chooseInfo } || {
            menuType: 1,
            isEnable: chooseInfo.isEnable
          }
        }
      >
        {(chooseInfo.id && (
          <Form.Item name="id" label="菜单Id">
            <Input disabled />
          </Form.Item>
        )) ||
          ''}
        <Form.Item name="roleName" label="权限名称">
          <Input />
        </Form.Item>
        <Form.Item name="menuIds" label="菜单列表ID">
          {showMenu &&
            showMenu.length &&
            showMenu.map((e) => {
              // console.log(e)
              return (
                <div>
                  <Checkbox
                    key={e.id}
                    // defaultChecked={(menuIds.indexOf(e.value) >= 0 && true) || false}
                    defaultChecked={''}
                    value={e.value}
                    onChange={(ev) => changeMenuIds(ev)}
                  >
                    {e.label}
                  </Checkbox>
                  <div>
                    {e.child &&
                      e.child.length &&
                      e.child.map((v) => {
                        return (
                          <Checkbox
                            key={v.id}
                            // defaultChecked={(menuIds.indexOf(v.id) >= 0 && true) || false}
                            defaultChecked={''}
                            value={v.id}
                            onChange={(ev) => changeMenuIds(ev)}
                          >
                            {v.menuName}
                          </Checkbox>
                        )
                      })}
                  </div>
                  <hr style={{ borderColor: '#fff' }} />
                </div>
              )
            })}
        </Form.Item>
      </Form>
    </Modal>
  )
}

class Rulelist extends React.Component {
  state = {
    data: [],
    menuList: [],
    pagination: {
      pageNo: 1,
      current: 1,
      pageSize: 10
    },
    loading: false,
    showEdit: false,
    chooseInfo: {}
  }
  componentWillUnmount() {
    this.setState = () => false
  }
  componentDidMount() {
    const { pagination } = this.state
    this.fetch({ pagination })
    this.getMenus()
  }
  modalChange(type, info) {
    // console.log(info)
    if (info) {
      this.setState({ chooseInfo: info })
    } else {
      this.setState({ chooseInfo: {} })
    }
    this.setState({
      [type]: !this.state[type]
    })
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.fetch({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters
    })
  }

  fetch = async (params = {}) => {
    this.setState({ loading: true })
    // console.log(params)
    params.pagination.pageNo = params.pagination.current
    let data = await api.getRuleList(params.pagination)
    // console.log(data)
    if (!data || data.code !== 200) {
      return false
    }
    this.setState({
      loading: false,
      data: (data && data.dataMap.records) || [],
      pagination: {
        pageNo: (data && data.dataMap.pageNo) || 1,
        current: (data && data.dataMap.pageNo) || 1,
        total: (data && data.dataMap.totalRecords) || 0,
        pageSize: (data && data.dataMap.pageSize) || 10
      }
    })
  }
  //获取菜单列表
  getMenus = async () => {
    let res = await api.getMenuAll()
    this.setState({ menuList: res.dataMap })
  }
  //提交
  onEditSub = async (vals) => {
    let res
    if (vals.id) {
      res = await api.updateRuleInfo(vals)
    } else {
      res = await api.createRule(vals)
    }
    // console.log(res)
    if (res.code === 200) {
      this.modalChange('showEdit')
      let pagination = this.state.pagination
      this.fetch({ pagination })
    }
  }
  //删除
  del = async (data) => {
    // console.log(data)
    let res = await api.delRule(data)
    // console.log(res)
    if (res.code === 200) {
      let pagination = this.state.pagination
      this.fetch({ pagination })
    } else {
      message.warn(res.message)
    }
  }
  //禁用
  disabled = async (data) => {
    let res = await api.delRule(data)
    // console.log(res)
    if (res.code === 200) {
      let pagination = this.state.pagination
      this.fetch({ pagination })
    } else {
      message.warn(res.message)
    }
  }

  render() {
    const { data, pagination, loading } = this.state
    const columns = [
      {
        title: '权限ID',
        dataIndex: 'id',
        sorter: true,
        width: 50
      },
      {
        title: '权限名称',
        dataIndex: 'roleName',
        sorter: true,
        width: 150,
        render: (index, data) => data.roleName
      },
      {
        title: '权限菜单Id',
        dataIndex: 'menuIds',
        sorter: true,
        width: 150,
        render: (index, data) => {
          return [
            ...data.menuList.map((e) => {
              return e.menuName
            })
          ].join(',')
        }
      },
      {
        title: '操作',
        width: 250,
        fixed: 'right',
        render: (index, data) => {
          return (
            <div>
              <Button type="primary" size="small" className="mr-1" onClick={() => this.modalChange('showEdit', data)}>
                修改
              </Button>

              <Popconfirm
                title="是确定要删除?删除后无法恢复."
                onConfirm={this.del.bind(this, data)}
                onCancel={this.cancel}
                okText="确定"
                cancelText="取消"
              >
                <Button size="small" type="danger" className="mr-1">
                  删除
                </Button>
              </Popconfirm>
            </div>
          )
        }
      }
    ]
    const validateMessages = {
      required: '${label} 不能为空!'
    }

    return (
      <div>
        <div className="mb-2">
          <Button size="small" type="primary" className="mr-1" onClick={() => this.modalChange('showEdit')}>
            新增
          </Button>
        </div>
        <Table
          columns={columns}
          rowKey={(record) => record.id}
          dataSource={data}
          pagination={{...pagination, showSizeChanger: false}}
          loading={loading}
          onChange={this.handleTableChange}
          size="small"
          sticky
          scroll={{ x: 1500 }}
        />

        {/* 修改信息 */}
        {(this.state.showEdit && (
          <EditForm
            visible={this.state.showEdit}
            onCreate={this.onEditSub}
            onCancel={() => this.modalChange('showEdit')}
            chooseInfo={this.state.chooseInfo}
            menuList={this.state.menuList}
          />
        )) ||
          ''}
      </div>
    )
  }
}

export default Rulelist
