import React, { useContext, useEffect, useRef, useState } from "react";

import {
  Table,
  Row,
  Col,
  Form,
  Input,
  Button,
  DatePicker,
  message,
  Checkbox,
  Divider,
  Modal,
  Select,
  Radio,
  Upload,
  Drawer,
  Space,
  Cascader,
  InputNumber,
} from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import api from "../../api";
import qs from "qs";
import _, { set } from "lodash";
import moment from "moment";
import utils from "../../assets/js/public.js";
import "./tabel.less";
import data from "../../assets/js/data.json";
import { PlusOutlined } from "@ant-design/icons";
import "./tabel.css";
import { render } from "less";
const { Option } = Select;
const { TextArea } = Input;
const ContactList = ({ visible, onCreate, onCancel, chooseUserInfo }) => {
  const [productId, setProductId] = useState(""); // 保存产品ID的状态
  const defaultCheckedList = ["Apple", "Orange"];
  const [quotationInfo, setquotationInfo] = useState({});
  const [config, setproductConfig] = useState({});
  const [selectconfig, setSelectProductConfig] = useState({});
  const [proDataSource, setProDataSource] = useState([]);
  // new 对象 === null
  const [craftDataSource, setCraftDataSource] = useState([]);
  const [childrenDrawer, setChildrenDrawer] = useState(false);
  const [totalPrice, setTotalPrice] = useState("0.00");
  const [dispatchDate, setDispatchDate] = useState(null);
  const restimatedDeliveryTime = quotationInfo.restimatedDeliveryTime
    ? moment(quotationInfo.restimatedDeliveryTime, "YYYY-MM-DD")
    : null;
  // const dispatchDate = quotationInfo.dispatchDate
  //   ? moment(quotationInfo.dispatchDate, "YYYY-MM-DD")
  //   : null;
  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  const onChange = (checkedValues) => {
    console.log("checked = ", checkedValues);
  };
  const [form] = Form.useForm();
  const [classifyArr, setClassifyArr] = React.useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tb_modifications, settb_modifications] = useState(false);
  const [hasEmpty, sethasEmpty] = useState(true);
  //产品线字段
  const [selectedOption, setSelectedOption] = useState("");
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const showChildrenDrawer = () => {
    selectUpdateProductName();
    setChildrenDrawer(true);
  };

  const getUpdateProductConfig = () => {
    const formValues = form.getFieldsValue();
    api.getUpdateProductConfig({
      id: productId.id,
      orderCode: formValues.orderCode,
      config: formValues,
    });
    onChildrenDrawerClose();
  };
  const handleOptionChange = (value, data) => {
    data.specimen = value;
    setSelectedOption(value);
    setProductId(data.id);
    api.orderProductLine({
      tbManager: data.tbManager,
      id: data.id,
      orderCode: data.orderCode,
      selectedSampleType: value,
    });
    let hasEmpty1 = false;
    // 遍历 proDataSource 数组
    proDataSource.forEach((product) => {
      if (product.specimen === "" || product.specimen === null) {
        hasEmpty1 = false;
      } else if (product.tbManager === null) {
        hasEmpty1 = true;
      }
    });
    checkAndSetSpecimenStatus(hasEmpty1);
  };
  function checkAndSetSpecimenStatus(e) {
    sethasEmpty(e);
  }

  // 判断给定label是否在tb_modifications中重复出现
  const isLabelRepeated = (lable, data) => {
    const labelCounts = {};
    // 遍历tb_modifications
    for (const modification of tb_modifications) {
      // 只处理当前产品的修改记录
      let modificationLabel;
      if (modification.productId === data.id) {
        modificationLabel = modification.lable;

        // 确保 modificationLabel 有定义
        if (modificationLabel) {
          // 累积计数
          if (labelCounts[modificationLabel]) {
            labelCounts[modificationLabel] += 1;
          } else {
            labelCounts[modificationLabel] = 1;
          }
        }
      }
    }
    return containsLabel(labelCounts, lable); // 如果 label 出现大于1次，则重复
  };
  function containsLabel(labelName, dataObject, lable) {
    // 遍历对象的每个属性
    for (let key in labelName) {
      // 如果找到匹配的标签名，则返回 true
      return key;
    }
    // 如果没有找到匹配的标签名，则返回 false
    return false;
  }

  const handleSubmit = (values) => {
    console.log(values); // 打印表单的值
    // 进行提交逻辑的处理
  };

  const onChildrenDrawerClose = () => {
    setChildrenDrawer(false);
  };

  useEffect(() => {
    setDispatchDate(
      quotationInfo.dispatchDate
        ? moment(quotationInfo.dispatchDate, "YYYY-MM-DD")
        : null
    );
  }, [quotationInfo.dispatchDate]);
  const proColumns = [
    {
      title: "项次",
      dataIndex: "",
      width: 100,
      align: "center",
      render: (text, data, index) => {
        return index + 1;
      },
    },
    {
      title: "名称",
      dataIndex: "",
      width: 120,
      render: (text, data, index) => {
        return (
          <div>
            <Form.Item label="" style={{ marginBottom: 0 }}>
              <Input.TextArea
                defaultValue={data.productTitle}
                onBlur={async (e) => {
                  await api.orderPutProductionLiaisonDocUpdateOrderProductInfo({
                    id: data.id,
                    orderCode: quotationInfo.orderCode,
                    orderId: quotationInfo.id,
                    productTitle: e.target.value,
                  });
                }}
              />
            </Form.Item>
          </div>
        );
      },
    },
    {
      title: "具体规格",
      dataIndex: "",
      width: 400,
      render: (text, data, index) => {
        return (
          <div>
            <Form.Item label="型号" style={{ marginBottom: 0 }}>
              <Input
                defaultValue={data.productModel}
                onBlur={async (e) => {
                  await api.orderPutProductionLiaisonDocUpdateOrderProductInfo({
                    id: data.id,
                    orderCode: quotationInfo.orderCode,
                    orderId: quotationInfo.id,
                    productModel: e.target.value,
                  });
                }}
              />
            </Form.Item>
            <br />
            <div>
              {data.productConfig && (
                <div>
                  {JSON.parse(data.productConfig).map((e, index) => {
                    const label = e.label;
                    const value = e.value;
                    const isRepeated = isLabelRepeated(label, data);
                    let istrue = false;
                    if (isRepeated === label) {
                      istrue = true;
                    }
                    // lable
                    return (
                      <div
                        key={index}
                        style={{
                          lineHeight: 2,
                          color: istrue ? "red" : "inherit",
                        }}
                      >
                        {label}: {value}
                      </div>
                    );
                  })}
                </div>
              )}
              {(!data.productConfig || !data.productConfig.length) && "-"}
            </div>

            {/* 在这个地方判断，如果data的id和tb_modifications这个集合内部任意一个的productId相同，然后取出lable名称进行比较，如果lable名称都一样，例如两个都为结构，给结构这个循环出的样式进行红色标注，代表重复标识 */}
            <Form.Item label="图纸编号：" style={{ marginBottom: 0 }}>
              <Input
                defaultValue={data.drawingNo}
                onBlur={async (e) => {
                  await api.orderPutProductionLiaisonDocUpdateOrderProductInfo({
                    id: data.id,
                    orderCode: quotationInfo.orderCode,
                    orderId: quotationInfo.id,
                    drawingNo: e.target.value,
                  });
                }}
              />
            </Form.Item>
            <Form.Item label="我司料号：" style={{ marginBottom: 0 }}>
              <Input
                class="ant-input-number-handler-wrap"
                // type="number"
                step="any"
                defaultValue={data.partNo}
                onBlur={async (e) => {
                  await api.orderPutProductionLiaisonDocUpdateOrderProductInfo({
                    id: data.id,
                    orderCode: quotationInfo.orderCode,
                    orderId: quotationInfo.id,
                    partNo: e.target.value,
                  });
                }}
              />
            </Form.Item>
            <Form.Item label="客户料号：" style={{ marginBottom: 0 }}>
              <Input
                defaultValue={data.customerPartNo}
                onBlur={async (e) => {
                  await api.orderPutProductionLiaisonDocUpdateOrderProductInfo({
                    id: data.id,
                    orderCode: quotationInfo.orderCode,
                    orderId: quotationInfo.id,
                    customerPartNo: e.target.value,
                  });
                }}
              />
            </Form.Item>
          </div>
        );
      },
    },
    {
      title: "数量",
      dataIndex: "",
      width: 100,
      align: "center",
      render: (text, data, index) => {
        return (data.productNumber && data.productNumber + "PCS") || "-";
      },
    },
    {
      title: "产品编号",
      dataIndex: "",
      width: 80,
      align: "center",
      render: (text, data, index) => {
        return (
          <Form.Item style={{ marginBottom: 0 }}>
            <div style={{ display: "flex" }}>
              {" "}
              {/* 使用flex布局 */}
              <Input.TextArea
                defaultValue={data.productNo}
                onBlur={async (e) => {
                  await api.orderPutProductionLiaisonDocUpdateOrderProductInfo({
                    id: data.id,
                    orderCode: quotationInfo.orderCode,
                    orderId: quotationInfo.id,
                    productNo: e.target.value,
                  });
                }}
                style={{ flex: 1 }} // 使用flex: 1来让TextArea填充剩余空间
              />
            </div>
          </Form.Item>
        );
      },
    },

    {
      title: "备注",
      dataIndex: "",
      width: 240,
      align: "center",
      render: (text, data, index) => {
        return (
          <Form.Item style={{ marginBottom: 0 }}>
            <Input.TextArea
              defaultValue={data.remark}
              onBlur={async (e) => {
                await api.orderPutProductionLiaisonDocUpdateOrderProductInfo({
                  id: data.id,
                  orderCode: quotationInfo.orderCode,
                  orderId: quotationInfo.id,
                  remark: e.target.value,
                });
              }}
            />
          </Form.Item>
        );
      },
    },
    {
      title: "产品关键工艺",
      width: 110,
      align: "left",
      dataIndex: "",
      render: (text, data, index) => {
        return (
          <Form.Item style={{ marginBottom: 0 }}>
            <label
              htmlFor="outsourcing"
              style={{
                width: "60px",
                display: "inline-block",
                lineHeight: "30px",
              }}
            >
              外购
            </label>
            <Checkbox
              type="checkbox"
              name="outsourcing"
              defaultValue={(data.checked = 1 ? 1 : 2)}
              onChange={onChange}
              onBlur={async (e) => {
                await api.orderPutProductionKeyProductProcess({
                  id: data.id,
                  orderCode: quotationInfo.orderCode,
                  orderId: quotationInfo.id,
                  outsourcing: 1,
                  checked: e.target.checked,
                });
              }}
            />
            <br />
            <label
              htmlFor="machining"
              style={{
                width: "60px",
                display: "inline-block",
                lineHeight: "30px",
              }}
            >
              机加工
            </label>
            <Checkbox
              type="checkbox"
              name="machining"
              defaultValue={data.machining}
              onChange={onChange}
              onBlur={async (e) => {
                await api.orderPutProductionKeyProductProcess({
                  id: data.id,
                  orderCode: quotationInfo.orderCode,
                  orderId: quotationInfo.id,
                  machining: 1,
                  checked: e.target.checked,
                });
              }}
            />
            <br />
            <label
              htmlFor="weld"
              style={{
                width: "60px",
                display: "inline-block",
                lineHeight: "30px",
              }}
            >
              焊接
            </label>
            <Checkbox
              type="checkbox"
              name="weld"
              defaultValue={data.weld}
              onChange={onChange}
              onBlur={async (e) => {
                await api.orderPutProductionKeyProductProcess({
                  id: data.id,
                  orderCode: quotationInfo.orderCode,
                  orderId: quotationInfo.id,
                  weld: 1,
                  checked: e.target.checked,
                });
              }}
            />
            <br />
            <label
              htmlFor="assemble"
              style={{
                width: "60px",
                display: "inline-block",
                lineHeight: "30px",
              }}
            >
              组装
            </label>
            <Checkbox
              type="checkbox"
              name="assemble"
              defaultValue={data.assemble}
              onChange={onChange}
              onBlur={async (e) => {
                await api.orderPutProductionKeyProductProcess({
                  id: data.id,
                  orderCode: quotationInfo.orderCode,
                  orderId: quotationInfo.id,
                  assemble: 1,
                  checked: e.target.checked,
                });
              }}
            />
            <br />
            <label
              htmlFor="functional"
              style={{
                width: "60px",
                display: "inline-block",
                lineHeight: "30px",
              }}
            >
              功能测试
            </label>
            <Checkbox
              type="checkbox"
              name="functional"
              defaultValue={data.functional}
              onChange={onChange}
              onBlur={async (e) => {
                await api.orderPutProductionKeyProductProcess({
                  id: data.id,
                  orderCode: quotationInfo.orderCode,
                  orderId: quotationInfo.id,
                  functional: 1,
                  checked: e.target.checked,
                });
              }}
            />
            <br />
            <label
              htmlFor="checkout"
              style={{
                width: "60px",
                display: "inline-block",
                lineHeight: "30px",
              }}
            >
              检验
            </label>
            <Checkbox
              type="checkbox"
              name="checkout"
              defaultValue={data.checkout}
              onChange={onChange}
              onBlur={async (e) => {
                await api.orderPutProductionKeyProductProcess({
                  id: data.id,
                  orderCode: quotationInfo.orderCode,
                  orderId: quotationInfo.id,
                  checkout: 1,
                  checked: e.target.checked,
                });
              }}
            />
            <br />
            <label
              htmlFor="packaging"
              style={{
                width: "60px",
                display: "inline-block",
                lineHeight: "30px",
              }}
            >
              包装
            </label>
            <Checkbox
              type="checkbox"
              name="packaging"
              defaultValue={data.packaging}
              onChange={onChange}
              onBlur={async (e) => {
                await api.orderPutProductionKeyProductProcess({
                  id: data.id,
                  orderCode: quotationInfo.orderCode,
                  orderId: quotationInfo.id,
                  packaging: 1,
                  checked: e.target.checked,
                });
              }}
            />
          </Form.Item>
        );
      },
    },
    {
      title: "预计交货时间",
      dataIndex: "",
      width: 100,
      align: "center",
      render: (text, data, index) => {
        return (
          <Form.Item style={{ marginBottom: 0 }}>
            <DatePicker
              // value={data.productDelivery}
              onBlur={async (e) => {
                let aa =
                  await api.orderPutProductionLiaisonDocUpdateOrderProductInfo({
                    id: data.id,
                    orderCode: quotationInfo.orderCode,
                    orderId: quotationInfo.id,
                    productDelivery: e.target.value,
                  });
              }}
            />
          </Form.Item>
        );
      },
    },
    {
      title: "产品类别",
      dataIndex: "",
      width: 100,
      align: "center",
      render: (text, data, index) => {
        const handleDropdownVisibleChange = (visible) => {
          if (!visible) {
            // 下拉菜单关闭时触发
            handleOptionChange(data.specimen, data);
          }
        };

        return (
          <Select
            value={data.specimen}
            onChange={(value) => handleOptionChange(value, data)}
            onDropdownVisibleChange={handleDropdownVisibleChange}
          >
            <Option value="A样">A样</Option>
            <Option value="B样">B样</Option>
            <Option value="C样">C样</Option>
            <Option value="D样">D样</Option>
          </Select>
        );
      },
    },
    {
      title: "参数修正",
      width: 120,
      align: "left",
      dataIndex: "",
      render: (text, data, index) => {
        return (
          <Form.Item>
            <Button
              // className="mr-3"
              style={{ float: "right" }}
              onClick={async () => {
                showDrawer(true);
                let res = await api.getUpdateProductName({
                  orderCode: data.orderCode,
                  productTitle: data.productTitle,
                  id: data.id,
                });
                setproductConfig(res);
              }}
              icon={<PlusOutlined />}
            >
              修改更正
            </Button>
          </Form.Item>
        );
      },
    },
  ];
  const selectUpdateProductName = async () => {
    let res = await api.selectUpdateProductName({
      productId: config.productId,
    });
    setSelectProductConfig(res);
  };
  const deleteItem = async (e) => {
    let res = await api.getDeleteProductConfig({
      id: config.id,
      index: e.index,
      deleteCode: e.orderCode,
    });
  };
  const getQuotationInfo = async () => {
    const orderCode = qs.parse(window.location.search.split("?")[1]).orderCode;
    let res = await api.orderGetProductionLiaisonDoc({ orderCode });
    form.setFieldsValue({
      ...res.data,
      restimatedDeliveryTime:
        (res.data.restimatedDeliveryTime &&
          moment(res.data.restimatedDeliveryTime)) ||
        "",
    });
    let totalPrice = 0;
    [...res.data.orderProductList].map((e) => {
      totalPrice += e.productAmount * e.productNumber;
    });
    settb_modifications([...res.data.modifications]);
    setTotalPrice(totalPrice.toFixed(2));
    setquotationInfo(res.data);
    setProDataSource([...res.data.orderProductList]);
    setCraftDataSource([...res.data.orderCraftList]);
  };
  useEffect(() => {
    getQuotationInfo();
    // getUpdateProductName();
  }, []);
  const onFinish = async (values) => {
    const orderCode = qs.parse(window.location.search.split("?")[1]).orderCode;
    let params = {
      // selectedOption: selectedOption,
      // productId: productId,
      id: quotationInfo.id,
      ...values,
      orderCode,
      restimatedDeliveryTime:
        (values?.restimatedDeliveryTime &&
          moment(values?.restimatedDeliveryTime).format(
            "YYYY-MM-DD HH:mm:ss"
          )) ||
        "",
    };

    let res = await api.orderProductionLiaisonDocUpdate(params);
    if (res.code == 200) {
      api
        .productionLiaisonDoc({
          orderCode: qs.parse(window.location.search.split("?")[1]).orderCode,
        })
        .then((res) => {
          if (res.code == 200) {
            let btn = document.createElement("a");
            btn.download = `订单编号-${
              qs.parse(window.location.search.split("?")[1]).orderCode
            }-生产联络单.pdf`;
            btn.href = res.data;
            btn.target = "_blank";
            btn.click();
            btn = null;
          }
        });
      return message.success("保存成功,正在生成单据");
    }
    message.error(res.message);
  };
  return (
    <div style={{ width: "100%", margin: "0 auto" }}>
      <Form form={form} name="quotation" initialValues={{}} onFinish={onFinish}>
        <h2 className="text-center">产品生产联络单</h2>
        <br />
        <br />
        <div style={{ display: "flex", justifyContent: "center" }}>
          {quotationInfo.repurchaseNumber && <ReloadOutlined />}
        </div>
        <div className="ant-table ant-table-bordered">
          <div className="ant-table-container">
            <div className="ant-table-content">
              <table className="table">
                <tbody className="ant-table-tbody">
                  <tr className="ant-table-row">
                    <td className="ant-table-cell">合同编号:</td>
                    <td className="ant-table-cell" colspan="3">
                      <Form.Item name="orderCode" style={{ marginBottom: 0 }}>
                        <Input />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr className="ant-table-row">
                    <td className="ant-table-cell">对方订单号：</td>
                    <td className="ant-table-cell">
                      <Form.Item
                        name="bayerContractNo"
                        style={{ marginBottom: 0 }}
                        onBlur={async (e) => {
                          await api.orderProductionUpdateDispatchDate({
                            id: form.id,
                            orderCode: quotationInfo.orderCode,
                            orderId: quotationInfo.id,
                            bayerContractNo: e.target.value,
                          });
                        }}
                      >
                        <Input />
                      </Form.Item>
                    </td>
                    <td className="ant-table-cell">派单日期：</td>
                    <td className="ant-table-cell">
                      <Form.Item style={{ marginBottom: 0 }}>
                        <DatePicker
                          value={dispatchDate}
                          onChange={(date) => {
                            setDispatchDate(date);
                            // 其他逻辑
                          }}
                          onBlur={async (e) => {
                            // console.log(proDataSource);
                            // console.log(quotationInfo);
                            await api.orderProductionUpdateDispatchDate({
                              id: form.id,
                              orderCode: quotationInfo.orderCode,
                              orderId: quotationInfo.id,
                              dispatchDate: e.target.value,
                            });
                          }}
                        />
                      </Form.Item>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <br />
        <br />
        <Table
          columns={proColumns}
          dataSource={proDataSource}
          pagination={false}
          bordered
          summary={() => (
            <>
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell colSpan={8}>
                    <Form.Item
                      name="restimatedDeliveryTime"
                      label="预计交货时间"
                      style={{ marginBottom: 0, gap: "20px" }}
                    >
                      <DatePicker
                        value={restimatedDeliveryTime}
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        // showTime
                      />
                    </Form.Item>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            </>
          )}
        />
        <br />
        <br />
        <div className="text-center">
          <Button
            className="mr-3"
            onClick={() => {
              // window.history.go(-1);
              // window.open("/index/order/contract");
              window.close();
            }}
          >
            返回
          </Button>
          <Button
            type="primary"
            className="mr-3"
            danger
            onClick={() => {
              setIsModalOpen(true);
              // let res = api.orderReleaseCause({
              //   // id: this.state.dataindex.id,
              //   orderCode: qs.parse(window.location.search.split("?")[1])
              //     .orderCode,
              //   orderStatus:3,
              //   releaseCause: e.target.value,
              // })
              // if(res.code !== 500){
              //   message.success("拒绝成功")
              // }else{
              //   message.error("无法拒绝")
              // }
            }}
          >
            拒绝
          </Button>
          {/* <Button className="mr-3" type="primary" htmlType="submit" disabled={!hasEmpty}>
            提交
          </Button> */}
          <Button className="mr-3" type="primary" htmlType="submit">
            提交
          </Button>
          <Button
            className="mr-3"
            onClick={() => {
              api
                .productionLiaisonDoc({
                  orderCode: qs.parse(window.location.search.split("?")[1])
                    .orderCode,
                })
                .then((res) => {
                  if (res.code == 200) {
                    let btn = document.createElement("a");
                    btn.download = `订单编号-${
                      qs.parse(window.location.search.split("?")[1]).orderCode
                    }-生产联络单.pdf`;
                    btn.href = res.data;
                    btn.target = "_blank";
                    btn.click();
                    btn = null;
                  }
                });
            }}
          >
            导出
          </Button>
        </div>
      </Form>
      <Modal
        title="拒绝原因"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form.Item style={{ marginBottom: 0 }}>
          <Input.TextArea
            name="releaseCause"
            placeholder="填写内容即代表拒绝"
            onBlur={async (e) => {
              let aa = await api.orderReleaseCause({
                orderCode: qs.parse(window.location.search.split("?")[1])
                  .orderCode,
                releaseCause: e.target.value,
              });
              if (aa.data === true) {
                this.componentDidMount();
              }
            }}
          />
        </Form.Item>
      </Modal>
      <Drawer
        title="参数替换更正"
        width={900}
        onClose={onClose}
        visible={open}
        form={config}
        bodyStyle={{
          paddingBottom: 80,
        }}
        extra={
          <Space>
            <Button onClick={onClose}>取消</Button>
            <Button onClick={onClose} type="primary">
              提交
            </Button>
          </Space>
        }
      >
        <Form layout="vertical" hideRequiredMark>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item>
                产品型号：<Input value={config.productModel}></Input>
                <br />
                {(config.productConfig &&
                  JSON.parse(config.productConfig).map((e, index) => {
                    const handleDelete = () => {
                      const formValues = form.getFieldsValue();
                      const updatedConfig = JSON.parse(config.productConfig);
                      updatedConfig.splice(index, 1); // 从数组中删除指定索引的项
                      deleteItem({
                        orderCode: formValues.orderCode,
                        index,
                      });
                    };

                    return (
                      <div key={index} style={{ lineHeight: 2 }}>
                        {e.label}: {e.value}
                        <Button onClick={handleDelete}>删除</Button>
                      </div>
                    );
                  })) ||
                  "-"}
              </Form.Item>

              <Button
                type="primary"
                onClick={() => {
                  showChildrenDrawer();
                  setProductId(config);
                }}
              >
                简易选型
              </Button>
            </Col>
          </Row>
        </Form>
        <Drawer
          title="简易选型"
          width={800}
          onClose={onChildrenDrawerClose}
          visible={childrenDrawer}
          bodyStyle={{
            paddingBottom: 80,
          }}
          extra={
            <Space>
              <Button onClick={onChildrenDrawerClose}>取消</Button>
              <Button
                type="primary"
                htmlType="submit"
                onClick={getUpdateProductConfig}
              >
                提交
              </Button>
            </Space>
          }
        >
          <Form
            form={form}
            layout="vertical"
            hideRequiredMark
            style={{ display: "flex" }}
            className="w"
            onFinish={handleSubmit}
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item>
                  {(selectconfig.templateContent &&
                    JSON.parse(selectconfig.templateContent).map((e) => {
                      if (e.label === "单行文本") {
                        return (
                          <Form.Item
                            name={e.props.title}
                            label={e.props.title}
                            key={e.props.title}
                          >
                            <Input
                              style={{ lineHeight: 2 }}
                              addonBefore={e.props.title}
                              placeholder="请输入内容"
                            />
                          </Form.Item>
                        );
                      }
                      if (e.label === "多行文本") {
                        return (
                          <Form.Item
                            name={e.props.title}
                            label={e.props.title}
                            key={e.props.title}
                          >
                            <Input.TextArea
                              placeholder="请输入内容"
                              autoSize={{ minRows: 3, maxRows: 5 }}
                            />
                          </Form.Item>
                        );
                      }
                      if (e.label === "级联选择") {
                        let configList = e.props.options;
                        return (
                          <Form.Item
                            name={e.props.title}
                            label={e.props.title}
                            key={e.props.title}
                            getValueFromEvent={(value) => value[0]}
                          >
                            <div>过程连接/规格</div>
                            <Cascader
                              fieldNames={{
                                label: "label",
                                value: "value",
                                children: "options",
                              }}
                              changeOnSelect={true}
                              options={configList}
                              placeholder="请选择"
                              onChange={(value) => {
                                form.setFieldsValue({ [e.props.title]: value });
                              }}
                            />
                          </Form.Item>
                        );
                      }
                      if (e.label === "单选") {
                        return (
                          <Form.Item
                            name={e.props.title}
                            label={e.props.title}
                            key={e.props.title}
                          >
                            <Radio.Group
                              options={e.props.options}
                            ></Radio.Group>
                          </Form.Item>
                        );
                      }
                      if (e.label === "多选") {
                        return (
                          <Form.Item
                            name={e.props.title}
                            label={e.props.title}
                            key={e.props.title}
                          >
                            <Checkbox.Group
                              options={e.props.options}
                            ></Checkbox.Group>
                          </Form.Item>
                        );
                      }
                      if (e.label === "单选下拉框") {
                        return (
                          <Form.Item
                            name={e.props.title}
                            label={e.props.title}
                            key={e.props.title}
                          >
                            <Select>
                              {e.props.options.map((option) => (
                                <Select.Option
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        );
                      }
                      if (e.label === "数字") {
                        return (
                          <Form.Item
                            name={e.props.title}
                            label={e.props.title}
                            key={e.props.title}
                          >
                            <InputNumber
                              placeholder="请输入数字"
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        );
                      }
                    })) ||
                    "-"}
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    onClick={getUpdateProductConfig}
                  >
                    提交
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Drawer>
      </Drawer>
    </div>
  );
};

export default ContactList;
