import React, { useState, useEffect } from "react";
import { Button, Avatar, Modal, Form, Row, Col, Input, message } from "antd";
import cookie from "react-cookies";
import api from "../api";

import { UserOutlined } from "@ant-design/icons";

const EditUserForm = ({ visible, onCreate, onCancel, chooseUserInfo }) => {
  const [form] = Form.useForm();
  const layout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 16,
    },
  };

  const [current, setCurrent] = React.useState(0);


  React.useEffect(() => {
    form.resetFields();
    setCurrent(0);
    if (chooseUserInfo.user_id) {
      api
        .getUserListInfo({
          id: chooseUserInfo.user_id,
        })
        .then((res) => {
          chooseUserInfo = res.data;
          delete chooseUserInfo.userPwd;
          form.setFieldsValue({ ...chooseUserInfo });
        });
    }
  }, [chooseUserInfo, visible]);

  const setCurrentEvent = () => {
    form
      .validateFields()
      .then((values) => {
        onCreate(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  let okText = "保存";
  let cancelText = "取消";
  // if (current === 1) {
  //   okText = "保存";
  //   cancelText = "上一步";
  // }
  const cancelEvent = () => {
    if (current === 1) {
      setCurrent(0);
    } else {
      onCancel();
    }
  };

  return (
    <Modal
      getContainer={false}
      visible={visible}
      title="修改密码"
      width={400}
      okText={okText}
      cancelText={cancelText}
      onCancel={() => {
        cancelEvent();
      }}
      onOk={() => {
        setCurrentEvent();
      }}
      destroyOnClose
    >
      <Form
        form={form}
        {...layout}
        name="form_in_modal"
        initialValues={chooseUserInfo}
      >
        <div
          className="stap1"
          style={{ display: current === 0 ? "block" : "none" }}
        >
          <Row gutter={30}>
            <Form.Item name="id">
              <Input type="hidden" value={chooseUserInfo.id} disabled />
            </Form.Item>
            <Col span={24}>
              <Form.Item name="oldPwd" label="原密码">
                <Input.Password />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="userPwd" label="新密码">
                <Input.Password />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="userPwdV" label="确认新密码">
                <Input.Password />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
    </Modal>
  );
};

class Mhead extends React.Component {
  state = {
    login_state: false,
    uinfo: cookie.load("userInfo") || {},
    showEdit: false,
  };

  componentDidMount = () => {
    // this.setState({ loading: true })
    console.log(this.state.uinfo);
    if(this.state.uinfo.user_id===84 || this.state.uinfo.user_id===86 || this.state.uinfo.user_id===87 || this.state.uinfo.user_id===89)
      // setInterval(function(){
      //   window.location.reload();
      // },300000);
    setInterval(() => {
      if (!cookie.load("accessToken")) {
        cookie.remove("userInfo"); 
        cookie.remove("accessToken");
        console.log(cookie.load("accessToken"));
        localStorage.removeItem("ruleMenu");
        window.location.href = "/login";
      }
    }, 500);
  };
  logOut(_t) {
    cookie.remove("userInfo");
    cookie.remove("accessToken", { path: "/" });
    localStorage.removeItem("ruleMenu");
    window.location.href = "/login";
  }

  modalChange(type, info) {
    const _t = this;
    delete info.userPwd;
    _t.setState({
      [type]: !_t.state[type],
    });
  }

  render() {
    const onEditUser = async (values) => {
      console.log(values);
      if (values.userPwdV != values.userPwd) {
        return message.error("密码不一致");
      }

      let result = await api.sysEditUser(values);
      if (!result || result.code !== 200) {
        message.error(result.message);
        return false;
      }
      this.setState({ showEdit: false });
    };
    return (
      <div className="content">
        <div className="logo">
          <img src={require("../assets/image/head-logo.png")} alt="" />
        </div>
        <div className="title"></div>
        <div className="right" style={{ textAlign: "right" }}>
          <span >
          <Button className="pc-btn"
            type="link"
          >
          <a href="http://select.feejoy.com/#/" target="_blank" >前台页面</a>
            {/* 前台页面 */}
          </Button>
          </span>
          <span >
          <Button 
          id="orderButton"
            type="link"
            className="submit"
          >
          <a href="https://system.feejoy.com/index/order/contract" >订单</a>
          </Button>
          </span>
          <Button className="pc-btn"
            type="link"
            onClick={() => {
              this.modalChange("showEdit", true);
            }}
          >
            修改密码
          </Button>
          <Button  type="link" onClick={this.logOut}>
            退出
          </Button>
        </div>
        {/* 修改用户信息 */}
        {this.state.showEdit && (
          <EditUserForm
            visible={this.state.showEdit}
            onCreate={onEditUser}
            onCancel={() => {
              this.modalChange("showEdit", false);
            }}
            chooseUserInfo={this.state.uinfo}
          />
        )}
      </div>
    );
  }
}
export default Mhead;
