import React from 'react'
import { Form, Cascader } from 'antd'
/**
 * @prop config 属性设置
 */
export default class TempCascader extends React.Component {
    constructor(props) {
        super(props);
    }
    dealOptions(options) {
        return options.filter(item => !!item && (item.value || item.label)).map((item, index) => {
            if (item.options) {
                item.options = this.dealOptions(item.options);
            }
            item.value = `${item.label}_${index}`;
            return item;
        })
    }
    render() {
        let { config } = this.props;
        let options = this.dealOptions(config.options);
        return (
            <Form.Item label={config.title} name={config.field} >
                <Cascader options={options} placeholder={`请选择${config.title}`} fieldNames={{ label: 'label', value: 'label', children: 'options' }} />
            </Form.Item>
        )
    }
}
