import React from 'react'

import { Switch, Route, Redirect, Link } from 'react-router-dom'
import { Spin, Form, Input, Button } from 'antd'
import { AppstoreOutlined } from '@ant-design/icons'

class Payment extends React.Component {
  state = {
    login_state: false
  }
  componentDidMount = () => {
    // this.setState({ loading: true })
  }

  render() {
    const layout = {
      labelCol: {
        span: 3
      },
      wrapperCol: {
        span: 8
      }
    }
    const validateMessages = {
      required: '${label} 不能为空!'
      // types: {
      //   email: '${label} is not validate email!',
      //   number: '${label} is not a validate number!',
      // },
      // number: {
      //   range: '${label} must be between ${min} and ${max}',
      // },
    }
    const onFinish = (values) => {
      console.log(values)
    }
    return (
      <div>
        <Form {...layout} name="nest-messages" onFinish={onFinish} validateMessages={validateMessages}>
          <Form.Item
            name={[ 'wxpay', 'appId' ]}
            label="微信支付AppId"
            rules={[
              {
                required: true
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={[ 'wxpay', 'appSecret' ]}
            label="微信支付AppSecret"
            rules={[
              {
                required: true
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={[ 'wxmini', 'appId' ]}
            label="小程序支付AppId"
            rules={[
              {
                required: true
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={[ 'wxmini', 'appSecret' ]}
            label="小程序支付AppSecret"
            rules={[
              {
                required: true
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item wrapperCol={{ ...layout.labelCol, offset: 2 }}>
            <Button type="primary" htmlType="submit">
              提交
            </Button>
          </Form.Item>
        </Form>
      </div>
    )
  }
}
export default Payment
