

import instance from './instance.js';
import { base } from "./config.js";
import { message } from 'antd'
export default {
    //注册用户数量和商家数量
    async index_trading() {
        return await instance.get(`${base}/home/trading`)
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            message.error('服务器出错');
        });
    },
    // 系统公告接口
    async notice_create(params) {
        return await instance.post(`${base}/notice/create`, params)
            .then((res) => {
                return res.data;
            })
            .catch((error) => {
                message.error('服务器出错');
            });
    },
}