import React from "react";
// import { Link
//     // , withRouter, Switch, Route, Redirect
//  } from 'react-router-dom';
import { Form, Input, Button, Checkbox, Col, Row, Spin, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import Footer from "../../components/footer";
import "./login.less";
import api from "../../api";
import cookie from "react-cookies";
import uitls from "../../assets/js/public";
export default class Login extends React.Component {
  constructor(props) {
    super(props);
    // console.log(this.props)
    this.state = {
      loading: false,
    };
  }

  componentDidMount = () => {
    document.title = "飞卓产品选型系统";
  };

  login = async (values) => {
    this.setState({ loading: true });
    values = Object.assign(values);
    console.log(values);
    uitls.try_catch(async () => {
      let res = await api.login(values);
      // console.log(cookie.load('ar').redirectUri)
      if (res.code === 200) {
        message.success("登录成功");
        const accessToken = res.data?.token.replace("Bearer ", "");
        cookie.save("userInfo", JSON.stringify(res.data));
        cookie.save("accessToken", accessToken);
        // cookie.save('userInfo', JSON.stringify(res.data))
        this.props.history.push("/index");
      } else {
        message.error(res.message);
        if (res.message === "用户未登录") {
          cookie.remove("userInfo", { path: "/" });
          cookie.remove("accessToken", { path: "/" });
          this.props.history.push("/login");
        }
      }
    });
    this.setState({ loading: false });
  };

  render() {
    return (
      <Spin spinning={this.state.loading}>
        <div className="header-logo"></div>
        <div className="login">
          <Row gutter={{
            xs: 8,
            sm: 16,
            md: 24,
            lg: 32,}} >
            <Col span={24}>
              <div className="middle-box">
                <div className="login-box">
                  <Form
                    className="login-form"
                    onFinish={this.login}
                    name="normal_login"
                    initialValues={{ remember: true }}
                  >
                    <h2 className="text-left">飞卓产品选型系统</h2>
                    <Form.Item
                      name="userName"
                      rules={[{ required: true, message: "请输入用户名" }]}
                    >
                      <Input
                        prefix={
                          <UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                        }
                        placeholder="请输入用户名"
                      />
                    </Form.Item>
                    <Form.Item
                      name="userPwd"
                      rules={[{ required: true, message: "请输入密码" }]}
                    >
                      <Input
                        prefix={
                          <LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                        }
                        type="password"
                        placeholder="请输入密码"
                      />
                    </Form.Item>
                    {/* <Form.Item className="mb-0">
                    </Form.Item> */}
                    <Form.Item className="mb-0">
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                      >
                        登录
                      </Button>
                    </Form.Item>
                    {/* <span className="gologin"><a href="/register">没有帐号?去注册</a></span> */}
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Spin>
    );
  }
}
