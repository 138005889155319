import instance from './instance.js';
import { base } from "./config.js";
import {message} from 'antd'
export default {
  //管理员登录
  async login(params){
    return await instance.post(`${base}/m/login`,params).then((res)=>{
      return res.data;
    }).catch((error)=>{
      message.error('服务器出错');
    });
  },

}