import instance from "./instance.js";
import { base, mock } from "./config.js";
import cookie from "react-cookies";
import qs from "qs";
export default {
  //获取用户列表
  async getUserList(params) {
    return await instance
      .post(`${base}/m/sysUser/query`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        //message.error('服务器出错')
      });
  },
  //获取用户信息
  async getUserListInfo(params) {
    return await instance
      .get(`${base}/m/sysUser/get/${params.id}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        //message.error('服务器出错')
      });
  },

  //创建管理员
  async addUser(params) {
    return await instance
      .put(`${base}/m/sysUser/addOrUpdate`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        //message.error('服务器出错')
      });
  },
  //修改管理员密码
  async sysEditUser(params) {
    return await instance
      .post(`${base}/m/sysUser/updatePwdOrEnableDTO`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        //message.error('服务器出错')
      });
  },
  
  // 删除用户 /m/sysUser/delete/{id}
  async delUser(params) {
    return await instance
      .delete(`${base}/m/sysUser/delete/${params}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        //message.error('服务器出错')
      });
  },
  //获取角色信息
  async sysUserQuery(params) {
    return await instance
      .get(`${base}/m/sysUser/query/${params.id}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        //message.error('服务器出错')
      });
  },

  //获取角色列表
  async getRoleList(params) {
    return await instance
      .post(`${base}/m/sysRole/query`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        //message.error('服务器出错')
      });
  },

  //给角色分配权限
  async sysRoleDoAssign(params) {
    return await instance
      .post(`${base}/m/sysRole/doAssign`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        //message.error('服务器出错')
      });
  },

  //获取角色信息
  async getSysRole(params) {
    return await instance
      .get(`${base}/m/sysRole/get/${params.id}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        //message.error('服务器出错')
      });
  },
  //创建/更新角色
  async RolesAddOrUpdate(params) {
    return await instance
      .put(`${base}/m/sysRole/addOrUpdate`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        //message.error('服务器出错')
      });
  },
  // 删除角色
  async delRoles(params) {
    return await instance
      .delete(`${base}/m/sysRole/delete/${params.id}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        //message.error('服务器出错')
      });
  },
  //创建/更新资源
  async sysResourceAddOrUpdate(params) {
    return await instance
      .put(`${base}/m/sysResource/addOrUpdate`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        //message.error('服务器出错')
      });
  },
  // 删除资源
  async sysResourceDelete(params) {
    return await instance
      .delete(`${base}/m/sysResource/delete/${params.id}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        //message.error('服务器出错')
      });
  },
  //获取用户资源信息
  async sysResourceGetUserResource(params) {
    return await instance
      .get(`${base}/m/sysResource/getUserResource`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        //message.error('服务器出错')
      });
  },
  //分页获取资源列表信息
  async sysResourceQuery(params) {
    return await instance
      .post(`${base}/m/sysResource/query`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        //message.error('服务器出错')
      });
  },
  //获取所有资源信息
  async sysResourceQueryAll() {
    return await instance
      .post(`${base}/m/sysResource/queryAll`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        //message.error('服务器出错')
      });
  },
  // 递归删除菜单
  async sysResourceRemove(params) {
    return await instance
      .delete(`${base}/m/sysResource/remove/${params.id}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        //message.error('服务器出错')
      });
  },
};
