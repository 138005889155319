import instance from "./instance.js";
import { base } from "./config.js";
import { message } from "antd";
export default {
  // 文件上传
  async fileUpload(params) {
    return await instance
      .post(`${base}/m/file/upload`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  async orderFileAddresse(params) {
    return await instance
      .put(`${base}/m/order/orderFileAddresse`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  // 获取所有技术参数
  async technicalParamGetAll(params) {
    return await instance
      .get(`${base}/m/technicalParam/getAll`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  // 创建/更新产品
  async productAddOrUpdate(params) {
    return await instance
      .put(`${base}/m/product/addOrUpdate`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  // 删除产品
  async productDelete(params) {
    return await instance
      .delete(`${base}/m/product/delete/${params.id}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  // 获取产品信息
  async productGet(params) {
    return await instance
      .get(`${base}/m/product/get/${params.id}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  // 获取产品列表信息
  async productGetList(params) {
    return await instance
      .post(`${base}/m/product/query`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  // 导出产品列表信息
  async productExport(params) {
    return await instance
      .post(`${base}/m/product/export`, params, {
        responseType: "blob",
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },

  // 获取产品列表信息
  async productUpdateShelves(params) {
    return await instance
      .put(`${base}/m/product/updateShelves`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  // 获取所有认证信息
  async certificationGetAll(params) {
    return await instance
      .get(`${base}/m/certification/getAll`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  // 获取所有分类信息
  async classifyGetAll(params) {
    return await instance
      .get(`${base}/m/classify/getAll`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },

  // 根据产品ID获取所有条件值列表
  async productGetAllConditions(params) {
    return await instance
      .get(`${base}/m/product/getAllConditions/${params.id}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  // 根据产品ID获取条件值列表
  async productGetConditions(params) {
    return await instance
      .get(`${base}/m/product/getConditions/${params.id}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  // 根据产品ID获取条件值Id列表
  async productGetConditionIds(params) {
    return await instance
      .get(`${base}/m/category/getConditionIds/${params.id}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  // 根据产品ID复制产品
  async productCopyProduct(params) {
    return await instance
      .get(`${base}/m/product/copyProduct/${params.id}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },

  // 修改产品条件值
  async productUpdateConditions(params) {
    return await instance
      .post(`${base}/m/product/updateConditions`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  // 创建/更新类目
  async categoryAddOrUpdate(params) {
    return await instance
      .put(`${base}/m/category/addOrUpdate`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  // 删除类目
  async categoryDelete(params) {
    return await instance
      .delete(`${base}/m/category/delete/${params.id}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  // 获取类目信息
  async categoryGet(params) {
    return await instance
      .get(`${base}/m/category/get/${params.id}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  // 获取所有类目
  async categoryGetAll(params) {
    return await instance
      .get(`${base}/m/category/getAll`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  // 分页获取类目列表信息
  async categoryQuery(params) {
    return await instance
      .post(`${base}/m/category/query`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  // 根据类目id获取类目配置
  async categoryGetConfig(params) {
    return await instance
      .get(`${base}/m/category/getConfig/${params.id}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  // 创建/更新条件
  async conditionAddOrUpdate(params) {
    return await instance
      .put(`${base}/m/condition/addOrUpdate`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  // 删除条件
  async conditionDelete(params) {
    return await instance
      .delete(`${base}/m/condition/delete/${params.id}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  // 获取条件信息
  async conditionGet(params) {
    return await instance
      .get(`${base}/m/condition/get/${params.id}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  // 获取所有条件
  async conditionGetAll(params) {
    return await instance
      .get(`${base}/m/condition/getAll`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  // 分页获取条件列表信息
  async conditionQuery(params) {
    return await instance
      .post(`${base}/m/condition/query`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  // 类目绑定修改条件列表
  async categoryUpdateConditions(params) {
    return await instance
      .post(`${base}/m/category/updateConditions`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  // 类目绑定修改条件列表
  async categoryGetConditions(params) {
    return await instance
      .get(`${base}/m/category/getConditions/${params.id}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },

  // 创建/更新条件值
  async conditionValueAddOrUpdate(params) {
    return await instance
      .put(`${base}/m/conditionValue/addOrUpdate`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  // 删除条件值
  async conditionValueDelete(params) {
    return await instance
      .delete(`${base}/m/conditionValue/delete/${params.id}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  // 获取条件信息值
  async conditionValueGet(params) {
    return await instance
      .get(`${base}/m/conditionValue/get/${params.id}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  // 获取所有条件值
  async conditionValueGetAll(params) {
    return await instance
      .get(`${base}/m/conditionValue/getAll`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  // 分页获取条件值列表信息
  async conditionValueQuery(params) {
    return await instance
      .post(`${base}/m/conditionValue/query`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
};
