import React, { useEffect, useState } from "react";

import { Route, Redirect, Link } from "react-router-dom";
import {
  Table,
  Row,
  Col,
  Button,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Checkbox,
  message,
  Cascader,
  Switch,
} from "antd";
import { SearchOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import api from "../../api";
import utils from "../../assets/js/public.js";
const { Option } = Select;
const { confirm } = Modal;

const getRandomuserParams = (params) => {
  console.log(params);
  return {
    pageSize: params.pageSize,
    pageNo: params.pageNo,
  };
};
const CheckboxGroup = Checkbox.Group;

const EditRolesForm = ({ visible, onCreate, onCancel, chooseUserInfo }) => {
  const [form] = Form.useForm();
  const [classifyArr, setClassifyArr] = React.useState([]);
  const layout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 16,
    },
  };
  React.useEffect(() => {
    let fn;
    fn = api.categoryGetAll();
    fn.then((res) => {
      if (res?.code === 200) {
        let classify = [
          {
            id: "0",
            parentId: "0",
            categoryName: "顶级类目",
            children: res.data,
          },
        ];
        if (!chooseUserInfo) {
          classify = JSON.stringify(classify)
            .replace(/id/g, "value")
            .replace(/categoryName/g, "label");
          classify = JSON.parse(classify);
          console.log(classify);
          setClassifyArr(classify);
        } else {
          classify = JSON.stringify(classify)
            .replace(/id/g, "value")
            .replace(/categoryName/g, "label");
          classify = JSON.parse(classify);
          setClassifyArr(classify);
          console.log(chooseUserInfo);

          let classifyVal = (chooseUserInfo.parentId != 0 && [
            "0",
            ...utils.getParent(res.data, chooseUserInfo.id).map((e) => e.id),
          ]) || ["0"];

          // classifyVal.pop()
          console.log(classifyVal);
          if (chooseUserInfo.parentId != 0) {
            classifyVal.splice(
              classifyVal.findIndex((e) => e == chooseUserInfo.id),
              1
            );
          }
          // delete classifyVal[
          //   classifyVal.findIndex((e) => e == chooseUserInfo.id)
          // ];
          console.log(classifyVal);
          form.setFieldsValue({
            ...chooseUserInfo,
            parentId: classifyVal,
          });
          // chooseUserInfo.parentId = classifyVal
          console.log(classifyVal);
        }
      }
    });

    if (visible) {
      form.resetFields();
    }
  }, [chooseUserInfo, visible]);

  return (
    <Modal
      getContainer={false}
      visible={visible}
      title="设置类目信息"
      width={950}
      okText={"保存"}
      cancelText={"取消"}
      onOk={() => {
        // form.submit();
        onCreate(form.getFieldsValue());
      }}
      onCancel={onCancel}
      destroyOnClose
    >
      <Form
        form={form}
        {...layout}
        name="form_in_modal"
        // initialValues={chooseUserInfo}
      >
        <div>
          <Row gutter={30}>
            <Form.Item name="id">
              <Input type="hidden" value={chooseUserInfo.id} disabled />
            </Form.Item>
            <Col span={12}>
              <Form.Item name="categoryName" label="类目名称">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="categoryNameEn" label="类目英文名称">
                <Input />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item name="parentId" label="父级类目">
                <Cascader
                  changeOnSelect={true}
                  options={
                    (classifyArr && classifyArr.length && classifyArr) || false
                  }
                />
                {/* <Input /> */}
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
    </Modal>
  );
};

const EditConditionForm = ({ visible, onCreate, onCancel, chooseUserInfo }) => {
  const [form] = Form.useForm();
  const [conditionArr, setConditionArr] = React.useState([]);
  const [conditionValuesArr, setConditionValueArr] = React.useState([]);
  const [defaultConditionIds, setDefaultConditionIds] = useState([]);

  const getConditions = async () => {
    const res = await api.conditionQuery({
      pageNo: 1,
      pageSize: 99999,
    });

    if (res && res.code == 200) {
      setConditionArr(res.data?.records || []);
      getConditionValues(res.data?.records);
    }
  };

  const getCategoryConditions = async () => {
    if (chooseUserInfo?.id) {
      let result = await api.categoryGetConditions({
          id: chooseUserInfo?.id,
        }),
        datas = {};
      if (result.code == 200) {
        result.data.map((e) => {
          datas[`conditionId-${e.id}`] = [];
          if (e.valueList && e.valueList.length) {
            e.valueList.map((c) => {
              datas[`conditionId-${e.id}`].push(c.id);
            });
          }
        });
        form.setFieldsValue({
          categoryId: chooseUserInfo.id,
          ...datas,
        });
      }
    }
  };

  const getConditionValues = async (conditionsArr) => {
    let result = await api.conditionValueQuery({
      pageNo: 1,
      pageSize: 9999999,
    });
    if (!result || result.code !== 200) {
      return false;
    }
    let values = [...result.data.records];
    values.map((item, ind) => {
      let pIndex = conditionsArr.findIndex(
        (parentChild, index) => parentChild.id == item.conditionId
      );
      if (
        conditionsArr[pIndex].children &&
        conditionsArr[pIndex].children.length
      ) {
        conditionsArr[pIndex].children.push(item);
      } else {
        conditionsArr[pIndex].children = [{ ...item }];
      }
    });
    console.log("conditionsArr", conditionsArr);
    setConditionValueArr(conditionsArr);
  };

  useEffect(() => {
    form.resetFields();

    if (!!visible && chooseUserInfo) {
      getConditions();
      getCategoryConditions();
    }
  }, [chooseUserInfo, visible]);

  return (
    <Modal
      getContainer={false}
      visible={visible}
      title="绑定条件信息"
      okText={"保存"}
      cancelText={"取消"}
      onOk={() => {
        onCreate(form.getFieldsValue());
      }}
      onCancel={onCancel}
    >
      <Form
        initialValues={{
          categoryId: chooseUserInfo.id,
        }}
        form={form}
        name="form_in_modal"
      >
        <div>
          <Form.Item name="categoryId" hidden>
            <Input disabled />
          </Form.Item>
          <Row gutter={30}></Row>
          {(conditionValuesArr &&
            conditionValuesArr.length &&
            conditionValuesArr.map((e) => {
              return (
                <Form.Item
                  name={`conditionId-${e.id}`}
                  label={e.conditionName}
                  labelCol={{ span: 6 }}
                  wrapperCol={{ span: 16 }}
                >
                  <Select
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    mode="multiple"
                    changeOnSelect={true}
                    options={
                      (e.children &&
                        e.children.length &&
                        e.children.map((child) => ({
                          value: child.id,
                          label: child.valueContent,
                          key: child.id,
                          props: child,
                        }))) ||
                      []
                    }
                  />
                </Form.Item>
              );
            })) ||
            ""}
        </div>
      </Form>
    </Modal>
  );
};

class Userlist extends React.Component {
  state = {
    data: [],
    pagination: {
      pageNo: 1,
      pageSize: 10,
    },
    loading: false,
    showDetail: false,
    showEdit: false,
    showBindCondition: false,
    chooseUserInfo: {},
    categoryAll: [],
  };
  componentWillUnmount() {
    this.setState = () => false;
  }
  componentDidMount() {
    this.getAll();
  }
  modalChange(type, info) {
    const _t = this;
    this.setState({ chooseUserInfo: info ? info : {} }, () => {
      _t.setState({
        [type]: !_t.state[type],
      });
    });
  }
  getAll = async () => {
    api.categoryGetAll().then((res) => {
      this.setState({
        categoryAll: res?.data || [],
        data: res?.data || [],
      });
    });
  };

  showDeleteConfirm = (info) => {
    const { pagination } = this.state;
    const _t = this;
    confirm({
      title: "您确定要执行删除操作吗?",
      icon: <ExclamationCircleOutlined />,
      content: "此操作将永久删除用户，是否继续？",
      okText: "是",
      okType: "danger",
      cancelText: "否",
      onOk() {
        api.categoryDelete(info).then((result) => {
          if (!result || 200 !== result.code) {
            message.error(result.message);
          } else {
            message.success("删除成功");
            _t.getAll();
          }
        });
      },
      onCancel() {},
    });
  };

  render() {
    const { data, loading } = this.state;
    const ruleMenu =
      (
        localStorage.getItem("ruleMenu") &&
        JSON.parse(localStorage.getItem("ruleMenu"))
      ).find((e) => e.path == "/index/product")?.children || [];

    const columns = [
      {
        title: "序号",
        dataIndex: "id",
        width: 60,
        key: "id",
        fixed: "left",
        render: (index, data, k) => k + 1,
      },
      {
        title: "类目名称",
        dataIndex: "categoryName",
        width: 150,
        key: "categoryName",
      },
      {
        title: "父级类目ID",
        dataIndex: "parentId",
        width: 200,
        key: "parentId",
        render: (text, data) => {
          const tree = utils.getParent(this.state.categoryAll, data.id);
          return (
            (tree.length > 2 && tree[tree.length - 2]?.categoryName) ||
            (tree.length == 2 && tree[0]?.categoryName) ||
            "顶级类目"
          );
        },
      },
      {
        title: "创建时间",
        dataIndex: "createTime",
        width: 200,
        key: "createTime",
      },
      {
        title: "操作",
        width: 150,
        fixed: "right",
        key: "ctrl",
        render: (index, data) => {
          return (
            <div>
              {(ruleMenu &&
                ruleMenu.findIndex(
                  (e) => e.path == "/index/product/classify/del"
                ) >= 0 && (
                  <Button
                    type="primary"
                    danger
                    size="small"
                    className="mr-1"
                    onClick={() => this.showDeleteConfirm(data)}
                  >
                    删除
                  </Button>
                )) ||
                ""}
              {(ruleMenu &&
                ruleMenu.findIndex(
                  (e) => e.path == "/index/product/classify/edit"
                ) >= 0 && (
                  <Button
                    type="primary"
                    size="small"
                    className="mr-1"
                    onClick={() => this.modalChange("showEdit", data)}
                  >
                    修改
                  </Button>
                )) ||
                ""}
              {(ruleMenu &&
                ruleMenu.findIndex(
                  (e) => e.path == "/index/product/classify/relat"
                ) >= 0 && (
                  <Button
                    type="primary"
                    size="small"
                    className="mr-1"
                    onClick={() => this.modalChange("showBindCondition", data)}
                  >
                    关联条件
                  </Button>
                )) ||
                ""}
            </div>
          );
        },
      },
    ];

    const onEditUser = async (values) => {
      values.parentId = values.parentId[values.parentId.length - 1];
      console.log(values);
      // return;
      let result = await api.categoryAddOrUpdate(values);
      if (!result || result.code !== 200) {
        message.error(result.message);
        return false;
      }
      const { pagination } = this.state;
      this.getAll();
      this.setState({ showEdit: false });
    };

    const onEditConditions = async (values) => {
      console.log(values);
      let params = [],
        categoryId = null;
      // "categoryConditionList": [
      // 	{
      // 		"categoryId": 0,
      // 		"conditionId": 0,
      // 		"conditionValueId": 0,
      // 		"type": 0
      // 	}
      // ]
      for (let k in values) {
        // 所属类目ID
        if (k == "categoryId") {
          categoryId = values[k];
        } else {
          console.log(values[k]);
          if (values[k]) {
            values[k].map((item) => {
              params.push({
                categoryId: Number(categoryId),
                conditionId: Number(k.split("-")[1]),
                conditionValueId: Number(item),
                type: 1,
              });
            });
          }
        }
      }

      console.log(params);

      const result = await api.categoryUpdateConditions({
        categoryConditionList: params,
      });
      console.log(result);
      if (!result || result.code !== 200) {
        message.error(result.message);
        return false;
      }

      this.setState({ showBindCondition: false });
    };

    return (
      <div>
        <div className="search-box d-flex mb-3">
          <div className="d-flex align-item-center">
            <div className="mr-1">
              {(ruleMenu &&
                ruleMenu.findIndex(
                  (e) => e.path == "/index/product/classify/add"
                ) >= 0 && (
                  <Button
                    type="primary"
                    onClick={() => {
                      console.log(this.state.chooseUserInfo);
                      this.setState({ chooseUserInfo: {} });
                      this.modalChange("showEdit", {
                        sex: 1,
                      });
                    }}
                  >
                    添加类目
                  </Button>
                )) ||
                ""}
            </div>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          size="small"
          rowKey="id"
        />

        {/* 修改用户信息 */}
        {this.state.showEdit && (
          <EditRolesForm
            visible={this.state.showEdit}
            onCreate={onEditUser}
            onCancel={() => {
              this.modalChange("showEdit", false);
            }}
            chooseUserInfo={this.state.chooseUserInfo}
          />
        )}

        {/* 修改用户信息 */}
        {this.state.showBindCondition && (
          <EditConditionForm
            visible={this.state.showBindCondition}
            onCreate={onEditConditions}
            onCancel={() => {
              this.modalChange("showBindCondition", false);
            }}
            chooseUserInfo={this.state.chooseUserInfo}
          />
        )}
      </div>
    );
  }
}

export default Userlist;
