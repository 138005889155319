import instance from './instance.js'
import { base, mock } from './config.js'
import cookie from 'react-cookies'
import qs from 'qs'
export default {
  //创建消息
  async addOrUpdateMessage(params) {
    return await instance
      .put(`${base}/m/message/addOrUpdate`, params)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        //message.error('服务器出错')
      })
  },
  //删除消息
  async delMessage(params) {
    return await instance
      .delete(`${base}/m/message/delete/${params.id}`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        //message.error('服务器出错')
      })
  },
  //获取消息信息
  async getMessageInfo(params) {
    return await instance
      .get(`${base}/m/message/get/${params.id}`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        //message.error('服务器出错')
      })
  },
  //获取消息列表
  async getMessageList(params) {
    return await instance
      .post(`${base}/m/message/query`,params)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        //message.error('服务器出错')
      })
  },

    //获取客户列表
    async getClientList(params) {
      return await instance
        .post(`${base}/m/client/query`,params)
        .then((res) => {
          return res.data
        })
        .catch((error) => {
          //message.error('服务器出错')
        })
    },
  //删除客户
  async delClient(params) {
    return await instance
      .delete(`${base}/m/client/delete/${params.id}`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        //message.error('服务器出错')
      })
  },
  //增加客户
  async addOrUpdateClient(params) {
    return await instance
      .put(`${base}/m/client/addOrUpdate`, params)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        //message.error('服务器出错')
      })
  },
    //增加客户
    // async addOrUpdateClient(params) {
    //   return await instance
    //     .get(`${base}/c/MiddleClientFile/getList`, params)
    //     .then((res) => {
    //       return res.data
    //     })
    //     .catch((error) => {
    //       //message.error('服务器出错')
    //     })
    // },
}
