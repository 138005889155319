import React, { useEffect, useRef, useState } from "react";

import { Route, Redirect, Link } from "react-router-dom";
import { Table, Row, Col, Form, Input, Button, message } from "antd";
import api from "../../api";
import qs from "qs";
import utils from "../../assets/js/public.js";
import "./tabel.less";

const Quotation = () => {
  const [loading, setLoading] = useState(false);
  const [quotationInfo, setquotationInfo] = useState({});
  const [proDataSource, setProDataSource] = useState([]);
  const [totalPrice, setTotalPrice] = useState("0.00");

  const [form] = Form.useForm();

  const proColumns = [
    {
      title: "项次",
      dataIndex: "",
      width: 100,
      align: "center",
      render: (text, data, index) => {
        return index + 1;
      },
    },
    {
      title: "产品名称",
      dataIndex: "",
      width: 200,
      render: (text, data, index) => {
        return data.productTitle || "-";
      },
    },
    {
      title: "品种规格",
      dataIndex: "",
      render: (text, data, index) => {
        return (
          <div>
            型号：{data.productModel}
            {(data.productConfig &&
              JSON.parse(data.productConfig).map((e) => {
                return (
                  <div>
                    {e.label}: {e.value}
                  </div>
                );
              })) ||
              "-"}
          </div>
        );
      },
    },
    {
      title: "数量",
      dataIndex: "",
      width: 100,
      align: "center",
      render: (text, data, index) => {
        return (data.productNumber && data.productNumber + "PCS") || "-";
      },
    },
    {
      title: "单价（元）",
      dataIndex: "",
      width: 120,
      align: "center",
      render: (text, data, index) => {
        return (data.salesAmount && data.salesAmount.toFixed(2)) || "-";
      },
    },
    {
      title: "总金额（元）",
      dataIndex: "",
      width: 120,
      align: "center",
      render: (text, data, index) => {
        return (
          (data.salesAmount &&
            data.productNumber &&
            (data.salesAmount * data.productNumber).toFixed(2)) ||
          "-"
        );
      },
    },
  ];

  const getQuotationInfo = async () => {
    const orderCode = qs.parse(window.location.search.split("?")[1]).orderCode;
    console.log(orderCode);
    let res = await api.orderGetQuotation({ orderCode });
    console.log(res);
    form.setFieldsValue({
      ...res.data,
    });
    let totalPrice = 0;
    [...res.data.orderProductList].map((e) => {
      totalPrice += e.salesAmount * e.productNumber;
    });
    setTotalPrice(totalPrice.toFixed(2));
    setquotationInfo(res.data);
    setProDataSource([...res.data.orderProductList]);
  };
  
  useEffect(() => {
    getQuotationInfo();
  }, []);

  const onFinish = async (values) => {
    console.log(values);
    const orderCode = qs.parse(window.location.search.split("?")[1]).orderCode;
    let params = {
      id: quotationInfo.id,
      ...values,
      orderCode,
    };
    let res = await api.orderPutQuotation(params);

    if (res.code == 200) {
      api
        .quotationExport({
          orderCode: qs.parse(window.location.search.split("?")[1]).orderCode,
        })
        .then((res) => {
          if (res.code == 200) {
            let btn = document.createElement("a");
            btn.download = `订单编号-${
              qs.parse(window.location.search.split("?")[1]).orderCode
            }-报价单.pdf`;
            btn.href = res.data;
            btn.target = "_blank";
            btn.click();
            btn = null;
          }
        });
      return message.success("保存成功,正在生成单据");
    }
    message.error(res.message);

    console.log(res);
    // console.log(res)
  };

  return (
    <div style={{ width: "60%", margin: "0 auto" }}>
      <Form name="quotation" form={form} initialValues={{}} onFinish={onFinish}>
        <h2 className="text-center">产品报价单</h2>
        <br />
        <br />
        <div className="ant-table ant-table-bordered">
          <div className="ant-table-container">
            <div className="ant-table-content">
              <table className="table">
                <tbody className="ant-table-tbody">
                  <tr className="ant-table-row">
                    <td className="ant-table-cell">客户名称：</td>
                    <td className="ant-table-cell">
                      <Form.Item name="custName" style={{ marginBottom: 0 }}>
                        <Input />
                      </Form.Item>
                    </td>
                    <td className="ant-table-cell">报价编号：</td>
                    <td className="ant-table-cell">
                      <Form.Item name="quotationNo" style={{ marginBottom: 0 }}>
                        <Input />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr className="ant-table-row">
                    <td className="ant-table-cell">地址：</td>
                    <td className="ant-table-cell">
                      <Form.Item name="addr" style={{ marginBottom: 0 }}>
                        <Input />
                      </Form.Item>
                    </td>
                    <td className="ant-table-cell">报价日期：</td>
                    <td className="ant-table-cell">
                      <Form.Item
                        name="quotationDate"
                        style={{ marginBottom: 0 }}
                      >
                        <Input />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr className="ant-table-row">
                    <td className="ant-table-cell">联系人：</td>
                    <td className="ant-table-cell">
                      <Form.Item name="contacts1" style={{ marginBottom: 0 }}>
                        <Input />
                      </Form.Item>
                    </td>
                    <td className="ant-table-cell">联系人：</td>
                    <td className="ant-table-cell">
                      <Form.Item name="contacts2" style={{ marginBottom: 0 }}>
                        <Input />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr className="ant-table-row">
                    <td className="ant-table-cell">电话：</td>
                    <td className="ant-table-cell">
                      <Form.Item name="tel1" style={{ marginBottom: 0 }}>
                        <Input />
                      </Form.Item>
                    </td>
                    <td className="ant-table-cell">电话：</td>
                    <td className="ant-table-cell">
                      <Form.Item name="tel2" style={{ marginBottom: 0 }}>
                        <Input />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr className="ant-table-row">
                    <td className="ant-table-cell">传真：</td>
                    <td className="ant-table-cell">
                      <Form.Item name="fax1" style={{ marginBottom: 0 }}>
                        <Input />
                      </Form.Item>
                    </td>
                    <td className="ant-table-cell">传真：</td>
                    <td className="ant-table-cell">
                      <Form.Item name="fax2" style={{ marginBottom: 0 }}>
                        <Input />
                      </Form.Item>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <br />
        <br />
        <p>产品名称、型号、数量及价款：</p>
        <Table
          columns={proColumns}
          dataSource={proDataSource}
          pagination={false}
          bordered
          summary={() => (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={6}>
                  <Form.Item name="remark" style={{ marginBottom: 0 }}>
                    <Input placeholder="备注" />
                  </Form.Item>
                </Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={6}>
                  <div
                    className="d-flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <b />
                    <b>RMB：{totalPrice}</b>
                  </div>
                </Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={1} rowSpan={4}>
                  <b>报价说明</b>
                </Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={2}>
                  <Form.Item name="quotationDesc1" style={{ marginBottom: 0 }}>
                    <Input />
                  </Form.Item>
                </Table.Summary.Cell>
                <Table.Summary.Cell colSpan={3}>
                  <Form.Item name="quotationDesc2" style={{ marginBottom: 0 }}>
                    <Input />
                  </Form.Item>
                </Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={2}>
                  <Form.Item name="quotationDesc3" style={{ marginBottom: 0 }}>
                    <Input />
                  </Form.Item>
                </Table.Summary.Cell>
                <Table.Summary.Cell colSpan={3}>
                  <Form.Item name="quotationDesc4" style={{ marginBottom: 0 }}>
                    <Input />
                  </Form.Item>
                </Table.Summary.Cell>
              </Table.Summary.Row>
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={2}>
                  <Form.Item name="quotationDesc5" style={{ marginBottom: 0 }}>
                    <Input />
                  </Form.Item>
                </Table.Summary.Cell>
                <Table.Summary.Cell colSpan={3}>
                  <Form.Item name="quotationDesc6" style={{ marginBottom: 0 }}>
                    <Input />
                  </Form.Item>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
        />
        <br />
        <br />
        <div className="text-center">
          <Button
            className="mr-3"
            onClick={() => {
              // window.history.go(-1);
              // window.open("/index/order/contract");
              window.close()
            }}
          >
            返回
          </Button>
          <Button className="mr-3" htmlType="submit" type="primary">
            提交
          </Button>
          <Button
            className="mr-3"
            onClick={() => {
              api
                .quotationExport({
                  orderCode: qs.parse(window.location.search.split("?")[1])
                    .orderCode,
                })
                .then((res) => {
                  if (res.code == 200) {
                    let btn = document.createElement("a");
                    btn.download = `订单编号-${
                      qs.parse(window.location.search.split("?")[1]).orderCode
                    }-报价单.pdf`;
                    btn.href = res.data;
                    btn.target = "_blank";
                    btn.click();
                    btn = null;
                  }
                });
            }}
          >
            导出
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default Quotation;
