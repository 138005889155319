import instance from "./instance.js";
import { base } from "./config.js";
import { message } from "antd";
export default {
  //系统信息
  async systemServer() {
    return await instance
      .get(`${base}/m/system/server`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  //删除
  async sysMemberNumber_delete(params) {
    return await instance
      .post(`${base}/sysMemberNumber/delete/${params}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  //查询所有
  async sysMemberNumber_query(params) {
    return await instance
      .post(`${base}/sysMemberNumber/querySysMemberNumber`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  async sysMemberNumber_update(params) {
    return await instance
      .post(`${base}/sysMemberNumber/update`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  // 邮豆数量管理接口
  // 创建
  async systemBeans_create(params) {
    return await instance
      .post(`${base}/systemBeans/create`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  // 创建
  async systemBeans_query(params) {
    return await instance
      .post(`${base}/systemBeans/querySysBeans`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  // 修改
  async systemBeans_update(params) {
    return await instance
      .post(`${base}/systemBeans/update`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  // 获取协议信息
  async getDealInfo(params) {
    return await instance
      .post(`${base}/sysProtocolManagement/querySysProtocolManagement`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  // 修改协议
  async updateDealInfo(params) {
    return await instance
      .post(`${base}/sysProtocolManagement/update`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
};
