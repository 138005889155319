import React from 'react';
import { Table, Switch, Button, message, Modal, Popconfirm, Form, Input, InputNumber } from 'antd';
import { PlusOutlined } from '@ant-design/icons'

import api from '../../api';
import utils from '../../assets/js/public';

const layout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 6,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 6,
        span: 6,
    },
};
// const data = [
//     {
//         key: '1',
//         name: 'John Brown',
//         age: 32,
//         address: 'New York No. 1 Lake Park',
//         tags: ['nice', 'developer'],
//     },
//     {
//         key: '2',
//         name: 'Jim Green',
//         age: 42,
//         address: 'London No. 1 Lake Park',
//         tags: ['loser'],
//     }
// ];

class MemberList extends React.Component {
    state = {
        loading: false,
        loadingCheck: false,
        visible: false,
        modalIndex: 1,
        dataMap: [],
        setDataValues: {},
        updataValues: {}

    }
    componentWillUnmount() {
        this.setState = () => false;
    }
    componentDidMount = () => {
        this.getList()
    }
    //获取列表
    getList = () => {
        this.setState({ loading: true })
        utils.try_catch(async () => {
            let res = await api.sysMemberNumber_query();
            let dataMap=[]
            if (res.code === 200) {
                dataMap = utils.ArrayObjSort(res.dataMap, 'monthNumber', 'a-b');
                this.setState({ dataMap })
            } else {
                message.error(res.message);
            }
        })
        this.setState({ loading: false })
    }
    //修改推广
    onChange = (id, checked) => {
        this.setState({ loading: true })
        // console.log(checked,id);
        let ischeck = checked == true ? '2' : '1';
        utils.try_catch(async () => {
            let res = await api.sysMemberNumber_update({ id: id, limitedPopularize: ischeck });
            if (res.code === 200) {
                message.success(ischeck === "1" ? '已关闭' : '已开启');
            } else {
                message.error(res.message);
            }
        })
        this.setState({ loading: false })
    }
    //删除
    confirm = (id) => {
        this.setState({ loading: true })
        utils.try_catch(async () => {
            let res = await api.sysMemberNumber_delete(id);
            if (res.code === 200) {
                message.success('删除成功');
                let dataMapS = [];
                this.state.dataMap.map((item, index) => {
                    if (item.id !== id) {
                        dataMapS.push(item);
                    }
                })
                this.setState({ dataMap: dataMapS })
            } else {
                message.error(res.message);
            }
        })
        this.setState({ loading: false })
    }

    //新增
    setdata = (values) => {
        this.setState({ loading: true })
        utils.try_catch(async () => {
            let res = await api.sysMemberNumber_create(values);
            if (res.code === 200) {
                message.success('新增成功');
                this.setState({
                    visible: false
                });
                this.getList()
            } else {
                message.error(res.message);
            }
        })
        this.setState({ loading: false })
    }
    //修改
    updataf = (values) => {
        this.setState({ loading: true })
        let v= {id:this.state.updataValues.id};
        values = Object.assign(values,v);
        utils.try_catch(async () => {
            let res = await api.sysMemberNumber_update(values);
            if (res.code === 200) {
                message.success('修改成功');
                this.setState({
                    visible: false,
                    updataValues:{}
                });
                this.getList()
            } else {
                message.error(res.message);
            }
        })
        this.setState({ loading: false })
    }

    // 模态框
    showModal = (index, record) => {
        this.setState({
            visible: true,
            modalIndex: index,
            updataValues:record
        });
    };
    handleOk = e => {
        console.log(e);
    };
    handleCancel = e => {
        console.log(e);
        this.setState({
            visible: false,
        });
    };
    render() {
        const { loading, loadingCheck, dataMap, updataValues } = this.state;

        const columns = [
            {
                title: '序号',
                dataIndex: 'id',
                key: 'id',
                width: 100,
                render: (text, record, index) => (
                    <div>
                        {/* {console.log(text,record, index)} */}
                        {index + 1}
                    </div>

                )
            },
            {
                title: '月份',
                dataIndex: 'monthNumber',
                key: 'monthNumber',
                width: 100,
                render: text => <span>{text}个月</span>,
            },
            {
                title: '实际价格',
                dataIndex: 'originalPrice',
                key: 'originalPrice',
                width: 150,
                render: text => <span>￥{text}</span>,
            },
            {
                title: '优惠价格',
                dataIndex: 'specialPrice',
                key: 'specialPrice',
                width: 150,
                render: text => <span>￥{text}</span>,
            },
            {
                title: '新增时间',
                dataIndex: 'creatTime',
                key: 'creatTime',
                width: 240,
                render: text => <span>{utils.timeTrans(text, true)}</span>,
            },
            {
                title: '修改时间',
                dataIndex: 'updateTime',
                key: 'updateTime',
                width: 240,
                render: text => <span>{utils.timeTrans(text, true)}</span>,
            },
            {
                title: '是否推广',
                dataIndex: 'limitedPopularize',
                key: 'limitedPopularize',
                width: 240,
                render: (text, record) => {
                    let ischeck = record.limitedPopularize === '2' ? true : false;
                    return (
                        <div>
                            < Switch checkedChildren="开启" unCheckedChildren="关闭" defaultChecked={ischeck} onChange={this.onChange.bind(this, record.id)} loading={loadingCheck} />
                        </div>
                    )
                }
                ,

            },
            {
                title: '操作',
                key: 'index',
                width: 240,
                fixed: 'right',
                render: (text, record) => (
                    <div>
                        {/* record.id */}

                        <Button size="small" type="Default" className="mr-1" onClick={this.showModal.bind(this, 2, record)} >修改</Button>
                        <Popconfirm
                            title="是确定要删除?删除后无法恢复."
                            onConfirm={this.confirm.bind(this, record.id)}
                            onCancel={this.cancel}
                            okText="确定"
                            cancelText="取消"
                        >
                            <Button size="small" type="danger" className="mr-1">删除</Button>
                        </Popconfirm>

                    </div>

                ),
            },
        ];
        //新增
        const onFinish = values => {
            console.log('Success:', values);
            if (this.state.modalIndex == 1){
                this.setdata(values)
            }else{
                this.updataf(values)
            }
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };
        return (
            <div>
                <p>飞卓会员充值月数和金额</p>
                <Button size="defult" onClick={this.showModal.bind(this, 1)} type="primary" className="mr-1 mb-2" icon={<PlusOutlined />}>新增会员价格数量</Button>
                <div>
                    <Table
                        rowKey={record => record.id}
                        columns={columns}
                        loading={loading}
                        dataSource={dataMap}
                        scroll={{ x: 1500 }} sticky
                    />
                </div>
                {this.state.visible && <Modal
                    title={this.state.modalIndex === 1 ? '新增' : '修改'}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    okText="确定"
                    cancelText="取消"
                    footer={null}
                >
                    <Form
                        {...layout}
                        name="basic"
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <Form.Item
                            label="月数"
                            name="monthNumber"
                            rules={[
                                {
                                    required: true,
                                    message: '请输入月数',
                                }]}
                            initialValue={updataValues.monthNumber || 0}
                        >
                            <InputNumber min={1} />
                        </Form.Item>

                        <Form.Item
                            label="原价"
                            name="originalPrice"
                            rules={[{
                                required: true,
                                message: '请输入原价金额',
                            },]}
                            initialValue={updataValues.originalPrice || 0}
                        >
                            <InputNumber formatter={value => `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} />
                        </Form.Item>
                        <Form.Item
                            label="特价"
                            name="specialPrice"
                            rules={[{
                                required: true,
                                message: '请输入特价金额',
                            }]}

                            initialValue={updataValues.specialPrice || 0}
                        >
                            <InputNumber formatter={value => `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} />
                        </Form.Item>
                        <Form.Item {...tailLayout}>
                            <Button type="primary" htmlType="submit">提交</Button>
                        </Form.Item>
                    </Form>
                </Modal>
        }
            </div>
        )
    }
}
export default MemberList;
