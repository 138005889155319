import React from 'react'
import { Form, Upload } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import './index.less'
/**
 * @prop config 属性设置
 */
export default class TempPicture extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        let { config } = this.props;
        return (
            <Form.Item label={config.title} name={config.field} >
                <Upload {...config.props} listType="picture-card" name='file'>
                    <div>
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>上传图片</div>
                    </div>
                </Upload>
            </Form.Item>
        )
    }
}
