import React from "react";

import { Switch, Route, Redirect, Link } from "react-router-dom";
import { Spin, Form, Input, Button, Card, Row, Col } from "antd";
import { AppstoreOutlined } from "@ant-design/icons";
import api from "../../api";

class Info extends React.Component {
  state = {
    systemInfo: {},
  };

  componentDidMount = () => {
    this.getSystemInfo();
  };

  getSystemInfo = async () => {
    const result = await api.systemServer();
    if (!result || result.code !== 200) {
      return false;
    }
    console.log(result);
    this.setState({});
    this.setState({
      systemInfo: (result && result.data) || [],
    });

    console.log(this.props.state);
  };

  render() {
    const { systemInfo } = this.state;

    return (
      <div>
        <Row gutter={30}>
          <Col>
            <Card
              title="Cpu信息"
              bordered={false}
              style={{ width: 300, display: "inline-block" }}
            >
              {/* <p>:{systemInfo?.cpu?.cpuNum}</p> */}
              <p>CPU空闲：{systemInfo?.cpu?.free || "-"}%</p>
              {/* <p>:{systemInfo?.cpu?.sys}</p> */}
              {/* <p>:{systemInfo?.cpu?.total}</p> */}
              {/* <p>:{systemInfo?.cpu?.used}</p> */}
              {/* <p>{systemInfo?.cpu?.wait}</p> */}
            </Card>
          </Col>
          <Col>
            <Card
              title="内存信息"
              bordered={false}
              style={{ width: 300, display: "inline-block" }}
            >
              <p>内存空闲：{systemInfo?.mem?.free || "-"}GB</p>
              <p>总内存：{systemInfo?.mem?.total || "-"}GB</p>
              <p>使用百分比：{systemInfo?.mem?.usage || "-"}%</p>
              <p>已使用：{systemInfo?.mem?.used || "-"}GB</p>
            </Card>
          </Col>
          <Col>
            <Card
              title="系统信息"
              bordered={false}
              style={{ width: 300, display: "inline-block" }}
            >
              <p>内网IP：{systemInfo?.sys?.computerIp || "-"}</p>
              <p>计算机：{systemInfo?.sys?.computerName || "-"}</p>
              <p>架构：{systemInfo?.sys?.osArch || "-"}</p>
              <p>操作系统：{systemInfo?.sys?.osName || "-"}</p>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
export default Info;
