import React from "react";
import { Switch, Route, Redirect, Link } from "react-router-dom";
import { Spin, Layout, Menu } from "antd";
import {
  AppstoreOutlined,
  UserOutlined,
  AccountBookOutlined,
  AppstoreAddOutlined,
  ExceptionOutlined,
  GlobalOutlined,
  MessageOutlined,
  ShareAltOutlined,
  CustomerServiceOutlined,
} from "@ant-design/icons";
import cookie from "react-cookies";

import api from "../api";
import "./index.less";
import Mhead from "../components/header";

//引入首页所需链接;
import Overview from "./index/overview";

//用户管理
import User from "./user/list";
//角色管理
import Rules from "./user/rules";
//客户管理
import client from "./user/client";
//产品管理
import Product from "./product/list";
import ProductClassify from "./product/classify";
import ProductClassifyConfig from "./product/classifyConfig";
import ProductClassifyConfigValue from "./product/classifyConfigValue";

//订单管理
//合同信息
import Contract from "./order/contract";
//业务员报价单
import quote from "./order/quote";
import Details from "./order/details";
import Quotation from "./order/quotation";
import ElectronicContract from "./order/electronicContract";
import ContactList from "./order/contactList";
// 模版管理
import TemplateList from "./template/list";
import TemplateForm from "./template/index";
// 日志管理
import Logs from "./logs/list";
import LoginLogs from "./loginlogs/list";
import CraftLog from "./logs/craftlog"
//消息管理
import Message from "./message/list";
//资源管理
import Resource from "./recource/list";

//系统
import Info from "./system/info";
import Setting from "./system/setting";
import Payment from "./system/payment";
import Email from "./system/email";
import MemberList from "./system/memberlist";
import BeanList from "./system/beanlist";
import RuleList from "./system/rulelist";
import MenuList from "./system/menulist";
import Deal from "./system/deal";
//公共函数
import utils from "../assets/js/public";

const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;

class Index extends React.Component {
  state = {
    loading: false,
    user_data: "",
    mode: "inline",
    theme: "light",
    routerList: [],
    routerListChild: [
      {
        key: "2",
        icon: <AppstoreOutlined style={{ marginRight: "10px" }} />,
        url: "/overview",
        menuName: "概览",
      },
      {
        key: "1",
        url: "/user",
        menuName: "用户管理",
        icon: <UserOutlined style={{ marginRight: "10px" }} />,
        // childCategoryList: [
        //   { key: "1", url: "/operate/notice", menuName: "用户管理" },
        // ],
      },
      {
        key: "2",
        url: "/rules",
        menuName: "角色管理",
        icon: <UserOutlined style={{ marginRight: "10px" }} />,
      },
      {
        key: "99",
        url: "/client",
        menuName: "客户管理",
        icon: <CustomerServiceOutlined style={{ marginRight: "10px" }} />,
      },
      {
        key: "4",
        url: "/product",
        menuName: "产品管理",
        icon: <AppstoreOutlined />,
        childCategoryList: [
          { key: "40", url: "/classify", menuName: "产品类目" },
          { key: "41", url: "/classifyConfig", menuName: "条件配置" },
          { key: "42", url: "/classifyConfigValue", menuName: "条件值配置" },
          { key: "43", url: "/list", menuName: "产品列表" },
          { key: "44", url: "/productDeliveryTime", menuName: "交期配置" },
        ],
      },
      {
        key: "5",
        url: "/order",
        menuName: "订单管理",
        icon: <AccountBookOutlined />,
        childCategoryList: [
          { key: "11", url: "/contract", menuName: "合同信息" },
          { key: "76", url: "/quote", menuName: "业务报价单" },
        ],
      },
      {
        key: "6",
        url: "/model/list",
        menuName: "配置模板",
        icon: <AppstoreAddOutlined style={{ marginRight: "10px" }} />,
        // childCategoryList: [
        //   { key: "61", url: "/list", menuName: "模板列表" },
        //   { key: "62", url: "/edit", menuName: "模板配置" },
        // ],
      },
      {
        key: "7",
        url: "/logs",
        menuName: "日志查看",
        icon: <ExceptionOutlined />,
        childCategoryList: [
          { key: "71", url: "/ctrl", menuName: "操作日志" },
          { key: "72", url: "/login", menuName: "登录日志" },
          { key: "73", url: "/craft", menuName: "联络单日志" },
        ],
      },
      {
        key: "8",
        url: "/message",
        menuName: "消息管理",
        icon: <MessageOutlined style={{ marginRight: "10px" }} />,
      },
      {
        key: "9",
        url: "/resource",
        menuName: "资源管理",
        icon: <ShareAltOutlined style={{ marginRight: "10px" }} />,
      },
      {
        key: "10",
        url: "/system/info",
        menuName: "系统信息",
        icon: <GlobalOutlined style={{ marginRight: "10px" }} />,
        // childCategoryList: [
        //   { key: "10", url: "/finance/index", menuName: "概览" },
        //   { key: "11", url: "/finance/shopdetails", menuName: "商户收支明细" },
        //   { key: "12", url: "/finance/details", menuName: "收支明细" },
        //   { key: "13", url: "/finance/withdraw", menuName: "提现申请" },
        // ],
      },
    ],
    newRouterListChild: [],
    ruleList: [],
  };
  componentWillUnmount() {
    this.setState = () => false;
  }
  async componentDidUpdate() {
    await this.isLogin();
  }

  componentDidMount = async () => {
    await this.isLogin().then(async (e) => {
      if (!e) {
        this.getrouterlist();
      }

      // console.log(cookie.load("userInfo"));
      let res = await api.sysResourceGetUserResource();
      if (res.data && res.data.menu && res.data.menu.length) {
        res.data.menu = [...new Set(res.data.menu)];
        res.data.menu = res.data.menu.sort((a, b) => a.id - b.id);
        // console.log(res.data.menu);
        let allMenu = [...this.state.routerListChild],
          newMenu = [];

        allMenu.map((e, i) => {
          let index = res.data.menu.findIndex(
            (item) => item.path == `/index${e.url}`
          );

          if (index >= 0) {
            // console.log(e);
            newMenu.push({ ...e, childCategoryList: [] });
          }

          if (e.childCategoryList) {
            if (
              res.data.menu &&
              res.data.menu.length &&
              res.data.menu[index]?.children &&
              res.data.menu[index]?.children.length
            ) {
              e.childCategoryList.map((childrenE) => {
                let childrenIndex = res.data.menu[index].children.findIndex(
                  (item) => item.path == `/index${e.url}${childrenE.url}`
                );

                if (childrenIndex >= 0) {
                  newMenu[newMenu.length - 1].childCategoryList.push(childrenE);
                }
              });
            }
          }
        });

        // console.log(allMenu, res.data.menu, newMenu);
        localStorage.setItem("ruleMenu", JSON.stringify(res.data.menu));
        this.setState({
          // routerListChild: newMenu,
          newRouterListChild: newMenu,
        });
      } else {
        localStorage.setItem("ruleMenu", "[]");
        this.setState({
          routerListChild: [],
        });
      }
    });
  };
  async isLogin() {
    if (!cookie.load("accessToken")) {
      cookie.remove("userInfo", { path: "/" });
      cookie.remove("accessToken", { path: "/" });
      this.props.history.push("/login");
      return true;
    }

    // this.setState({
    //   routerListChild: res.data.menu
    // })

    // let res = await api.sysUserQuery()
    // if (res && res.code === 200) {
    //   this.setState(
    //     {
    //       ruleList: res.dataMap
    //     },
    //     () => {
    //       if (!cookie.load('userInfo').isSuperAdmin) {
    //         this.props.history.push('/index/finance/index')
    //       }
    //     }
    //   )
    // }
    // console.log(res)
    return false;
  }
  getUserInfo = async () => {
    let res = await api.getUserInfo();
    // console.log(res)
    if (res.code === 200) {
      this.setState({ user_data: res.dataMap });
    }
  };
  //获取路由列表
  getrouterlist = () => {
    // console.log(utils)
    utils.try_catch(async () => {
      let res = await utils.json("router");
      // console.log(res);
      if (res.code === 200) {
        let routerList = [];
        routerList = utils.ArrayObjSort(res.dataMap, "ordina", "a-b");
        this.setState({ routerList });
      }
    });
  };
  render() {
    return (
      <Spin spinning={this.state.loading}>
        <Layout id="components-layout-demo-responsive">
          <Header
            className="site-layout-sub-header-background"
            style={{ padding: 0 ,textAlign:"center"}}
            // style={{ textAlign: "center" }}
          >
            <Mhead />
          </Header>
          <Layout>
            <Sider
              style={{ zIndex: 999 }}
              collapsedWidth="0"
              onBreakpoint={(broken) => {
                console.log(broken);
              }}
              onCollapse={(collapsed, type) => {
                console.log(collapsed, type);
              }}
            >
              <Menu
                defaultSelectedKeys={["1"]}
                defaultOpenKeys={["sub1"]}
                mode={this.state.mode}
                theme={this.state.theme}
              >
                {this.state.newRouterListChild &&
                  this.state.newRouterListChild.length &&
                  this.state.newRouterListChild.map((item, index) => {
                    if (
                      !item.childCategoryList ||
                      !item.childCategoryList.length
                    ) {
                      return (
                        <Menu.Item key={index + 1}>
                          {item.icon}
                          <Link to={"/index" + item.url}>{item.menuName}</Link>
                        </Menu.Item>
                      );
                    } else {
                      return (
                        <SubMenu
                          key={"sub" + (index + 1)}
                          title={
                            <span>
                              {item.icon}
                              <span>{item.menuName}</span>
                            </span>
                          }
                        >
                          {item.childCategoryList.map((item2, index2) => {
                            return (
                              <Menu.Item key={item2.key}>
                                {(item2.menuType === 1 && (
                                  <Link to={"/index" + item.url + item2.url}>
                                    {item2.menuName}
                                  </Link>
                                )) ||
                                  (item2.menuType === 2 && (
                                    <a href={item2.url} target="_blank">
                                      {item2.menuName}
                                    </a>
                                  )) || (
                                    <Link to={"/index" + item.url + item2.url}>
                                      {item2.menuName}
                                    </Link>
                                  )}
                                {/* <Link to={'/index' + item.url + item2.url}>{item2.menuName}</Link> */}
                              </Menu.Item>
                            );
                          })}
                        </SubMenu>
                      );
                    }
                  })}
              </Menu>
            </Sider>
            <Layout style={{ height: "auto", overflow: "auto" }}>
              <Content style={{ margin: "24px 16px 0", overflow: "auto" }}>
                <div className="site-layout-background" style={{ padding: 24 }}>
                  <Switch>
                    {/* 概览 */}
                    <Route path="/index/overview" component={Overview} />
                    {/* 用户管理 */}
                    <Route path="/index/user" component={User} />
                    {/* 角色管理 */}
                    <Route path="/index/rules" component={Rules} />
                    {/*客户管理 */}
                    <Route path="/index/client" component={client} />
                    {/* 产品管理 */}
                    <Route path="/index/product/list" component={Product} />
                    <Route
                      path="/index/product/classify"
                      component={ProductClassify}
                    />
                    <Route
                      path="/index/product/classifyConfig"
                      component={ProductClassifyConfig}
                    />
                    <Route
                      path="/index/product/classifyConfigValue"
                      component={ProductClassifyConfigValue}
                    />
                    {/* 订单管理 */}
                    <Route path="/index/order/contract" component={Contract} />
                    //业务员报价单
                    <Route path="/index/order/quote" component={quote} />
                    <Route path="/index/order/details" component={Details} />
                    <Route
                      path="/index/order/quotation"
                      component={Quotation}
                    />
                    <Route
                      path="/index/order/electronicContract"
                      component={ElectronicContract}
                    />
                    <Route
                      path="/index/order/contactList"
                      component={ContactList}
                    />
                    {/* 模版管理 */}
                    <Route path="/index/model/list" component={TemplateList} />
                    <Route path="/index/model/edit" component={TemplateForm} />
                    {/* 日志管理 */}

                    {/* 操作日志 */}
                    <Route path="/index/logs/ctrl" component={Logs} />
                    {/* 登录日志 */}
                    <Route path="/index/logs/login" component={LoginLogs} />
                    {/* 联络单日志 */}
                    <Route path="/index/logs/craft" component={CraftLog} />
                    {/* 消息管理 */}
                    <Route path="/index/message" component={Message} />
                    {/* 资源管理 */}
                    <Route path="/index/resource" component={Resource} />
                    {/* 系统 */}
                    <Route path="/index/system/info" component={Info} />
                    <Route path="/index/system/setting" component={Setting} />
                    <Route path="/index/system/payment" component={Payment} />
                    <Route path="/index/system/email" component={Email} />
                    <Route path="/index/system/member" component={MemberList} />
                    <Route path="/index/system/bean" component={BeanList} />
                    <Route path="/index/system/rulelist" component={RuleList} />
                    <Route path="/index/system/menulist" component={MenuList} />
                    <Route path="/index/system/deal" component={Deal} />
                    {/* 异常路由重定向 */}
                    <Redirect to={`/index/overview`} />
                  </Switch>
                </div>
              </Content>
              {/* <Footer>
                <Mfoot />
              </Footer> */}
            </Layout>
          </Layout>
        </Layout>
      </Spin>
    );
  }
}

export default Index;
