import React from 'react'
import { Form, Upload, message } from 'antd'
import { InboxOutlined } from '@ant-design/icons'
import api from '../api';
const { Dragger } = Upload;
/**
 * @prop config 属性设置
 */
export default class TempUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fileList: [...props.config.fileList]
        }
    }
    onRemove(file) {
        this.setState(state => {
            const index = state.fileList.indexOf(file);
            const newFileList = state.fileList.slice();
            newFileList.splice(index, 1);
            return {
                fileList: newFileList,
            };
        });
    }
    uploadEvent(fileobj) {
        const { file } = fileobj;
        const formData = new FormData();
        formData.append('file', file);
        api.uploadFile(formData).then(res => {
            if (200 !== res.code || !res.data) {
                return message.error('上传失败');
            }
            let fileList = [...this.state.fileList, {
                uid: file.uid,
                name: file.name,
                status: 'done',
                url: res.data,
            }]
            this.props.saveFile(fileList);
        })
    }
    render() {
        let { config } = this.props;
        let { fileList } = this.state;
        return (
            <Form.Item label={config.title}>
                <Dragger name="file" fileList={fileList} onRemove={file => { this.onRemove(file) }} customRequest={file => { this.uploadEvent(file) }}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">点击或拖拽附件进行上传</p>
                    <p className="ant-upload-hint">支持单文件或目录上传</p>
                </Dragger>
            </Form.Item>
        )
    }
}
