import React from "react";
import { Form, Select } from "antd";
/**
 * @prop config 属性设置
 */
export default class TempSignSelect extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    let { config } = this.props;
    let options = config.options
      .filter((item) => !!item && (item.value || item.label))
      .map((item, index) => {
        item.value = `${item.label}_${index}`;
        return item;
      });
    return (
      <Form.Item label={config.title} name={config.field}>
        <Select
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
          placeholder={`请输入${config.title}`}
        >
          {options.map((item) => (
            <Select.Option key={item.value} value={item.value}>
              {item.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    );
  }
}
