import React from "react";

import { Route, Redirect, Link } from "react-router-dom";
import {
  Table,
  Row,
  Col,
  Button,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Checkbox,
  message,
} from "antd";
import { SearchOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import api from "../../api";
import utils from "../../assets/js/public.js";
const { Option } = Select;
const { TextArea } = Input;
const { confirm } = Modal;

const getRandomuserParams = (params) => {
  console.log(params);
  return {
    pageSize: params.pageSize,
    pageNo: params.pageNo,
  };
};
const CheckboxGroup = Checkbox.Group;

function handleChange(value) {
  console.log(`selected ${value}`);
}
const EditRolesForm = ({ visible, onCreate, onCancel, chooseUserInfo }) => {
  const [form] = Form.useForm();
  const layout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 16,
    },
  };

  form.resetFields();

  return (
    <Modal
      getContainer={false}
      visible={visible}
      title="设置消息信息"
      width={950}
      okText={"保存"}
      cancelText={"取消"}
      onOk={() => {
        // form.submit();
        onCreate(form.getFieldsValue());
        console.log(form.getFieldsValue());
      }}
      onCancel={onCancel}
      destroyOnClose
    >
      <Form
        form={form}
        {...layout}
        name="form_in_modal"
        initialValues={chooseUserInfo}
      >
        <div>
          <Row gutter={30}>
            <Form.Item name="id">
              <Input type="hidden" value={chooseUserInfo.id} disabled />
            </Form.Item>
            <Col span={24}>
              <Form.Item name="title" label="消息标题">
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="titleEn" label="消息英文标题">
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="content" label="消息内容">
                <TextArea rows={5} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="contentEn" label="消息英文内容">
                <TextArea rows={5} />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
    </Modal>
  );
};

class Userlist extends React.Component {
  state = {
    data: [],
    pagination: {
      pageNo: 1,
      pageSize: 10,
    },
    loading: false,
    showDetail: false,
    showEdit: false,
    chooseUserInfo: {},
  };
  componentWillUnmount() {
    this.setState = () => false;
  }
  componentDidMount() {
    const { pagination } = this.state;
    this.fetch(pagination);
  }
  modalChange(type, info) {
    const _t = this;
    this.setState({ chooseUserInfo: info ? info : {} }, () => {
      _t.setState({
        [type]: !_t.state[type],
      });
    });
  }
  UserChangeStatus() {
    console.log(1);
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.setState(
      {
        pagination: {
          ...pagination,
          pageNo: pagination.current,
        },
      },
      () => {
        this.fetch({
          sortField: sorter.field,
          sortOrder: sorter.order,
          ...pagination,
          pageNo: pagination.current,
          ...filters,
        });
      }
    );
  };

  fetch = async (params = {}) => {
    this.setState({ loading: true });
    let result = await api.getMessageList(params);
    if (!result || result.code !== 200) {
      return false;
    }
    this.setState({
      loading: false,
      data: (result.data && result.data.records) || [],
      pagination: {
        ...this.state.pagination,
        pageNo: (result.data && result.data.pageNo) || 1,
        pageSize: (result.data && result.data.pageSize) || 10,
        total: (result.data && result.data.totalRecords) || 1,
      },
    });
  };

  showDeleteConfirm = (info) => {
    const { pagination } = this.state;
    const _t = this;
    confirm({
      title: "您确定要执行删除操作吗?",
      icon: <ExclamationCircleOutlined />,
      content: "此操作将永久删除用户，是否继续？",
      okText: "是",
      okType: "danger",
      cancelText: "否",
      onOk() {
        api.delMessage(info).then((result) => {
          if (!result || 200 !== result.code) {
            message.error(result.message);
          } else {
            message.success("删除成功");
            _t.fetch({ pagination });
          }
        });
      },
      onCancel() {},
    });
  };

  render() {
    const { data, pagination, loading } = this.state;
    const ruleMenu =
      (
        localStorage.getItem("ruleMenu") &&
        JSON.parse(localStorage.getItem("ruleMenu"))
      ).find((e) => e.path == "/index/message")?.children || [];

    const columns = [
      {
        title: "序号",
        dataIndex: "",
        width: 60,
        key: "no",
        fixed: "left",
        render: (index, data, k) => k + 1,
      },
      {
        title: "消息标题",
        dataIndex: "title",
        width: 150,
        key: "title",
      },
      {
        title: "消息内容",
        dataIndex: "content",
        width: 150,
        key: "content",
      },
      {
        title: "创建时间",
        dataIndex: "createTime",
        width: 200,
        key: "createTime",
      },
      {
        title: "操作",
        width: 150,
        fixed: "right",
        key: "ctrl",
        render: (index, data) => {
          return (
            <div>
              {(ruleMenu &&
                ruleMenu.findIndex((e) => e.path == "/index/message/del") >=
                  0 && (
                  <Button
                    type="primary"
                    danger
                    size="small"
                    className="mr-1"
                    onClick={() => this.showDeleteConfirm(data)}
                  >
                    删除
                  </Button>
                )) ||
                ""}
              {(ruleMenu &&
                ruleMenu.findIndex((e) => e.path == "/index/message/edit") >=
                  0 && (
                  <Button
                    type="primary"
                    size="small"
                    className="mr-1"
                    onClick={() => this.modalChange("showEdit", data)}
                  >
                    修改
                  </Button>
                )) ||
                ""}
            </div>
          );
        },
      },
    ];

    const onEditUser = async (values) => {
      let result = await api.addOrUpdateMessage(values);
      if (!result || result.code !== 200) {
        message.error(result.message);
        return false;
      }
      const { pagination } = this.state;
      this.fetch({ pagination });
      this.setState({ showEdit: false });
    };

    return (
      <div>
        <div className="search-box d-flex mb-3">
          <div className="d-flex align-item-center">
            {/* <div className="mr-1">搜索用户名</div>
            <div className="mr-1">
              <Input prefix={<SearchOutlined />} />
            </div>
            <div className="mr-1">
              <Button type="primary" onClick={() => { }}>查询</Button>
            </div> */}
            <div className="mr-1">
              {(ruleMenu &&
                ruleMenu.findIndex((e) => e.path == "/index/message/add") >=
                  0 && (
                  <Button
                    type="primary"
                    onClick={() => {
                      console.log(this.state.chooseUserInfo);
                      this.setState({ chooseUserInfo: {} });
                      this.modalChange("showEdit", {
                        sex: 1,
                      });
                    }}
                  >
                    添加消息
                  </Button>
                )) ||
                ""}
            </div>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={data}
          pagination={{ ...pagination, showSizeChanger: false }}
          loading={loading}
          onChange={this.handleTableChange}
          size="small"
          sticky
          scroll={{ x: 1500 }}
        />

        {/* 修改用户信息 */}
        {this.state.showEdit && (
          <EditRolesForm
            visible={this.state.showEdit}
            onCreate={onEditUser}
            onCancel={() => {
              this.modalChange("showEdit", false);
            }}
            chooseUserInfo={this.state.chooseUserInfo}
          />
        )}
      </div>
    );
  }
}

export default Userlist;
