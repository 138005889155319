import React from 'react'
import './index.less'
export default class footer extends React.Component {
  render() {
    return (
      <div className="footer text-center">
        飞卓版权所有 2020-2025 <a href="http://beian.miit.gov.cn/">沪ICP备16025352号</a>
      </div>
    )
  }
}
