import instance from "./instance.js";
import { base } from "./config.js";
import { message } from "antd";
export default {
  // 获取登录日志列表信息
  async getLogList(params) {
    return await instance
      .post(`${base}/sysOperationLog/query`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  // 获取登录日志列表信息
  async getLoginLogList(params) {
    return await instance
      .post(`${base}/m/sysLoginLog/query`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  //获取联络单日志列表
  async getLogCraft(params) {
    return await instance
      .post(`${base}/m/sysCraftLog/query`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
  async sendChallenge(params) {
    return await instance
      .post(`${base}/challenge`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error(error.msg || "服务器出错");
      });
  },
};
