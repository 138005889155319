import React from "react";

import { Route, Redirect, Link } from "react-router-dom";
import {
  Table,
  Row,
  Col,
  Button,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Checkbox,
  message,
} from "antd";
import { hashHistory } from "react-router";
import { SearchOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import api from "../../api";
import utils from "../../assets/js/public.js";
import { fromPairs } from "lodash";
import FormItem from "antd/lib/form/FormItem";
import data from "../../assets/js/data.json";
const { Option } = Select;
const { TextArea } = Input;
const { confirm } = Modal;

const getRandomuserParams = (params) => {
  console.log(params);
  return {
    pageSize: params.pageSize,
    pageNo: params.pageNo,
  };
};
const CheckboxGroup = Checkbox.Group;

class Userlist extends React.Component {
  
  state = {
    pagination: {
      pageNo: 1,
      pageSize: 10,
    },
    loading: false,
    showDetail: false,
    showEdit: false,
    chooseUserInfo: {},
  };
  componentWillUnmount() {
    this.setState = () => false;
  }
  componentDidMount() {
    const { pagination } = this.state;
    this.fetch({ ...pagination });
  }
  modalChange(type, info) {
    const _t = this;
    this.setState({ chooseUserInfo: info ? info : {} }, () => {
      _t.setState({
        [type]: !_t.state[type],
      });
    });
  }
  UserChangeStatus() {
    console.log(1);
  }
  handleTableChange = (pagination, sorter) => {
    const {filters} = this.state
    this.fetch({
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...pagination,
      pageNo: pagination.current,
      filters:{...filters},
    });
  };
  
  fetch = async (params = {}) => {
    this.setState({ loading: true });
    let data = await api.configTemplateGetList({
      ...params.pagination,
      ...params.filters,
      ...params,
    });
    if (!data || data.code !== 200) {
      return false;
    }
    this.setState({
      loading: false,
      data: (data.data && data.data.records) || [],
      pagination: {
        pageNo: (data.data && data.data.pageNo) || 1,
        pageSize: (data.data && data.data.pageSize) || 10,
        total: (data.data && data.data.totalRecords) || 1,
      },
      filters: {...params.filters}
    });
  };
  
  SearchOutlined(e) {
    // if (e.which !== 13) return
   const {filters} = this.state
   this.setState({loading:true})
   api.configTemplateGetList({
      pageNo :1,
      pageSize: 10,
      total:  1,
      title :e.target.value,
      filters:{...filters},
    }).then(data =>{
      this.setState({
        loading: false,
        data: (data.data && data.data.records) || [],
        pagination: {
          pageNo: (data.data && data.data.pageNo) || 1,
          pageSize: (data.data && data.data.pageSize) || 10,
          total: (data.data && data.data.totalRecords) || 1,
        },
        filters: {...e.filters}
      });
    })
    

  }
  copy = (info) => {
    let _t = this;
    confirm({
      title: "您确定要执行复制操作吗？",
      okText: "是",
      cancelText: "否",
      async onOk() {
        let result = await api.configTemplateCopyTemplate(info);
        if (result?.code == 200) {
          message.success("复制成功！", () => {
            const { pagination } = _t.state;
            _t.fetch({ ...pagination });
          });
        }
      },
    });
  };

  showDeleteConfirm = (info) => {
    const { pagination } = this.state;
    const _t = this;
    confirm({
      title: "您确定要执行删除操作吗?",
      icon: <ExclamationCircleOutlined />,
      content: "此操作将永久删除，是否继续？",
      okText: "是",
      okType: "danger",
      cancelText: "否",
      onOk() {
        api.configTemplateDel(info).then((result) => {
          if (!result || 200 !== result.code) {
            message.error(result.message);
          } else {
            message.success("删除成功");
            _t.fetch({ ...pagination });
          }
        });
      },
      onCancel() {},
    });
  };
  formRef = React.createRef();
  render() {
    
    const { data, pagination, loading } = this.state;
    const ruleMenu =
      (
        localStorage.getItem("ruleMenu") &&
        JSON.parse(localStorage.getItem("ruleMenu"))
      ).find((e) => e.path == "/index/model/list")?.children || [];

    const columns = [
      {
        title: "模版ID",
        dataIndex: "id",
        width: 60,
        key: "id",
        fixed: "left",
      },
      {
        title: "模版名称",
        dataIndex: "title",
        width: 150,
        key: "title",
      },
      {
        title: "创建时间",
        dataIndex: "createTime",
        width: 200,
        key: "createTime",
      },
      {
        title: "操作",
        width: 150,
        fixed: "right",
        key: "ctrl",
        render: (index, data) => {
          return (
            
            <div>
              
              {(ruleMenu &&
                ruleMenu.findIndex((e) => e.path == "/index/model/list/del") >=
                  0 && (
                  <Button
                    type="primary"
                    danger
                    size="small"
                    className="mr-1"
                    onClick={() => this.showDeleteConfirm(data)}
                  >
                    删除
                  </Button>
                )) ||
                ""}
              {(ruleMenu &&
                ruleMenu.findIndex((e) => e.path == "/index/model/list/edit") >=
                  0 && (
                  <Button
                    type="primary"
                    size="small"
                    className="mr-1"
                    // onClick={() => this.modalChange("showEdit", data)}
                    onClick={() => {
                      console.log(this.props.history.push);
                      // this.props.history.push({
                      //   pathname: "/index/model/edit",
                      //   search: "id=" + data.id,
                      // });
                      window.open("/index/model/edit?id=" + data.id)
                    }}
                  >
                    编辑
                  </Button>
                )) ||
                ""}
              {(ruleMenu &&
                ruleMenu.findIndex((e) => e.path == "/index/model/list/copy") >=
                  0 && (
                  <Button
                    type="primary"
                    size="small"
                    className="mr-1"
                    onClick={() => this.copy(data)}
                  >
                    复制
                  </Button>
                )) ||
                ""}
            </div>
          );
        },
      },
    ];
    return (
      <div>
        <Form
          className="search-box d-flex mb-3"
          name="product-form"
          layout="inline"
          ref={this.formRef}
        >
        <div className="search-box d-flex mb-3">
          <div className="d-flex align-item-center">
            <div className="mr-1">
              {(ruleMenu &&
                ruleMenu.findIndex((e) => e.path == "/index/model/list/add") >=
                  0 && (
                  <Button
                    type="primary"
                    onClick={() => {
                      this.props.history.push({
                        pathname: "/index/model/edit",
                      });
                    }}
                  >
                    添加模板
                  </Button>
                )) ||
                ""}
            </div>
          <Form.Item name='title'  label="搜索模板：">
          <Input onPressEnter={this.SearchOutlined.bind(this)} />
          </Form.Item>
          <Form.Item className="ml-auto d-flex">
            <Button
              type="primary"
              className="mr-1"
              onClick={() => {
                let params = { ...this.formRef.current.getFieldsValue() };
                if (!params.userId) {
                  delete params.userId;
                }
                const { pagination } = this.state;
                console.log(pagination);
                pagination.pageNo = 1;
                this.fetch({ pagination, filters: params });
              }}
            >
              查询
            </Button>
          </Form.Item>
          </div>
        </div>
        </Form>
        <Table
          columns={columns}
          dataSource={data}
          pagination={{...pagination, showSizeChanger: false}}
          loading={loading}
          onChange={this.handleTableChange}
          size="small"
          sticky
          scroll={{ x: 1500 }}
        />
      </div>
    );
  }
}

export default Userlist;
