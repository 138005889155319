import React from 'react'
import { Form, Input } from 'antd'
import './index.less'
/**
 * @prop config 属性设置
 */
export default class TempInput extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        let { config } = this.props;
        return (
            <Form.Item label={config.title} name={config.field}>
                <Input placeholder={`请输入${config.title}`} />
            </Form.Item>
        )
    }
}
