import React, { useEffect, useState } from "react";

import { Route, Redirect, Link } from "react-router-dom";
import {
  Table,
  Row,
  Col,
  Button,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Checkbox,
  message,
  Divider,
} from "antd";
import { SearchOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import api from "../../api";
import utils from "../../assets/js/public.js";
const { Option } = Select;
const { confirm } = Modal;

const getRandomuserParams = (params) => {
  console.log(params);
  return {
    pageSize: params.pageSize,
    pageNo: params.pageNo,
  };
};
const CheckboxGroup = Checkbox.Group;

function handleChange(value) {
  console.log(`selected ${value}`);
}
const EditRolesForm = ({ visible, onCreate, onCancel, chooseUserInfo }) => {
  const [form] = Form.useForm();
  const layout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 16,
    },
  };

  const [current, setCurrent] = React.useState(0);
  const [rolesArr, setRolesArr] = React.useState([]);

  const [resourceArrs, setResourceArrs] = useState([]);
  const [hasResourceArrs, setHasResourceArrs] = useState([]);

  React.useEffect(() => {
    form.resetFields();
    setCurrent(0);
    api.sysResourceQueryAll().then((res) => {
      if (res.code == 200) {
        setResourceArrs(res.data);
      }
    });
    if (chooseUserInfo.id) {
      api.queryResourceByRoleId(chooseUserInfo).then((res) => {
        if (res.code == 200) {
          let has = [];
          res.data.map((e) => {
            if (e) {
              has.push(e.id);
              if (e.children && e.children.length) {
                e.children.map((c) => {
                  if (c) {
                    has.push(c.id);
                  }
                });
              }
            }
          });
          form.setFieldsValue({
            sysResourceIdList: has,
          });
          setHasResourceArrs(res.data);
        }
      });
    }
  }, [chooseUserInfo, visible]);

  const setCurrentEvent = () => {
    if (current === 1) {
      form
        .validateFields()
        .then((values) => {
          onCreate(values);
        })
        .catch((info) => {
          console.log("Validate Failed:", info);
        });
    } else {
      setCurrent(1);
    }
  };

  let okText = "下一步";
  let cancelText = "取消";
  if (current === 1) {
    okText = "保存";
    cancelText = "上一步";
  }
  const cancelEvent = () => {
    if (current === 1) {
      setCurrent(0);
    } else {
      onCancel();
    }
  };

  return (
    <Modal
      getContainer={false}
      visible={visible}
      title="设置角色信息"
      width={950}
      okText={okText}
      cancelText={cancelText}
      onOk={() => {
        setCurrentEvent();
      }}
      onCancel={() => {
        cancelEvent();
      }}
      // onOk={() => {
      //   // form.submit();
      //   onCreate(form.getFieldsValue());
      //   console.log(form.getFieldsValue());
      // }}
      // onCancel={onCancel}
      destroyOnClose
    >
      <Form
        form={form}
        {...layout}
        name="form_in_modal"
        initialValues={chooseUserInfo}
      >
        <div
          className="stap1"
          style={{ display: current === 0 ? "block" : "none" }}
        >
          <Row gutter={30}>
            <Form.Item name="id">
              <Input type="hidden" value={chooseUserInfo.id} disabled />
            </Form.Item>
            <Col span={12}>
              <Form.Item name="roleName" label="角色名称">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="roleCode" label="角色编码">
                <Input disabled={!!chooseUserInfo.roleCode} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="discount" label="角色折扣">
                <InputNumber max={100} />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div
          className="stap2"
          style={{ display: current === 1 ? "block" : "none" }}
        >
          <Form.Item
            name="sysResourceIdList"
            labelCol={{ span: 0 }}
            wrapperCol={{ span: 24 }}
          >
            <Checkbox.Group style={{ width: "100%" }}>
              {resourceArrs &&
                resourceArrs.length &&
                resourceArrs.map((e, i) => {
                  return (
                    <div style={{ width: "100%" }} ket={i}>
                      <Row gutter={30} key={i}>
                        <Col span={4}>
                          <Checkbox value={e.id}>{e.resourceName}</Checkbox>
                        </Col>
                        <Col span={20}>
                          {(e.children &&
                            e.children.length &&
                            e.children.map((c) => {
                              return (
                                <Checkbox value={c.id}>
                                  {c.resourceName}
                                </Checkbox>
                              );
                            })) ||
                            ""}
                        </Col>
                      </Row>
                      <Divider />
                    </div>
                  );
                })}
            </Checkbox.Group>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

class Userlist extends React.Component {
  state = {
    data: [],
    pagination: {
      pageNo: 1,
      pageSize: 10,
    },
    loading: false,
    showDetail: false,
    showEdit: false,
    chooseUserInfo: {},
  };
  componentWillUnmount() {
    this.setState = () => false;
  }
  componentDidMount() {
    const { pagination } = this.state;
    this.fetch(pagination);
  }
  modalChange(type, info) {
    const _t = this;
    this.setState({ chooseUserInfo: info ? info : {} }, () => {
      _t.setState({
        [type]: !_t.state[type],
      });
    });
  }
  UserChangeStatus() {
    console.log(1);
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.setState(
      {
        pagination: {
          ...pagination,
          pageNo: pagination.current,
        },
      },
      () => {
        this.fetch({
          sortField: sorter.field,
          sortOrder: sorter.order,
          ...pagination,
          pageNo: pagination.current,
          ...filters,
        });
      }
    );
  };

  fetch = async (params = {}) => {
    this.setState({ loading: true });
    let result = await api.getRoleList(params);
    if (!result || result.code !== 200) {
      return false;
    }
    this.setState({
      loading: false,
      data: (result.data && result.data.records) || [],
      pagination: {
        ...this.state.pagination,
        pageNo: (result.data && result.data.pageNo) || 1,
        pageSize: (result.data && result.data.pageSize) || 10,
        total: (result.data && result.data.totalRecords) || 1,
      },
    });
  };

  showDeleteConfirm = (info) => {
    const { pagination } = this.state;
    const _t = this;
    confirm({
      title: "您确定要执行删除操作吗?",
      icon: <ExclamationCircleOutlined />,
      content: "此操作将永久删除用户，是否继续？",
      okText: "是",
      okType: "danger",
      cancelText: "否",
      onOk() {
        api.delRoles(info).then((result) => {
          if (!result || 200 !== result.code) {
            message.error(result.message);
          } else {
            message.success("删除成功");
            _t.fetch({ pagination });
          }
        });
      },
      onCancel() {},
    });
  };

  render() {
    const { data, pagination, loading } = this.state;
    const ruleMenu =
      (
        localStorage.getItem("ruleMenu") &&
        JSON.parse(localStorage.getItem("ruleMenu"))
      ).find((e) => e.path == "/index/rules")?.children || [];

    const columns = [
      {
        title: "序号",
        dataIndex: "",
        width: 60,
        key: "no",
        fixed: "left",
        render: (index, data, k) => k + 1,
      },
      {
        title: "角色名称",
        dataIndex: "roleName",
        width: 150,
        key: "roleName",
      },
      {
        title: "角色编码",
        dataIndex: "roleCode",
        width: 150,
        key: "roleCode",
      },
      {
        title: "折扣",
        dataIndex: "discount",
        width: 200,
        key: "discount",
      },
      {
        title: "创建时间",
        dataIndex: "createTime",
        width: 200,
        key: "createTime",
      },
      {
        title: "操作",
        width: 150,
        fixed: "right",
        key: "ctrl",
        render: (index, data) => {
          return (
            <div>
              {(ruleMenu &&
                ruleMenu.findIndex((e) => e.path == "/index/rules/del") >=
                  0 && (
                  <Button
                    type="primary"
                    danger
                    size="small"
                    className="mr-1"
                    onClick={() => this.showDeleteConfirm(data)}
                  >
                    删除
                  </Button>
                )) ||
                ""}
              {(ruleMenu &&
                ruleMenu.findIndex((e) => e.path == "/index/rules/edit") >=
                  0 && (
                  <Button
                    type="primary"
                    size="small"
                    className="mr-1"
                    onClick={() => this.modalChange("showEdit", data)}
                  >
                    修改
                  </Button>
                )) ||
                ""}
            </div>
          );
        },
      },
    ];

    const onEditUser = async (values) => {
      let result = await api.RolesAddOrUpdate(values);
      if (!result || result.code !== 200) {
        message.error(result.message);
        return false;
      }

      if (values.id) {
        let formData = new FormData();

        formData.append("roleId", values.id);
        values.sysResourceIdList.map((e, i) => {
          formData.append(`resourceIds`, e);
        });
        // {
        //   roleId: values.roleId,
        //   resourceIds: values.sysResourceIdList.join(","),
        // }
        let result2 = await api.sysRoleDoAssign(formData);
      }

      const { pagination } = this.state;
      this.fetch({ pagination });
      this.setState({ showEdit: false });
    };

    return (
      <div>
        <div className="search-box d-flex mb-3">
          <div className="d-flex align-item-center">
            {/* <div className="mr-1">搜索用户名</div>
            <div className="mr-1">
              <Input prefix={<SearchOutlined />} />
            </div>
            <div className="mr-1">
              <Button type="primary" onClick={() => { }}>查询</Button>
            </div> */}
            <div className="mr-1">
              {(ruleMenu &&
                ruleMenu.findIndex((e) => e.path == "/index/rules/add") >=
                  0 && (
                  <Button
                    type="primary"
                    onClick={() => {
                      console.log(this.state.chooseUserInfo);
                      this.setState({ chooseUserInfo: {} });
                      this.modalChange("showEdit", {
                        sex: 1,
                      });
                    }}
                  >
                    添加角色
                  </Button>
                )) ||
                ""}
            </div>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={data}
          pagination={{ ...pagination, showSizeChanger: false }}
          loading={loading}
          onChange={this.handleTableChange}
          size="small"
          sticky
          scroll={{ x: 1500 }}
        />

        {/* 修改用户信息 */}
        {this.state.showEdit && (
          <EditRolesForm
            visible={this.state.showEdit}
            onCreate={onEditUser}
            onCancel={() => {
              this.modalChange("showEdit", false);
            }}
            chooseUserInfo={this.state.chooseUserInfo}
          />
        )}
      </div>
    );
  }
}

export default Userlist;
