import instance from './instance.js'
import { base, mock } from './config.js'
import cookie from 'react-cookies'
import qs from 'qs'
export default {
  //创建公告
  async createNotice(params) {
    return await instance
      .post(`${base}/notice/create`, params)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        //message.error('服务器出错')
      })
  },
  //获取公告列表
  async getNotice(params) {
    return await instance
      .post(`${base}/notice/queryList`, params)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        //message.error('服务器出错')
      })
  },
  //删除公告
  async delNotice(params) {
    return await instance
      .post(`${base}/notice/delete/${params.id}`)
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        //message.error('服务器出错')
      })
  }
}
