import React, { useState, useEffect } from "react";

import { Route, Redirect, Link } from "react-router-dom";
import {
  Spin,
  Table,
  Row,
  Col,
  Button,
  Modal,
  Form,
  Input,
  Radio,
  Select,
  Switch,
  Checkbox,
  Divider,
  message,
} from "antd";
import { SearchOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import api from "../../api";
import utils from "../../assets/js/public.js";
const { Option } = Select;
const { confirm } = Modal;


const getRandomuserParams = (params) => {
  console.log(params);
  return {
    pageSize: params.pageSize,
    pageNo: params.pageNo,
  };
};
const CheckboxGroup = Checkbox.Group;

function handleChange(value) {
  console.log(`selected ${value}`);
}

const EditUserForm = ({ visible, onCreate, onCancel, chooseUserInfo }) => {
  const [form] = Form.useForm();
  const layout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 16,
    },
  };

  const [current, setCurrent] = React.useState(0);
  const [rolesArr, setRolesArr] = React.useState([]);

  const [resourceArrs, setResourceArrs] = useState([]);

  React.useEffect(() => {
    form.resetFields();
    setCurrent(0);
    api
      .getRoleList({
        pageNo: 1,
        pageSize: 99999,
      })
      .then((res) => {
        if (res?.code === 200) {
          setRolesArr(res.data.records);
        }
      });
    if (chooseUserInfo.id) {
      api.getUserListInfo(chooseUserInfo).then((res) => {
        chooseUserInfo = res.data;
        // chooseUserInfo.sex = (chooseUserInfo.sex == false && 2) || 1;
        delete chooseUserInfo.userPwd;
        form.setFieldsValue({ ...chooseUserInfo });
      });
    }
  }, [chooseUserInfo, visible]);

  const setCurrentEvent = () => {
    // if (current === 1) {
    form
      .validateFields()
      .then((values) => {
        onCreate(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
    // } else {
    //   setCurrent(1);
    // }
  };

  let okText = "保存";
  let cancelText = "取消";
  // if (current === 1) {
  //   okText = "保存";
  //   cancelText = "上一步";
  // }
  const cancelEvent = () => {
    if (current === 1) {
      setCurrent(0);
    } else {
      onCancel();
    }
  };

  return (
    <Modal
      getContainer={false}
      visible={visible}
      title="设置用户信息"
      width={950}
      okText={okText}
      cancelText={cancelText}
      onCancel={() => {
        cancelEvent();
      }}
      onOk={() => {
        setCurrentEvent();
      }}
      destroyOnClose
    >
      <Form
        form={form}
        {...layout}
        name="form_in_modal"
        initialValues={chooseUserInfo}
      >
        <div
          className="stap1"
          style={{ display: current === 0 ? "block" : "none" }}
        >
          <Row gutter={30}>
            <Form.Item name="id">
              <Input type="hidden" value={chooseUserInfo.id} disabled />
            </Form.Item>
            <Col span={12}>
              <Form.Item name="userName" label="用户名">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="userPwd" label="密码">
                <Input.Password />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="nickName" label="姓名">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="roleId" label="角色">
                <Select
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  onChange={(val) => {
                    form.setFieldsValue({ roleId: val });
                  }}
                >
                  {rolesArr &&
                    rolesArr.map((e) => {
                      return <Option value={Number(e.id)}>{e.roleName}</Option>;
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="sex" label="性别">
                <Radio.Group>
                  <Radio value={1}>男</Radio>
                  <Radio value={2}>女</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="isEnable"
                label="启用状态"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="mobile" label="手机">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="companyUnit" label="公司/单位">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              {/* <Form.Item name="department" label="部门/单位">
                <Input />
              </Form.Item> */}
              <Form.Item name="department" label="部门/单位">
            <Select
              style={{ width: "240px" }}
              options={[
                {
                  value: "销售一部",
                  label: "销售一部",
                  key: 0,
                },
                {
                  value: "客服部",
                  label: "客服部",
                  key: 0,
                },
                {
                  value: "销售二部",
                  label: "销售二部",
                  key: 0,
                },
                {
                  value: "销售三部",
                  label: "销售三部",
                  key: 0,
                },
                {
                  value: "海外事业部",
                  label: "海外事业部",
                  key: 0,
                },
                //线上转化，东南亚，俄罗斯
                {
                  value: "线上技术部",
                  label: "线上技术部",
                  key: 0,
                },
                //客服，大客户，战略客户部
                {
                  value: "大客户技术部",
                  label: "大客户技术部",
                  key: 0,
                },
                {
                  value: "技术部",
                  label: "技术部",
                  key: 0,
                },
                {
                  value: "生产部",
                  label: "生产部",
                  key: 0,
                }
              ]}
            />
          </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item name="email" label="邮箱">
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
    </Modal>
  );
};

class Userlist extends React.Component {
  state = {
    data: [],
    pagination: {
      pageNo: 1,
      pageSize: 10,
    },
    loading: false,
    showDetail: false,
    showEdit: false,
    chooseUserInfo: {},
    rolesArr: {},
  };
  componentWillUnmount() {
    this.setState = () => false;
  }
  componentDidMount() {
    const { pagination } = this.state;
    this.fetch({ pagination });

    api
      .getRoleList({
        pageNo: 1,
        pageSize: 99999,
      })
      .then((res) => {
        if (res?.code === 200) {
          this.setState({ rolesArr: res.data.records });
        }
      });
  }
  modalChange(type, info) {
    const _t = this;
    delete info.userPwd;
    this.setState({ chooseUserInfo: info ? info : {} }, () => {
      _t.setState({
        [type]: !_t.state[type],
      });
    });
  }
  async UserChangeStatus(val, data) {
    console.log(val, data);
    let res = await api.sysEditUser({
      id: data.id,
      isEnable: (val && 1) || 0,
    });
    if (res.code == 200) {
      const { pagination } = this.state;
      this.fetch({ pagination });
    }
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.fetch({
      pagination: {
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...pagination,
        pageNo: pagination.current,
        ...filters,
      },
    });
  };

  fetch = async (params = {}) => {
    this.setState({ loading: true });
    let result = await api.getUserList({ ...params.pagination, ...params });
    if (!result || result.code !== 200) {
      return false;
    }
    this.setState({
      loading: false,
      data: (result.data && result.data.records) || [],
      pagination: {
        pageNo: (result.data && result.data.pageNo) || 1,
        pageSize: (result.data && result.data.pageSize) || 10,
        total: (result.data && result.data.totalRecords) || 1,
      },
    });
  };

  showDeleteConfirm = (info) => {
    const { pagination } = this.state;
    const _t = this;
    confirm({
      title: "您确定要执行删除操作吗?",
      icon: <ExclamationCircleOutlined />,
      content: "此操作将永久删除用户，是否继续？",
      okText: "是",
      okType: "danger",
      cancelText: "否",
      onOk() {
        api.delUser(info.id).then((result) => {
          console.log(result);
          if (!result || 200 !== result.code) {
            message.error(result.message);
          } else {
            message.success("删除成功");
            _t.fetch({ pagination });
          }
        });
      },
      onCancel() {},
    });
  };

  formRef = React.createRef();

  render() {
    const { data, pagination, loading } = this.state;

    const ruleMenu =
      (
        localStorage.getItem("ruleMenu") &&
        JSON.parse(localStorage.getItem("ruleMenu"))
      ).find((e) => e.path == "/index/user")?.children || [];

    const columns = [
      {
        title: "序号",
        dataIndex: "",
        width: 60,
        key: "no",
        fixed: "left",
        render: (index, data, k) => k + 1,
      },
      {
        title: "用户名",
        dataIndex: "userName",
        width: 150,
        key: "userName",
        render: (index, data) => data.userName || "-",
      },
      {
        title: "姓名",
        dataIndex: "nickName",
        width: 150,
        key: "nickName",
        render: (index, data) => data.nickName || "-",
      },
      {
        title: "手机",
        dataIndex: "mobile",
        width: 200,
        key: "mobile",
        render: (index, data) => data.mobile || "-",
      },
      {
        title: "邮箱",
        dataIndex: "email",
        width: 200,
        key: "email",
        render: (index, data) => data.email || "-",
      },

      {
        title: "公司/单位",
        dataIndex: "companyUnit",
        width: 250,
        key: "companyUnit",
        render: (index, data) => data.companyUnit || "-",
      },
      {
        title: "部门/单位",
        dataIndex: "department",
        width: 250,
        key: "department",
        render: (index, data) => data.department || "-",
      },
      {
        title: "启动状态",
        dataIndex: "isEnable",
        width: 80,
        key: "isEnable",
        render: (index, data) => (
          <Switch
            checkedChildren="开启"
            unCheckedChildren="关闭"
            checked={data.isEnable}
            onChange={(val) => {
              console.log(val, data);
              this.UserChangeStatus(val, data);
            }}
          />
        ),
      },
      {
        title: "角色",
        dataIndex: "roleId",
        width: 150,
        key: "roleId",
        render: (index, data) => {
          return (
            (this.state.rolesArr &&
              this.state.rolesArr.length &&
              this.state.rolesArr.find((e) => {
                return e.id == data.roleId;
              })?.roleName) ||
            "-"
          );
        },
      },
      {
        title: "最后访问日期",
        dataIndex: "updateTime",
        width: 200,
        key: "updateTime",
      },
      {
        title: "操作",
        width: 150,
        fixed: "right",
        key: "ctrl",
        render: (index, data) => {
          return (
            <div>
              {(ruleMenu &&
                ruleMenu.findIndex((e) => e.path == "/index/user/del") >= 0 && (
                  <Button
                    type="primary"
                    danger
                    size="small"
                    className="mr-1"
                    onClick={() => this.showDeleteConfirm(data)}
                  >
                    删除
                  </Button>
                )) ||
                ""}

              {(ruleMenu &&
                ruleMenu.findIndex((e) => e.path == "/index/user/edit") >=
                  0 && (
                  <Button
                    type="primary"
                    size="small"
                    className="mr-1"
                    onClick={() => this.modalChange("showEdit", data)}
                  >
                    修改
                  </Button>
                )) ||
                ""}
            </div>
          );
        },
      },
    ];

    const onEditUser = async (values) => {
      console.log(values);
      values.isEnable = (values.isEnable && 1) || 0;

      let result = await api.addUser(values);
      if (!result || result.code !== 200) {
        message.error(result.message);
        return false;
      }
      const { pagination } = this.state;
      this.fetch({ pagination });
      this.setState({ showEdit: false });
    };

    return (
      <div>
        <Form
          className="search-box d-flex mb-3"
          name="product-form"
          layout="inline"
          ref={this.formRef}
        >
          <Form.Item name="userNameLike" label="搜索用户名">
            <Input prefix={<SearchOutlined />} />
          </Form.Item>
          <Button
            type="primary"
            className="mr-1"
            onClick={() => {
              let params = { ...this.formRef.current.getFieldsValue() };
              const { pagination } = this.state;
              pagination.pageNo = 1;
              this.fetch({ ...params, ...pagination });
            }}
          >
            查询
          </Button>
          <Form.Item>
            {(ruleMenu &&
              ruleMenu.findIndex((e) => e.path == "/index/user/add") >= 0 && (
                <Button
                  type="primary"
                  onClick={() => {
                    console.log(this.state.chooseUserInfo);
                    this.setState({ chooseUserInfo: {} });
                    this.modalChange("showEdit", {
                      sex: 1,
                    });
                  }}
                >
                  添加用户
                </Button>
              )) ||
              ""}
          </Form.Item>
        </Form>
        <Table
          columns={columns}
          dataSource={data}
          pagination={{ ...pagination, showSizeChanger: false }}
          loading={loading}
          onChange={this.handleTableChange}
          size="small"
          sticky
          scroll={{ x: 1500 }}
        />

        {/* 修改用户信息 */}
        {this.state.showEdit && (
          <EditUserForm
            visible={this.state.showEdit}
            onCreate={onEditUser}
            onCancel={() => {
              this.modalChange("showEdit", false);
            }}
            chooseUserInfo={this.state.chooseUserInfo}
          />
        )}
      </div>
    );
  }
}

export default Userlist;
